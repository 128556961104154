export default {
    "account": "Mon compte",
    "greeting": "Bonjour, {name}!",
    "my_stays": {
        "title": "Mes séjours",
        "subtitle_active": "Gérez vos séjours",
        "subtitle_inactive": "Créez votre prochain séjour"
    },
    "personal_info": {
        "title": "Informations personnelles",
        "description": "Configurez vos informations personnelles"
    },
    "language" : {
        "title": "Langue",
        "description": "Sélectionnez votre langue par défaut"
    },
    "logout": "Se déconnecter",
    "share_stay": "Partager le Séjour",
    "page_personal_info": {
        "title": "Informations personnelles",
        "change_photo": "Changer de photo",
        "change_password": "Changer de mot de passe",
        "btn_save": "Enregistrer",
        "form": {
            "name": {
                "label": "Prénom",
                "placeholder": "Entrez votre prénom"
            },
            "last_name": {
                "label": "Nom",
                "placeholder": "Entrez votre nom"
            },
            "email": {
                "label": "Email",
                "placeholder": "Entrez votre email"
            },
            "number_phone": {
                "label": "Numéro de téléphone",
                "placeholder": "Entrez votre numéro de téléphone"
            },
            "password": {
                "label": "Mot de passe",
                "placeholder": "Entrez votre mot de passe"
            }
        }
    },
    "book_next_stay": {
        "title": "Réservez votre prochain séjour",
        "description": "Planifiez votre prochaine escapade"
    },
    "rewards": {
        "title": "Invitez un ami",
        "description": "Partagez des avantages exclusifs",
        "button-recommend": "Recommandez un ami maintenant",
        "referrals": {
            "title": "Recommandez un ami !",
            "discount": "{amount} de réduction",
            "description": "Envoyez votre code de parrainage. Il obtiendra {amount} de réduction grâce à votre code."
        },
        "referent": {
            "title": "Recommandez un ami et gagnez tous les deux !",
            "titleReferred": "{amount} de réduction pour le parrainé",
            "descerptionReferred": "Envoyez votre code de parrainage. Il obtiendra {amount} de réduction grâce à votre code.",
            "titleReferent": "Recevez {amount} de réduction",
            "descerptionReferent": "Une fois le code parrain validé et utilisé, vous obtiendrez {amount} de réduction."
        },
        "titleReferentProfile": "Gagnez de l'argent",
        "descriptionReferentProfile": "Recommandez un ami pour obtenir des avantages"
    },
    "next-reserve": {
        "reserve-button": "Réserver",
        "continue-title": "Voulez-vous continuer?",
        "continue-message": "Vous serez redirigé vers le site web de l'hôtel pour poursuivre votre réservation.",
        "continue-yes": "Oui, continuer",
        "continue-cancel": "Annuler"
    }


};

export default {
  "title": "Inicio",
  "title-welcome": "Benvido",
  "btn-see-all": "ver todo",
  "hab": "Hab",
  "btn-more-info": "Información do hotel",
  "stayTitle": "Estancia",
  "guestTitle": "Hóspedes",
  "invite": "Invitar",
  "section-facility": {
    "title": "instalacións"
  },
  "section-what-visit": {
    "title": "que visitar"
  },
  "section-where-eat": {
    "title": "onde comer"
  },
  "section-leisure": {
    "title": "ocio"
  },
  "section-experience": {
    "title": "experiencias"
  },
  "form-survey": {
    "title": "Como foi a túa experiencia connosco?",
    "subtitle": "Encantaríanos coñecer a túa opinión, é moi importante para nós.",
    "btn-wrong": "Mal",
    "btn-normal": "Normal",
    "btn-good": "Moi ben",
    "btn-next": "Seguinte",
    "btn-submit": "Enviar",
    "message-success-title": "Grazas por compartir a túa experiencia!",
    "message-success-text": "Valoramos moito a túa opinión e traballaremos para ofrecerche a mellor estancia posible no futuro."
  },
  "card-product": {
    "recommended": "Recomendado",
    "featured": "Destacado"
  },
  "inviteModal": {
    "title": "Invitar hóspedes",
    "inputLabel": "Invitar outro hóspede",
    "inputPlaceholder": "Correo electrónico do hóspede",
    "button": "Invitar",
    "textToast": "Invitación enviada"
  },
  "social": {
    "title": "As nosas redes"
  }
};

export default {
    "gocheckinButton": "Compléter le check-in",
    "cardBanner": {
        "title": "Check-in disponible",
        "subtitle": "Optimisez votre temps, effectuez votre check-in dès maintenant !"
    },
    "card-guest": {
        "resend-staylink": "Renvoyer le lien du séjour",
        "invite-to-completecheckin": "Inviter à compléter le check-in",
        "delete-checkin": "Supprimer le check-in de l'invité",
        "delete-guest": "Supprimer l'invité"
    },
    "delete-guest": {
        "title": "Supprimer l'invité du séjour ?",
        "info": "Vous allez retirer cet invité de votre séjour. Vous pourrez lui renvoyer une invitation ultérieurement.",
        "confirm": "Supprimer l'invité",
        "cancel": "Annuler"
    },
    "delete-checkin": {
        "title": "Supprimer le check-in de l'invité ?",
        "info": "Vous allez supprimer les informations de check-in de cet invité. L'invité devra compléter son check-in à nouveau.",
        "confirm": "Supprimer le check-in",
        "cancel": "Annuler"
    },
    "header": {
        "mystay": "Séjour",
        "guests": "Invités"
    },
    "form": {
        "why-data": "Pourquoi demandons-nous ces informations ?",
        "policies-info-1": "En appuyant sur le bouton « Finaliser », j'accepte les",
        "policies-info-2": "politiques et règles",
        "policies-info-3": "de l'hébergement",
        "why-data-title": "Pourquoi demandons-nous ces informations ?",
        "why-data-p1": "La législation locale exige que les hébergements touristiques enregistrent tous leurs invités.",
        "why-data-p2": "C'est pourquoi nous avons besoin de certaines informations personnelles de base et obligatoires.",
        "why-data-p3": "Compléter le check-in en ligne avant votre arrivée vous fera gagner du temps, vous évitant de devoir fournir ces informations sur place.",
        "why-data-p4": "Vos données seront traitées de manière sécurisée et conformément au Règlement Général sur la Protection des Données (RGPD).",
        "why-data-p5": "Vous pouvez obtenir plus de détails dans notre politique de confidentialité.",
        "changes-bar-next": "Suivant",
        "changes-bar-finish": "Finaliser",
        "changes-bar-send-data": "Envoi des données",
        "changes-bar-previus": "Étape précédente",
        "input-2-label": "Premier nom de famille",
        "input-3-label": "Deuxième nom de famille",
        "input-3-alert": "Si votre type de document est le DNI espagnol, remplissez ce champ",
        "input-4-label": "Date de naissance",
        "input-4-plchdr": "JJ/MM/AAAA",
        "input-5-label": "Sexe",
        "input-5-plchdr": "Sélectionnez une option",
        "input-6-label": "Téléphone",
        "input-7-label": "Courriel",
        "input-8-label": "Adulte responsable",
        "input-8-plchdr": "Nom et prénom de l'adulte",
        "input-9-label": "Lien de parenté",
        "input-9-plchdr": "Comment l'adulte est lié au mineur",
        "input-10-label": "Nationalité",
        "input-10-plchdr": "Tapez pour voir des suggestions",
        "input-11-label": "Type de document",
        "input-11-plchdr": "Sélectionnez une option",
        "input-11-error": "Veuillez remplir le champ 'Deuxième nom de famille'",
        "input-12-label": "Numéro de support du document",
        "input-12-plchdr": "Uniquement pour NIE ou DNI espagnol",
        "input-12-error": "Le nombre de caractères saisi n'est pas valide",
        "input-13-label": "Numéro du document",
        "input-13-plchdr": "Saisissez le numéro du document sélectionné",
        "input-14-label": "Pays de résidence",
        "input-14-plchdr": "Tapez pour voir des suggestions",
        "input-15-label": "Code postal",
        "input-15-plchdr": "Tapez pour voir des suggestions",
        "input-16-label": "Ville",
        "input-16-plchdr": "Tapez pour voir des suggestions",
        "input-17-label": "Adresse de résidence",
        "input-17-plchdr": "Tapez pour voir des suggestions",
        "head-title-1": "Informations personnelles",
        "head-title-2": "Détails d'identification",
        "head-title-3": "Enquête",
        "exit-title": "Quitter le check-in ?",
        "exit-description": "Si vous quittez maintenant, les informations saisies ne seront pas enregistrées",
        "exit-button": "Quitter",
        "exit-continue": "Continuer le check-in",
        "norms-title": "Politiques et règles",
        "norms-subtitle": "Pénalité :",
        "why-data-continue": "Compris",
        "see-policies": "Voir la politique de confidentialité"
    },
    "autocomplete": {
        "cardbanner-title": "Remplir automatiquement le formulaire",
        "cardbanner-subtitle": "Gagnez du temps en scannant votre document",
        "title": "Remplir automatiquement le formulaire",
        "description-1": "Vous allez scanner votre passeport ou votre carte d'identité à l'aide de l'appareil photo de votre dispositif.",
        "description-2": "Vous devez scanner le code MRZ.",
        "description-3": "Nous vous indiquons ci-dessous où le trouver.",
        "passport-title": "Passeport",
        "dninie-title": "Carte d'identité",
        "scan-doc": "Scanner le document",
        "backtoform": "Retour au formulaire",
        "error-scan": "Erreur : Veuillez cadrer le code MRZ du document",
        "browser-warning": "Connectez-vous via un navigateur tel que Google Chrome pour utiliser cette fonctionnalité.",
        "rescan-document": "Scanner à nouveau le document"
      }
}

export default {
  "title": "Início",
  "title-welcome": "Bem-vindo",
  "btn-see-all": "Ver todos",
  "hab": "Quarto",
  "btn-more-info": "Informações do hotel",
  "stayTitle": "Estadia",
  "guestTitle": "Hóspedes",
  "invite": "Convidar",
  "section-facility": {
    "title": "instalações"
  },
  "section-what-visit": {
    "title": "o que visitar"
  },
  "section-where-eat": {
    "title": "onde comer"
  },
  "section-leisure": {
    "title": "lazer"
  },
  "section-experience": {
    "title": "experiências"
  },
  "card-product": {
    "featured": "destacado",
    "recommended": "recomendado"
  },
  "form-survey": {
    "title": "Como foi sua experiência conosco?",
    "subtitle": "Gostaríamos muito de saber sua opinião, ela é muito importante para nós.",
    "btn-wrong": "Ruim",
    "btn-normal": "Normal",
    "btn-good": "Muito Bom",
    "btn-next": "Próximo",
    "btn-submit": "Enviar",
    "message-success-title": "Obrigado por compartilhar sua experiência!",
    "message-success-text": "Valorizamos muito seu feedback e trabalharemos para proporcionar a melhor estadia possível no futuro."
  },
  "card-product": {
    "recommended": "Recomendado",
    "featured": "Destacado",
  },
  "inviteModal": {
    "title": "Convidar hóspedes",
    "inputLabel": "Convidar outro hóspede",
    "inputPlaceholder": "Email do convidado",
    "button": "Convidar",
    "textToast": "Convite enviado",
  },
  "social": {
    "title": "Nossas redes"
  }
};

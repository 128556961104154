export default {
    "privacy": {
      "title": "Pribatutasun politika",
      //
      "s1-t1": "1. IDENTIFIKAZIOA ETA DATUEN TRATAMENDUAREN ARDURADUNA.",
      "s1-p1": "{hotel}, {address} helbidean eta IFZ {nif}rekin, webapp bidez bildutako datu pertsonalen arduraduna da.",
      "s1-p2": "Zalantzaren bat baduzu edo zure datuen tratamenduari buruzko informazio gehiago behar baduzu, jar zaitez harremanetan {email} helbidearen bidez.",
      "s1-p3": "Datuen Babeserako Ordezkariaren kontaktua: posta elektronikoa {email}, helbide postala [helbidea].",
      //
      "s2-t1": "2. BILDUTAKO INFORMAZIO PERTSONALA.",
      "s2-p1": "Webappa erabiltzean, zure inguruko honako informazioa bildu eta prozesatu dezakegu:",
      "s2-p2": "Webapperako sarbidea. Sarbide batzuek honako datu hauetako bat edo gehiago eskatuko dute:",
      "s2-p3": "Izena eta abizenak: identifikatzeko eta zure esperientzia pertsonalizatzeko.",
      "s2-p4": "Telefono zenbakia edo posta elektronikoa: komunikazioa errazteko eta jakinarazpenak bidaltzeko.",
      "s2-p5": "Check-in eta check-out datak: zure egonaldiaren datak ezagutzeko eta informazio garrantzitsua eskaintzeko.",
      "s2-p6": "Egonaldiaren erregistroa: izena, lehenengo eta bigarren abizena, generoa, nazionalitatea, jaiotze data, jaiotze hiria, jaioterria, bizileku herrialdea, bizileku hiria, helbidea, identifikazio mota, zenbakia, jaulketa data eta herrialdea, jaulketa hiria, egonaldiaren helburua, bisa zenbakia, sinadura.",
      "s2-p7": "Inkestetan parte hartzea. Gogobetetze inkestetan parte hartzea erabakitzen baduzu, zure erantzunak jasoko ditugu zure esperientzia ebaluatzeko eta zerbitzuak hobetzeko.",
      //
      "s3-t1": "3. TRATAMENDUAREN HELBURUA.",
      "s3-p1": "Webapp bidez jasotako datu pertsonalak honako helburu hauekin erabiliko dira:",
      "s3-p2": "Webapperako sarbidea eta erabilera: webappa modu pertsonalizatuan erabiltzeko aukera ematea, erabiltzaileari bere egonaldiarekin, hiriarekin eta erabilgarri dauden jarduera turistikoekin lotutako informazioa eskuratzeko eta webapp barruko txata erabiliz hoteleko langileekin harremanetan jartzeko aukera ematea.",
      "s3-p3": "Gogobetetze inkestak: egonaldian zehar edo amaitu ondoren, erabiltzaileari inkesta batean parte hartzeko gonbidapena egin dakioke bere esperientzia ebaluatzeko eta iritzia emateko.",
      "s3-p4": "Jakinarazpenak: zenbait kasutan, jakinarazpenak bidal ditzakegu, posta elektronikoz edo SMS bidez, txatean irakurri gabeko mezuak edo egonaldiarekin lotutako beste edozein informazio garrantzitsuari buruzkoak.",
      "s3-p5": "Komunikazio komertzialak: komunikazio eta eskaintza pertsonalizatuak bidaltzea.",
      "s3-p6": "Ahalmen duten agintariei informazioa ematea, araudi turistikoak hala eskatzen badu.",
      //
      "s4-t1": "4. TRATAMENDUAREN LEGITIMAZIOA.",
      "s4-p1": "Erabiltzaileen datu pertsonalen tratamendua legitimatzen duen oinarri juridikoa honako hauetan oinarritzen da:",
      "s4-p2": "Kontratu baten exekuzioa edo interesdunaren eskaeraz aurre-kontratu neurriak aplikatzea.",
      "s4-p3": "Interes legitimoa: zenbait kasutan, gure interes legitimoan oinarrituta datuak trata ditzakegu, baldin eta interes hori ez bada erabiltzailearen oinarrizko eskubide eta askatasunek gainditzen.",
      "s4-p4": "Legezko betebehar bat betetzea: zenbait kasutan, datu pertsonalen tratamendua beharrezkoa izan daiteke hosterrari aplikatzen zaizkion legezko betebeharrak betetzeko.",
      //
      "s5-t1": "5. DATUEN LAGAPENA.",
      "s5-p1": "Erabiltzaileen datu pertsonalak ez zaizkie hirugarrenei emango edo transferituko aldez aurretik informatuta baimena izan gabe, salbu eta legez aurreikusitako kasuetan.",
      "s5-p2": "Hala ere, webappak behar bezala funtziona dezan, zerbitzu-hornitzaile batzuek erabiltzaileen datu pertsonaletarako sarbidea izan beharko dute tratamenduaren arduradun gisa. Hornitzaile hauek datuak tratatzeko baimena dute soilik hosterrak zehaztutako helburuetarako eta dagokion kontratuan ezarritako baldintzen arabera.",
      "s5-p3": "Hornitzaile hauen artean dago TheHoster (The Hoster Hospitality S.L. IFZ B56861479 eta kontakturako emaila: {email}), webappa behar bezala funtziona dezan beharrezkoa den azpiegitura eta teknologia eskaintzen dituena. TheHoster beti aritzen da tratamenduaren arduradun gisa eta datuak ostatuaren jabearen jarraibideen arabera bakarrik tratatzen ditu.",
      //
      "s6-t1": "6. ERABILTZAILEAREN ESKUBIDEAK.",
      "s6-p1": "a) Sarbide eskubidea: zure datu pertsonalak tratatzen diren ala ez egiaztatzeko eta gordetako datuetara sartzeko eskubidea duzu.",
      "s6-p2": "b) Zuzentzeko eskubidea: zure datu pertsonalak zuzendu daitezela eskatzeko eskubidea duzu, hauek zehatzak edo osatu gabeak badira.",
      "s6-p3": "c) Ezabatzeko eskubidea: zure datuak ezabatzeko eskubidea duzu, besteak beste, datuak jada ez direnean beharrezkoak jasotako helburuetarako.",
      "s6-p4": "d) Aurka egiteko eskubidea: zure egoera partikularrarekin lotutako arrazoiengatik zure datuak tratatzearen aurka egiteko eskubidea duzu.",
      "s6-p5": "e) Tratamendua mugatzeko eskubidea: zure datuak tratatzearen mugapena eskatzeko eskubidea duzu; kasu horretan, soilik gordeko dira erreklamazioak aurkezteko edo defendatzeko.",
      "s6-p6": "f) Datuen eramangarritasunerako eskubidea: zuk emandako eta zu bezalako pertsona bati dagozkion datuak jaso eta beste arduradun bati helarazteko eskubidea duzu, formatu egituratu eta mekanikoki irakurgarri batean.",
      "s6-p7": "g) Erabaki automatizatuen aurkako eskubidea: eskubidea duzu soilik tratamendu automatizatuan oinarritutako erabaki baten mende ez egoteko, horrek ondorio juridikoak baditu edo zure egoeran nabarmen eragiten badu.",
      "s6-p8": "h) Emandako baimena ezeztatzeko eskubidea.",
      "s6-p9": "i) Datuak Babesteko Espainiako Agentzian erreklamazioa aurkezteko eskubidea, zure eskubideak urratu direla uste baduzu.",
      //
      "s7-t1": "7. DATUEN KONTSERBAZIO EPEA.",
      "s7-p1": "Zure datuak tratamendu ezberdinak egiteko behar den denboran gordeko dira, eta gutxienez 3 urtez, araudi turistikoaren arabera.",
      //
      "s8-t1": "8. HIRUGARRENEN WEBGUNEETARAKO ESTEKAK",
      "s8-p1": "Webappak hirugarrenen webguneetarako estekak izan ditzake. Webgune horien titularrek euren datu babes politikak izango dituzte, eta beraiek izango dira fitxategien eta pribatutasun praktikaren arduradunak.",
      "s8-p2": "Pribatutasun politika hau azken aldiz eguneratua izan zen: 2024ko martxoaren 6an."
    }
  }
  
export default {
    "gocheckinButton": "Completar o check-in",
    "cardBanner": {
        "title": "Check-in disponível",
        "subtitle": "Otimize seu tempo, realize o check-in agora!"
    },
    "card-guest": {
        "resend-staylink": "Reenviar link da estadia",
        "invite-to-completecheckin": "Convidar para completar o check-in",
        "delete-checkin": "Excluir o check-in do hóspede",
        "delete-guest": "Excluir hóspede"
    },
    "delete-guest": {
        "title": "Remover hóspede da estadia?",
        "info": "Você removerá este hóspede da sua estadia. Poderá enviar um novo convite posteriormente.",
        "confirm": "Remover hóspede",
        "cancel": "Cancelar"
    },
    "delete-checkin": {
        "title": "Excluir o check-in do hóspede?",
        "info": "Você excluirá as informações de check-in deste hóspede. O hóspede terá que refazer o check-in.",
        "confirm": "Excluir check-in",
        "cancel": "Cancelar"
    },
    "header": {
        "mystay": "Estadia",
        "guests": "Hóspedes"
    },
    "form": {
        "why-data": "Por que pedimos esses dados?",
        "policies-info-1": "Ao pressionar o botão 'Finalizar', declaro que aceito as",
        "policies-info-2": "políticas e regras",
        "policies-info-3": "do alojamento",
        "why-data-title": "Por que pedimos esses dados?",
        "why-data-p1": "A legislação local exige que os alojamentos turísticos registrem todos os seus hóspedes.",
        "why-data-p2": "Por isso, precisamos de algumas informações pessoais básicas e obrigatórias.",
        "why-data-p3": "Completar o check-in online antes da sua chegada economiza tempo, evitando que você forneça essas informações no local.",
        "why-data-p4": "Seus dados serão processados de forma segura e de acordo com o Regulamento Geral de Proteção de Dados (GDPR).",
        "why-data-p5": "Você pode obter mais detalhes em nossa política de privacidade.",
        "changes-bar-next": "Próximo",
        "changes-bar-finish": "Finalizar",
        "changes-bar-send-data": "Enviando dados",
        "changes-bar-previus": "Etapa anterior",
        "input-2-label": "Primeiro sobrenome",
        "input-3-label": "Segundo sobrenome",
        "input-3-alert": "Se o seu tipo de documento é o DNI espanhol, preencha este campo",
        "input-4-label": "Data de nascimento",
        "input-4-plchdr": "DD/MM/AAAA",
        "input-5-label": "Gênero",
        "input-5-plchdr": "Selecione uma opção",
        "input-6-label": "Telefone",
        "input-7-label": "E-mail",
        "input-8-label": "Adulto responsável",
        "input-8-plchdr": "Nome completo do adulto",
        "input-9-label": "Relação de parentesco",
        "input-9-plchdr": "Como o adulto está relacionado ao menor",
        "input-10-label": "Nacionalidade",
        "input-10-plchdr": "Digite para ver sugestões",
        "input-11-label": "Tipo de documento",
        "input-11-plchdr": "Selecione uma opção",
        "input-11-error": "Por favor, preencha o campo 'Segundo sobrenome'",
        "input-12-label": "Número de suporte do documento",
        "input-12-plchdr": "Apenas para NIE ou DNI espanhol",
        "input-12-error": "A quantidade de caracteres inserida não é válida",
        "input-13-label": "Número do documento",
        "input-13-plchdr": "Digite o número do documento selecionado",
        "input-14-label": "País de residência",
        "input-14-plchdr": "Digite para ver sugestões",
        "input-15-label": "Código postal",
        "input-15-plchdr": "Digite para ver sugestões",
        "input-16-label": "Cidade",
        "input-16-plchdr": "Digite para ver sugestões",
        "input-17-label": "Endereço residencial",
        "input-17-plchdr": "Digite para ver sugestões",
        "head-title-1": "Dados pessoais",
        "head-title-2": "Detalhes de identificação",
        "head-title-3": "Pesquisa",
        "exit-title": "Sair do check-in?",
        "exit-description": "Se você sair agora, as informações inseridas não serão salvas",
        "exit-button": "Sair",
        "exit-continue": "Continuar com o check-in",
        "norms-title": "Políticas e Regras",
        "norms-subtitle": "Penalidade:",
        "why-data-continue": "Entendido",
        "see-policies": "Ver política de privacidade"
    },
    "autocomplete": {
        "cardbanner-title": "Preenchimento automático do formulário",
        "cardbanner-subtitle": "Economize tempo escaneando seu documento",
        "title": "Preenchimento automático do formulário",
        "description-1": "Você irá escanear seu passaporte ou documento de identidade usando a câmera do seu dispositivo.",
        "description-2": "Você deve escanear o código MRZ.",
        "description-3": "Abaixo mostramos onde encontrá-lo.",
        "passport-title": "Passaporte",
        "dninie-title": "Cartão de Cidadão",
        "scan-doc": "Escanear documento",
        "backtoform": "Voltar ao formulário",
        "error-scan": "Erro: Você deve focar no código MRZ do documento",
        "browser-warning": "Faça login a partir de um navegador como o Google Chrome para usar essa funcionalidade.",
        "rescan-document": "Escanear novamente o documento"
    }
}

export default {
    "header": {
      "home": "inici",
      "facilities": "instal·lacions",
      "hotel": "hotel",
      "hostal": "hostal",
      "destination": "destinació",
      "experiences": "experiències",
      "messages": "missatges"
    },
    "dropdownLanguage": {
      "title": "idioma"
    },
    "footer": {
      "legal-warning": "avís legal",
      "privacy-policy": "política de privacitat",
      "privacy-cookies": "política de galetes",
      "terms-conditions": "termes i condicions"
    },
    "input-search": {
      "search": "Cercar",
      "placeholder": "Destinacions, experiències...",
      "from": "des de"
    }
  };
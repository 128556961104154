export default {
    "gocheckinButton": "Completar Check-in",
    "cardBanner": {
      "title": "Check-in dispoñible",
      "subtitle": "Optimiza o teu tempo, realiza o check-in agora!"
    },
    "card-guest": {
      "resend-staylink": "Reenviar ligazón á estancia",
      "invite-to-completecheckin": "Invitar a completar o Check-in",
      "delete-checkin": "Eliminar Check-in do hóspede",
      "delete-guest": "Eliminar hóspede"
    },
    "delete-guest": {
      "title": "Eliminar hóspede da estancia?",
      "info": "Quitarás este hóspede da túa estancia. Poderás volver a enviar a invitación despois.",
      "confirm": "Eliminar hóspede",
      "cancel": "Cancelar"
    },
    "delete-checkin": {
      "title": "Eliminar o Check-in do hóspede?",
      "info": "Eliminarás a información do Check-in deste hóspede. Terá que completalo de novo.",
      "confirm": "Eliminar Check-in",
      "cancel": "Cancelar"
    },
    "header": {
      "mystay": "Estancia",
      "guests": "Hóspedes"
    },
    "form": {
      "why-data": "Por que pedimos estes datos?",
      "policies-info-1": "Ao premer o botón 'Finalizar', declaro que acepto as",
      "policies-info-2": "políticas e normas",
      "policies-info-3": "do aloxamento",
      "why-data-title": "Por que pedimos estes datos?",
      "why-data-p1": "A lei local require que os aloxamentos turísticos rexistren a todos os seus hóspedes.",
      "why-data-p2": "Por iso, necesitamos algúns datos persoais básicos e obrigatorios.",
      "why-data-p3": "Completar o check-in en liña antes da túa chegada aforrarache tempo.",
      "why-data-p4": "Os teus datos procesaranse de forma segura e conforme ao RGPD.",
      "why-data-p5": "Podes obter máis detalles na nosa política de privacidade.",
      "changes-bar-next": "Seguinte",
      "changes-bar-finish": "Finalizar",
      "changes-bar-send-data": "Enviando datos",
      "changes-bar-previus": "Paso anterior",
      "input-2-label": "Primeiro apelido",
      "input-3-label": "Segundo apelido",
      "input-3-alert": "Se o teu documento é DNI español, completa este campo",
      "input-4-label": "Data de nacemento",
      "input-4-plchdr": "DD/MM/AAAA",
      "input-5-label": "Sexo",
      "input-5-plchdr": "Selecciona unha opción",
      "input-6-label": "Teléfono",
      "input-7-label": "Correo electrónico",
      "input-8-label": "Adulto responsable",
      "input-8-plchdr": "Nome e apelidos do adulto",
      "input-9-label": "Relación de parentesco",
      "input-9-plchdr": "Como se vincula o adulto co menor",
      "input-10-label": "Nacionalidade",
      "input-10-plchdr": "Escribe para ver suxestións",
      "input-11-label": "Tipo de documento",
      "input-11-plchdr": "Selecciona unha opción",
      "input-11-error": "Por favor, completa o campo 'Segundo apelido'",
      "input-12-label": "Número de soporte do documento",
      "input-12-plchdr": "Só para NIE ou DNI español",
      "input-12-error": "A cantidade de caracteres introducida non é válida",
      "input-13-label": "Número de documento",
      "input-13-plchdr": "Escribe o número do documento seleccionado",
      "input-14-label": "País de residencia",
      "input-14-plchdr": "Escribe para ver suxestións",
      "input-15-label": "Código postal",
      "input-15-plchdr": "Escribe para ver suxestións",
      "input-16-label": "Concello",
      "input-16-plchdr": "Escribe para ver suxestións",
      "input-17-label": "Domicilio de residencia",
      "input-17-plchdr": "Escribe para ver suxestións",
      "head-title-1": "Datos persoais",
      "head-title-2": "Datos de identificación",
      "head-title-3": "Enquisa",
      "exit-title": "Saír do Check-in?",
      "exit-description": "Se saes agora, a información introducida non se gardará",
      "exit-button": "Saír",
      "exit-continue": "Continuar co Check-in",
      "norms-title": "Políticas e Normas",
      "norms-subtitle": "Penalización:",
      "why-data-continue": "Entendido",
      "see-policies": "Ver política de privacidade"
    },
    "autocomplete": {
      "cardbanner-title": "Autocompletar formulario",
      "cardbanner-subtitle": "Aforra tempo escaneando o teu documento",
      "title": "Autocompletar formulario",
      "description-1": "Escanearás o teu pasaporte ou DNI usando a cámara do dispositivo.",
      "description-2": "Debes escanear o código MRZ.",
      "description-3": "Indicámosche abaixo onde atopalo.",
      "passport-title": "Pasaporte",
      "dninie-title": "DNI/NIE",
      "scan-doc": "Escanear documento",
      "backtoform": "Volver ao formulario",
      "error-scan": "Erro: Debes enfocar o código MRZ do documento",
      "browser-warning": "Inicia sesión desde un navegador como Google Chrome para usar esta funcionalidade.",
      "rescan-document": "Volver a escanear documento"
    }
  }
  
export default {
    "log-or-register": {
      "title": "Inicia sesión ou rexístrate",
      "welcome-title": "Benvido!",
      "welcome-msg": "Goza da nosa WebApp e xestiona as túas estadías en calquera dos nosos hoteis.",
      "input": "Introduce o teu correo electrónico",
      "text-error": "O formato do correo electrónico introducido non é correcto",
      "continue-button": "Continuar",
      "continue-google": "Continuar con Google",
      "continue-fb": "Continuar con Facebook"
    },
    "log": {
      "title": "Inicia sesión",
      "input-password": "Introduce un contrasinal",
      "password-text-error": "O contrasinal introducido é incorrecto",
      "continue-button": "Continuar",
      "forgot-question": "Esqueciches o teu contrasinal?",
      "input-show-pass": "Amosar",
      "input-hide-pass": "Ocultar",
      "entering": "Iniciando sesión"
    },
    "register": {
      "title": "Completa o rexistro",
      "input-name": "Introduce o teu nome",
      "input-email": "Introduce o teu correo electrónico",
      "input-email-error": "O formato do correo electrónico introducido non é correcto",
      "input-password": "Introduce un contrasinal",
      "continue-button": "Aceptar e continuar",
      "text-info-1": "enviarache comunicacións comerciais e promocións personalizadas por correo electrónico, SMS ou outros medios electrónicos sobre produtos e servizos",
      "text-info-2": "Non quero recibir mensaxes promocionais.",
      "policies-text": "Ao seleccionar Aceptar e continuar, acepto a",
      "policies-title": "Política de Privacidade.",
      "the-chain": "A cadea",
      "password-info": "O contrasinal debe conter polo menos 8 caracteres.",
      "complete-register": "Completando rexistro"
    },
    "reset-pass": {
      "title": "Cambiar contrasinal",
      "input-placeholder": "Introduce o teu novo contrasinal",
      "input-input-error": "O contrasinal introducido é incorrecto",
      "continue-button": "Cambiar contrasinal",
      "greeting": "Ola",
      "title-reset": "Restablecer contrasinal",
      "msg": "Solicitaches unha ligazón para restablecer o contrasinal da túa conta na nosa WebApp. Fai clic no seguinte botón para iniciar o proceso.",
      "link": "Restablecer contrasinal"
    },
    "hotel-list": {
      "title": "Selecciona o aloxamento para crear a túa estadía na nosa WebApp",
      "amount-text": "aloxamentos da cadea",
      "stars-text": "estrelas",
      "star-text": "estrela"
    },
    "my-stay": {
      "title": "A miña estadía"
    },
    "saving-changes": "Gardando cambios"
  }
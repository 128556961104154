export default {
    "title": "Berichten",
    "availabilty": "Beschikbaar",
    "not-availabilty": "Niet beschikbaar",
    "languages-text": "Welkom bij de chat!<br>Je kunt met ons personeel communiceren in de volgende talen:",
    "automatic-response": "Automatisch antwoord",
    "input": "Typ een bericht...",
    "horary-title": "Chattijden",
    "nameDayLunes": "Maandag",
    "nameDayMartes": "Dinsdag",
    "nameDayMiércoles": "Woensdag",
    "nameDayJueves": "Donderdag",
    "nameDayViernes": "Vrijdag",
    "nameDaySábado": "Zaterdag",
    "nameDayDomingo": "Zondag",
    "tabs": {
      "inbox": "inbox",
      "chat": "chat"
    }
  }
  
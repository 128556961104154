export default {
    "apiError401": "Credencials incorrectes",
    "apiError404": "Servei no disponible",
    "apiError405_406": "Sol·licitud invàlida",
    "apiError500": "Error de connexió",
    "apiErrorUndefined": "Error general de l'aplicació",
    "invalidKey": "Clau invàlida",
    "recordSuccess": "Registre completat",
    "emailSuccess": "Correu enviat",
    "passwordUpdated": "Contrasenya actualitzada",
    "copiedClipboard": "Copiat al porta-retalls",
    "guestDeleted": "Hoste eliminat",
    "urlClipboard": "Enllaç copiat",
    "changeSave": "Canvis desats",
    "dataSave": "Dades desades amb èxit"
  };
  
export default{
    "privacy":{
        "title":"Política de privacidad",
        //
        "s1-t1":"1. IDENTIFICACIÓN Y RESPONSABLE DEL TRATAMIENTO DE LOS DATOS.",
        "s1-p1":"{hotel} con domicilio en {address} y con NIF {nif} es el responsable de los datos personales recogidos a través de la webapp.",
        "s1-p2":"Si tiene alguna duda o necesita más información sobre el tratamiento de sus datos personales, puede contactar a través de {email}.",
        "s1-p3":"Datos de contacto del Delegado de Protección de Datos: correo electrónico {email}, dirección postal [dirección].",
        //
        "s2-t1":"2. INFORMACIÓN PERSONAL RECOPILADA.",
        "s2-p1":"Al utilizar la webapp, podemos recopilar y procesar la siguiente información sobre usted:",
        "s2-p2":"Acceso a la webapp. Determinados accesos a la webapp implicarán la solicitud de alguno de los siguientes datos:",
        "s2-p3":"Nombre y apellidos: para identificarle y personalizar su experiencia en la webapp.",
        "s2-p4":"Número de teléfono o email: para facilitar la comunicación y enviarle notificaciones relacionadas con la webapp.",
        "s2-p5":"Fecha de Check-in y Check-out: para conocer las fechas de su estancia y ofrecerle información relevante.",
        "s2-p6":"Registro de la estancia: nombre, apellido, segundo apellido, sexo, nacionalidad, fecha de nacimiento, ciudad de nacimiento, país de nacimiento, país de residencia, ciudad de residencia, dirección de residencia, tipo de identificación, número de identificación, fecha de identificación, país de identificación, ciudad de emisión, propósito de la estadía, número de visa, firma.",
        "s2-p7":"Participación en encuestas. Si decide participar en las encuestas de satisfacción, recopilamos sus respuestas para evaluar su experiencia y ayudarnos a mejorar los servicios.",
        //
        "s3-t1":"3. FINALIDAD DEL TRATAMIENTO.",
        "s3-p1":"Los datos personales que se recogen a través de la webapp se utilizan para las siguientes finalidades:",
        "s3-p2":"Acceso y uso de la webapp: facilitar el acceso y uso personalizado de la webapp, permitiendo al huésped obtener información relacionada con su estancia, la ciudad y las actividades turísticas disponibles, y comunicarse con el personal del hotel a través del chat incorporado en la webapp.",
        "s3-p3":"Encuestas de satisfacción: durante la estancia o una vez finalizada la misma, el huésped puede ser invitado a participar en una encuesta para evaluar su experiencia y proporcionar feedback.",
        "s3-p4":"Notificaciones: en determinados casos, podemos enviar notificaciones, ya sea por correo electrónico o SMS, relacionadas con mensajes sin leer en el chat o cualquier otra información relevante sobre la estancia.",
        "s3-p5":"Comunicaciones comerciales: envío de comunicaciones comerciales y ofertas personalizadas.",
        "s3-p6":"Información a las autoridades competentes, según lo requieran las normativas turísticas.",
        //
        "s4-t1":"4. LEGITIMACIÓN DEL TRATAMIENTO.",
        "s4-p1":"La base jurídica que legitima el tratamiento de los datos personales de los huéspedes se fundamenta en:",
        "s4-p2":"Ejecución de un contrato en el que el interesado es parte o para la aplicación a petición de este de medidas precontractuales.",
        "s4-p3":"Interés legítimo: en determinadas circunstancias, es posible que tratemos datos personales basándonos en nuestro interés legítimo, siempre y cuando dicho interés no se vea superado por los intereses o derechos y libertades fundamentales del huésped.",
        "s4-p4":"Cumplimiento de una obligación legal: en ciertos casos, el tratamiento de datos personales puede ser necesario para cumplir con obligaciones legales aplicables al hoster.",
        //
        "s5-t1":"5. CESIÓN DE LOS DATOS.",
        "s5-p1":"Los datos personales de los huéspedes no serán cedidos ni transferidos a terceros sin el previo consentimiento informado, salvo en los casos previstos por la ley.",
        "s5-p2":"Sin embargo, para el correcto funcionamiento de la webapp es necesario que ciertos proveedores de servicios accedan a los datos personales de los huéspedes en calidad de encargados del tratamiento. Estos proveedores tienen la obligación de tratar los datos exclusivamente con las finalidades estipuladas por el hoster y bajo las condiciones que se determinen en los correspondientes contratos de encargado del tratamiento.",
        "s5-p3": "Entre estos proveedores se encuentra TheHoster (The Hoster Hospitality S.L. con NIF B56861479 y email de contacto {email}), que proporciona la infraestructura y tecnología necesaria para el correcto funcionamiento de la webapp. TheHoster actúa en todo momento como encargado del tratamiento y únicamente procesa los datos según las instrucciones del titular del alojamiento y para las finalidades previstas.",
        //
        "s6-t1":"6. DERECHOS DEL USUARIO.",
        "s6-p1":"a) Derecho de acceso: tiene derecho a solicitar y obtener confirmación de si se tratan datos personalesque le conciernen, así como a acceder a sus datos personales almacenados.",
        "s6-p2":"b) Derecho de rectificación: tiene derecho a solicitar la rectificación de sus datos personales si estos fueran inexactos o incompletos",
        "s6-p3":"c) Derecho de supresión: tiene derecho a solicitar la supresión de sus datos personales cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.",
        "s6-p4":"d) Derecho de oposición: tiene derecho a oponerse al tratamiento de sus datos personales, por motivos relacionados con su situación particular.",
        "s6-p5":"e) Derecho a la limitación del tratamiento: tiene derecho a solicitar la limitación del tratamiento de sus datos personales, en cuyo caso únicamente se conservan para el ejercicio o la defensa de reclamaciones.",
        "s6-p6":"f) Derecho a la portabilidad de los datos: tiene derecho a recibir los datos personales que le incumben, que haya facilitado a un responsable del tratamiento, en un formato estructurado, de uso común y lectura mecánica y a transmitirlos a otro responsable del tratamiento.",
        "s6-p7":"g) Derecho a no ser objeto de decisiones individuales automatizadas: tiene derecho a no ser objeto de una decisión basada únicamente en el tratamiento automatizado, incluida la elaboración de perfiles, que produzca efectos jurídicos en él o le afecte significativamente de modo similar.",
        "s6-p8":"h) Derecho a revocar el consentimiento otorgado.",
        "s6-p9":"i) Derecho a reclamar ante la Agencia Española de Protección de Datos cuando el interesado considere que se ha vulnerado los derechos que le son reconocidos por la normativa aplicable en protección de datos.",
        //
        "s7-t1":"7. PLAZO DE CONSERVACIÓN DE LOS DATOS.",
        "s7-p1":"Sus datos se almacenan durante el tiempo necesario para realizar los diferentes tratamientos, y al menos, de acuerdo con la normativa turística, por un período de 3 años.",
        //
        "s8-t1":"8. ENLACES A SITIOS WEB DE TERCEROS",
        "s8-p1":"La webapp puede incluir hipervínculos o enlaces que permiten acceder a páginas web de terceros. Los titulares de dichos sitios web dispondrán de sus propias políticas de protección de datos, siendo ellos mismos, en cada caso, responsables de sus propios ficheros y de sus propias prácticas de privacidad.",
        "s8-p2":"Esta Política de Privacidad fue actualizada por última vez el 06 de marzo de 2024.",
    },
}  
export default{
    "privacy":{
        "title": "Informativa sulla privacy",
        //
        "s1-t1": "1. IDENTIFICAZIONE E RESPONSABILITÀ DEL TRATTAMENTO DEI DATI.",
        "s1-p1": "{hotel} con sede in {address} e con il codice fiscale {nif} è responsabile dei dati personali raccolti tramite la webapp.",
        "s1-p2": "Se hai domande o hai bisogno di ulteriori informazioni sul trattamento dei tuoi dati personali, puoi contattarci all'indirizzo {email}.",
        "s1-p3": "Contatti del Responsabile della Protezione dei Dati: email {email}, indirizzo postale [indirizzo].",
        //
        "s2-t1": "2. INFORMAZIONI PERSONALI RACCOLTE.",
        "s2-p1": "Utilizzando la webapp, possiamo raccogliere e trattare le seguenti informazioni su di te:",
        "s2-p2": "Accesso alla webapp. Alcuni accessi alla webapp richiederanno la fornitura di uno o più dei seguenti dati:",
        "s2-p3": "Nome e cognome: per identificarti e personalizzare la tua esperienza sulla webapp.",
        "s2-p4": "Numero di telefono o email: per facilitare la comunicazione e inviarti notifiche relative alla webapp.",
        "s2-p5": "Date di check-in e check-out: per conoscere le date del tuo soggiorno e fornirti informazioni rilevanti.",
        "s2-p6": "Registrazione del soggiorno: nome, cognome, secondo cognome, sesso, nazionalità, data di nascita, città di nascita, paese di nascita, paese di residenza, città di residenza, indirizzo di residenza, tipo di identificazione, numero di identificazione, data di identificazione, paese di identificazione, città di rilascio, scopo del soggiorno, numero del visto, firma.",
        "s2-p7": "Partecipazione ai sondaggi. Se decidi di partecipare ai sondaggi sulla soddisfazione, raccoglieremo le tue risposte per valutare la tua esperienza e aiutarci a migliorare i nostri servizi.",
        //
        "s3-t1": "3. FINALITÀ DEL TRATTAMENTO.",
        "s3-p1": "I dati personali raccolti tramite la webapp sono utilizzati per le seguenti finalità:",
        "s3-p2": "Accesso e utilizzo della webapp: per facilitare l'accesso e l'uso personalizzato della webapp, permettendo all'ospite di ottenere informazioni relative al suo soggiorno, alla città e alle attività turistiche disponibili, e di comunicare con il personale dell'hotel tramite la chat integrata nella webapp.",
        "s3-p3": "Sondaggi di soddisfazione: durante il soggiorno o dopo la sua conclusione, l'ospite può essere invitato a partecipare a un sondaggio per valutare la sua esperienza e fornire un feedback.",
        "s3-p4": "Notifiche: in alcuni casi, possiamo inviare notifiche, via email o SMS, relative a messaggi non letti nella chat o ad altre informazioni rilevanti sul soggiorno.",
        "s3-p5": "Comunicazioni commerciali: invio di comunicazioni commerciali e offerte personalizzate.",
        "s3-p6": "Informazioni alle autorità competenti, come richiesto dalla normativa turistica.",
        //
        "s4-t1": "4. BASE GIURIDICA DEL TRATTAMENTO.",
        "s4-p1": "La base giuridica che legittima il trattamento dei dati personali degli ospiti si fonda su:",
        "s4-p2": "L'esecuzione di un contratto di cui l'interessato è parte o, su sua richiesta, l'applicazione di misure precontrattuali.",
        "s4-p3": "Interesse legittimo: in determinate circostanze, potremmo trattare i dati personali basandoci sul nostro interesse legittimo, a condizione che tale interesse non prevalga sugli interessi o sui diritti e le libertà fondamentali dell'ospite.",
        "s4-p4": "Adempimento di un obbligo legale: in alcuni casi, il trattamento dei dati personali può essere necessario per adempiere a obblighi legali applicabili all'host.",
        //
        "s5-t1": "5. TRASFERIMENTO DEI DATI.",
        "s5-p1": "I dati personali degli ospiti non saranno ceduti o trasferiti a terzi senza il previo consenso informato, salvo nei casi previsti dalla legge.",
        "s5-p2": "Tuttavia, per il corretto funzionamento della webapp, è necessario che alcuni fornitori di servizi accedano ai dati personali degli ospiti in qualità di responsabili del trattamento. Questi fornitori sono tenuti a trattare i dati esclusivamente per le finalità stabilite dall'host e secondo le condizioni determinate nei relativi contratti di responsabile del trattamento.",
        "s5-p3": "Tra questi fornitori figura TheHoster (The Hoster Hospitality S.L. con il codice fiscale B56861479 e email di contatto {email}), che fornisce l'infrastruttura e la tecnologia necessarie per il corretto funzionamento della webapp. TheHoster agisce sempre come responsabile del trattamento e tratta i dati esclusivamente secondo le istruzioni del titolare dell'alloggio e per le finalità previste.",
        //
        "s6-t1": "6. DIRITTI DELL'UTENTE.",
        "s6-p1": "a) Diritto di accesso: hai il diritto di richiedere e ottenere conferma se sono trattati dati personali che ti riguardano, nonché di accedere ai tuoi dati personali archiviati.",
        "s6-p2": "b) Diritto di rettifica: hai il diritto di richiedere la correzione dei tuoi dati personali se risultano inesatti o incompleti.",
        "s6-p3": "c) Diritto alla cancellazione: hai il diritto di richiedere la cancellazione dei tuoi dati personali quando, tra le altre ragioni, i dati non sono più necessari per le finalità per cui sono stati raccolti.",
        "s6-p4": "d) Diritto di opposizione: hai il diritto di opporti al trattamento dei tuoi dati personali per motivi legati alla tua situazione particolare.",
        "s6-p5": "e) Diritto alla limitazione del trattamento: hai il diritto di richiedere la limitazione del trattamento dei tuoi dati personali, in tal caso verranno conservati solo per l'esercizio o la difesa di eventuali reclami.",
        "s6-p6": "f) Diritto alla portabilità dei dati: hai il diritto di ricevere i dati personali che ti riguardano e che hai fornito a un titolare del trattamento, in un formato strutturato, di uso comune e leggibile da una macchina, e di trasmetterli a un altro titolare del trattamento.",
        "s6-p7": "g) Diritto di non essere sottoposto a decisioni individuali automatizzate: hai il diritto di non essere sottoposto a una decisione basata esclusivamente su un trattamento automatizzato, incluso il profiling, che produca effetti giuridici nei tuoi confronti o ti influenzi in modo significativo.",
        "s6-p8": "h) Diritto di revocare il consenso prestato.",
        "s6-p9": "i) Diritto di proporre reclamo all'Autorità Garante per la Protezione dei Dati se ritieni che i tuoi diritti, riconosciuti dalla normativa applicabile in materia di protezione dei dati, siano stati violati.",
        //
        "s7-t1": "7. DURATA DI CONSERVAZIONE DEI DATI.",
        "s7-p1": "I tuoi dati sono conservati per il tempo necessario a realizzare i diversi trattamenti e, almeno, in conformità con la normativa turistica, per un periodo di 3 anni.",
        //
        "s8-t1": "8. COLLEGAMENTI A SITI WEB DI TERZI.",
        "s8-p1": "La webapp può includere collegamenti ipertestuali o link che permettono l'accesso a siti web di terzi. I titolari di tali siti web dispongono di proprie politiche di protezione dei dati e sono, in ogni caso, responsabili dei propri file e delle proprie pratiche in materia di privacy.",
        "s8-p2": "La presente Informativa sulla Privacy è stata aggiornata l'ultima volta il 06 marzo 2024."
    },
}

export default{
    "privacy":{
        "title": "Política de Privacidade",
        //
        "s1-t1": "1. IDENTIFICAÇÃO E RESPONSABILIDADE PELO TRATAMENTO DOS DADOS.",
        "s1-p1": "{hotel} com sede em {address} e com NIF {nif} é o responsável pelos dados pessoais coletados através da webapp.",
        "s1-p2": "Se você tiver alguma dúvida ou precisar de mais informações sobre o tratamento dos seus dados pessoais, pode entrar em contato através de {email}.",
        "s1-p3": "Dados de contato do Encarregado de Proteção de Dados: email {email}, endereço postal [endereço].",
        //
        "s2-t1": "2. INFORMAÇÕES PESSOAIS COLETADAS.",
        "s2-p1": "Ao utilizar a webapp, podemos coletar e processar as seguintes informações sobre você:",
        "s2-p2": "Acesso à webapp. Certos acessos à webapp implicarão a solicitação de um ou mais dos seguintes dados:",
        "s2-p3": "Nome e sobrenome: para identificá-lo e personalizar sua experiência na webapp.",
        "s2-p4": "Número de telefone ou email: para facilitar a comunicação e enviar notificações relacionadas à webapp.",
        "s2-p5": "Data de Check-in e Check-out: para conhecer as datas da sua estadia e fornecer informações relevantes.",
        "s2-p6": "Registro da estadia: nome, sobrenome, segundo sobrenome, sexo, nacionalidade, data de nascimento, cidade de nascimento, país de nascimento, país de residência, cidade de residência, endereço residencial, tipo de identificação, número de identificação, data de identificação, país de identificação, cidade de emissão, finalidade da estadia, número de visto, assinatura.",
        "s2-p7": "Participação em pesquisas. Se você decidir participar das pesquisas de satisfação, coletaremos suas respostas para avaliar sua experiência e nos ajudar a melhorar os serviços.",
        //
        "s3-t1": "3. FINALIDADE DO TRATAMENTO.",
        "s3-p1": "Os dados pessoais coletados através da webapp são utilizados para as seguintes finalidades:",
        "s3-p2": "Acesso e uso da webapp: facilitar o acesso e o uso personalizado da webapp, permitindo que o hóspede obtenha informações relacionadas à sua estadia, à cidade e às atividades turísticas disponíveis, e se comunique com a equipe do hotel por meio do chat integrado na webapp.",
        "s3-p3": "Pesquisas de satisfação: durante a estadia ou após o término dela, o hóspede pode ser convidado a participar de uma pesquisa para avaliar sua experiência e fornecer feedback.",
        "s3-p4": "Notificações: em determinados casos, podemos enviar notificações, seja por email ou SMS, relacionadas a mensagens não lidas no chat ou a outras informações relevantes sobre a estadia.",
        "s3-p5": "Comunicações comerciais: envio de comunicações comerciais e ofertas personalizadas.",
        "s3-p6": "Informações às autoridades competentes, conforme exigido pela regulamentação turística.",
        //
        "s4-t1": "4. LEGITIMAÇÃO DO TRATAMENTO.",
        "s4-p1": "A base jurídica que legitima o tratamento dos dados pessoais dos hóspedes baseia-se em:",
        "s4-p2": "Execução de um contrato do qual o interessado é parte ou para a aplicação, a seu pedido, de medidas pré-contratuais.",
        "s4-p3": "Interesse legítimo: em determinadas circunstâncias, podemos tratar os dados pessoais com base em nosso interesse legítimo, desde que esse interesse não se sobreponha aos interesses ou aos direitos e liberdades fundamentais do hóspede.",
        "s4-p4": "Cumprimento de uma obrigação legal: em certos casos, o tratamento dos dados pessoais pode ser necessário para cumprir obrigações legais aplicáveis ao anfitrião.",
        //
        "s5-t1": "5. TRANSFERÊNCIA DOS DADOS.",
        "s5-p1": "Os dados pessoais dos hóspedes não serão cedidos ou transferidos a terceiros sem o prévio consentimento informado, salvo nos casos previstos por lei.",
        "s5-p2": "No entanto, para o correto funcionamento da webapp, é necessário que certos prestadores de serviços tenham acesso aos dados pessoais dos hóspedes na qualidade de responsáveis pelo tratamento. Esses prestadores têm a obrigação de tratar os dados exclusivamente para as finalidades estipuladas pelo anfitrião e sob as condições determinadas nos respectivos contratos de tratamento.",
        "s5-p3": "Entre esses prestadores está a TheHoster (The Hoster Hospitality S.L. com NIF B56861479 e email de contato {email}), que fornece a infraestrutura e a tecnologia necessárias para o correto funcionamento da webapp. A TheHoster atua em todos os momentos como responsável pelo tratamento e processa os dados apenas de acordo com as instruções do titular da hospedagem e para as finalidades previstas.",
        //
        "s6-t1": "6. DIREITOS DO USUÁRIO.",
        "s6-p1": "a) Direito de acesso: você tem o direito de solicitar e obter a confirmação se dados pessoais que lhe dizem respeito estão sendo processados, bem como de acessar seus dados pessoais armazenados.",
        "s6-p2": "b) Direito de retificação: você tem o direito de solicitar a correção dos seus dados pessoais se estes estiverem incorretos ou incompletos.",
        "s6-p3": "c) Direito à eliminação: você tem o direito de solicitar a exclusão dos seus dados pessoais quando, entre outros motivos, os dados não forem mais necessários para as finalidades para as quais foram coletados.",
        "s6-p4": "d) Direito de oposição: você tem o direito de se opor ao tratamento dos seus dados pessoais por motivos relacionados à sua situação particular.",
        "s6-p5": "e) Direito à limitação do tratamento: você tem o direito de solicitar a limitação do tratamento dos seus dados pessoais, caso em que eles serão mantidos apenas para o exercício ou a defesa de reivindicações.",
        "s6-p6": "f) Direito à portabilidade dos dados: você tem o direito de receber os dados pessoais que lhe dizem respeito e que forneceu a um responsável pelo tratamento, em um formato estruturado, de uso comum e legível por máquina, e de transmiti-los a outro responsável pelo tratamento.",
        "s6-p7": "g) Direito de não ser sujeito a decisões individuais automatizadas: você tem o direito de não ser sujeito a uma decisão baseada exclusivamente no tratamento automatizado, incluindo a criação de perfis, que produza efeitos jurídicos sobre você ou o afete de forma semelhante de maneira significativa.",
        "s6-p8": "h) Direito de revogar o consentimento fornecido.",
        "s6-p9": "i) Direito de apresentar uma reclamação à Autoridade de Proteção de Dados, se considerar que seus direitos, conforme reconhecidos pela regulamentação aplicável em matéria de proteção de dados, foram violados.",
        //
        "s7-t1": "7. PRAZO DE CONSERVAÇÃO DOS DADOS.",
        "s7-p1": "Seus dados são armazenados pelo tempo necessário para a realização dos diversos tratamentos e, no mínimo, de acordo com a regulamentação turística, por um período de 3 anos.",
        //
        "s8-t1": "8. LINKS PARA SITES DE TERCEIROS.",
        "s8-p1": "A webapp pode incluir hiperlinks ou links que permitem o acesso a sites de terceiros. Os responsáveis por esses sites possuem suas próprias políticas de proteção de dados e são, em cada caso, responsáveis por seus próprios arquivos e práticas de privacidade.",
        "s8-p2": "Esta Política de Privacidade foi atualizada pela última vez em 06 de março de 2024."
    },
}

export default {
    "breadcrumbs": {
      "experiences": "Experiencias"
    },
    "card-experience": {
      "hour": "horas",
      "aprox": "aproximadamente",
      "min-aprox": "minutos",
      "and": "e",
      "recommed": "recomendado",
      "prominent": "destacado",
      "annulation-gratuite": "cancelación gratuíta",
      "non-refundable": "non reembolsable",
      "from": "Desde",
      "reviews": "reseñas"
    },
    "list-page": {
      "title": "Experiencias",
      "section-filter": {
        "label-search": "Filtrar por",
        "placeholder-input-search": "Introduce unha palabra clave",
        "label-price": "Prezo",
        "label-price-min": "Prezo mín.",
        "placeholder-price-min": "Mínimo...",
        "label-price-max": "Prezo máx.",
        "placeholder-price-max": "Máximo...",
        "label-duration": "Duración",
        "cancellation-policy": {
          "title": "Política de cancelación",
          "free-cancellation": "Cancelación gratuíta"
        },
        "duration": {
          "title": "Duración",
          "one-hour": "Ata 1 hora",
          "one-four-hour": "Entre 1 e 4 horas",
          "noon": "Medio día",
          "whole-day": "Día completo"
        },
        "score": {
          "title": "Puntuación",
          "star": "Estrelas"
        },
        "price": {
          "title": "Prezo",
          "0-100": "Entre 0 € e 100 €",
          "100-250": "Entre 100 € e 250 €",
          "250-500": "Entre 250 € e 500 €",
          "500": "+500 €"
        }
      },
      "section-filter-history": {
        "btn-price": "Ata {price_max}",
        "btn-close": "Quitar filtro"
      },
      "btn-more-filter": "Máis filtros",
      "btn-submit-filter": "Aplicar filtros",
      "text-count-list": "Experiencias atopadas",
      "text-count-list-mobile": "Atopáronse {count} experiencias.",
      "placeholder-search": "Buscar unha experiencia",
      "text-count-list-search-active": "Buscando todas as experiencias para",
      "text-count-list-search": "{count} experiencias atopadas para",
      "text-search-not-found": "Axusta a túa busca ou elimina filtros.",
      "without-results": {
        "title": "Non se atoparon experiencias",
        "text": "Non se atoparon experiencias nesta cidade. Recomendámosche explorar outras cidades para descubrir actividades diversas."
      },
      "btn-load-more": "Cargar máis"
    },
    "detail-page": {
      "tag-recommend": "Recomendado",
      "tag-featured": "Destacado",
      "text-variand-price": "O prezo varía segundo o tamaño do grupo",
      "tag-ticket-mobile": "Entrada móbil",
      "title-recomendation": "Recomendación",
      "title-description": "Descrición",
      "title-description-mobile": "Descrición xeral",
      "tooltip-language": "{language} e {numbers} máis",
      "open-collapse-description": "Ver máis",
      "close-collapse-description": "Ver menos",
      "title-include": "Que inclúe",
      "title-location": "Localización",
      "btn-eye-map": "Ver en Google Maps",
      "subtitle-point-start": "Punto de encontro",
      "subtitle-point-end": "Punto de finalización",
      "ends-point-at-meeting-point": "Esta actividade remata no punto de encontro inicial",
      "title-other-information": "Información adicional",
      "title-politic-cancelation": "Política de cancelación",
      "text-politic-standar-1": "Pode cancelar ata 24 horas antes da experiencia para obter un reembolso completo.",
      "text-politic-standar-2": "Para obter un reembolso completo, debe cancelar polo menos 24 horas antes da hora de inicio da experiencia.",
      "text-politic-standar-3": "Calquera cambio realizado con menos de 24 horas de antelación non será aceptado.",
      "text-politic-standar-4": "Se cancela con menos de 24 horas de antelación, non se reembolsará o importe abonado.",
      "text-politic-standar-5": "Os horarios límite baséanse na hora local da experiencia.",
      "text-politic-notrembolsable": "Esta experiencia non é reembolsable e non se pode modificar por ningún motivo. Se a cancela ou solicita unha modificación, non se devolverá o importe abonado.",
      "opinions-word": "opinións",
      "variant-price": "O prezo varía segundo o tamaño do grupo",
      "when-experience-label": "Cando queres gozar desta experiencia?",
      "maximum-reserves-label": "Podes reservar un máximo de {maxSeats} prazas",
      "availability-label": "Ver dispoñibilidade",
      "free-cancellation-label": "Cancelación gratuíta",
      "non-refundable-cancellation-label": "Non reembolsable",
      "check-the-availability-of-this-experience": "Consulta a dispoñibilidade desta experiencia",
      "btn-see-availability-on-viator": "Ver dispoñibilidade en Viator",
      "title-general-information": "Información xeral",
      "tag-paper-ticket": "Entrada en papel",
      "offered-in": "Ofrecido en"
    }
  }
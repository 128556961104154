export default {
    "account": "Nire kontua",
    "greeting": "Kaixo, {name}!",
    "my_stays": {
        "title": "Nire egonaldiak",
        "subtitle_active": "Kudeatu zure egonaldiak",
        "subtitle_inactive": "Sortu zure hurrengo egonaldia"
    },
    "personal_info": {
        "title": "Informazio pertsonala",
        "description": "Konfiguratu zure informazio pertsonala"
    },
    "language": {
        "title": "Hizkuntza hobespena",
        "description": "Hautatu zure lehenetsitako hizkuntza"
    },
    "logout": "Saioa itxi",
    "share_stay": "Egonaldia partekatu",
    "page_personal_info": {
        "title": "Informazio pertsonala",
        "change_photo": "Argazkia aldatu",
        "change_password": "Pasahitza aldatu",
        "btn_save": "Gorde",
        "form": {
            "name": {
                "label": "Izena",
                "placeholder": "Sartu zure izena"
            },
            "last_name": {
                "label": "Abizenak",
                "placeholder": "Sartu zure abizena"
            },
            "email": {
                "label": "Posta elektronikoa",
                "placeholder": "Sartu zure posta"
            },
            "number_phone": {
                "label": "Telefono zenbakia",
                "placeholder": "Sartu zure telefonoa"
            },
            "password": {
                "label": "Pasahitza",
                "placeholder": "Sartu zure pasahitza"
            }
        }
    },
    "book_next_stay": {
        "title": "Erreserbatu zure hurrengo egonaldia",
        "description": "Planifikatu zure hurrengo ihesaldia"
    },
    "rewards": {
        "title": "Gonbidatu lagun bat",
        "description": "Partekatu abantaila esklusiboak",
        "button-recommend": "Gomendatu lagun bat orain",
        "referrals": {
            "title": "Gomendatu lagun bat!",
            "discount": "{amount} deskontua",
            "description": "Bidali zure erreferentzia kodea. {amount} deskontua lortuko du kode horri esker.",
        },
        "referent": {
            "title": "Gomendatu lagun bat eta biok irabazi!",
            "titleReferred": "{amount} deskontua gomendatutakoarentzat",
            "descerptionReferred": "Bidali zure erreferentzia kodea. {amount} deskontua lortuko du kode horri esker.",
            "titleReferent": "{amount} deskontua jaso",
            "descerptionReferent": "Kodea erabili eta balidatzean, {amount} deskontua jasoko duzu."
        },
        "titleReferentProfile": "Dirua irabazi",
        "descriptionReferentProfile": "Gomendatu lagun bat onurak lortzeko"
    },
    "next-reserve": {
        "reserve-button": "Erreserbatu",
        "continue-title": "Jarraitu nahi duzu?",
        "continue-message": "Hotelaren webgunera birbideratuko zara erreserba prozesuarekin jarraitzeko.",
        "continue-yes": "Bai, jarraitu",
        "continue-cancel": "Utzi",
    },
};

export default {
    "guestLog": {
      "title": "Toegang tot de webapp",
      "name": {
        "label": "Voor- en achternaam",
        "placeholder": "Voer je voor- en achternaam in"
      },
      "email": {
        "label": "E-mailadres",
        "placeholder": "Voer je e-mailadres in",
        "error": "Ongeldig e-mailadres"
      },
      "button": "Volgende",
      "backButton": "Terug",
      "processing": "Bezig met verwerken"
    }
  }
  
export default {
  "information": "Informazioa",
  "facilities": "Instalazioak",
  "lodging": "ostatu",
  "apartment": "apartamentu",
  "buttons_home": {
    "wifi": "Wifi",
    "call": "Deitu",
    "standards": "Araudiak",
    "share_stay": "Egonaldia partekatu"
  },
  "utils": {
    "see_more": "Ikusi gehiago",
    "see_all": "Ikusi dena",
    "see_less": "Ikusi gutxiago",
    "copy": "Kopiatu",
    "from": "Hasiera",
    "to": "Amaiera",
    "our_networks": "Gure sareak"
  }
};

export default {
    "cmn": "Mandarijn",
    "es": "Spaans",
    "en": "Engels",
    "hi": "Hindi",
    "ar": "Arabisch",
    "pt": "Portugees",
    "bn": "Bengaals",
    "ru": "Russisch",
    "ja": "Japans",
    "pa": "Punjabi",
    "de": "Duits",
    "jv": "Javaans",
    "wuu": "Wu-Chinees",
    "ms": "Maleis",
    "te": "Telugu",
    "vi": "Vietnamees",
    "ko": "Koreaans",
    "fr": "Frans",
    "mr": "Marathi",
    "ta": "Tamil",
    "ur": "Urdu",
    "tr": "Turks",
    "it": "Italiaans",
    "yue": "Cantonees",
    "th": "Thais",
    "gu": "Gujarati",
    "mnp": "Min Bei-Chinees",
    "fa": "Perzisch",
    "pl": "Pools",
    "ps": "Pasjtoe",
    "hsn": "Hunan-Chinees",
    "ml": "Malayalam",
    "my": "Birmaans",
    "hak": "Hakka-Chinees",
    "uk": "Oekraïens",
    "fil": "Filipijns",
    "ro": "Roemeens",
    "nl": "Nederlands",
    "ca": "Catalaans",
    "gl": "Galicisch",
    "eu": "Baskisch",
    "ku": "Koerdisch",
    "hr": "Kroatisch",
    "hu": "Hongaars",
    "el": "Grieks",
    "cs": "Tsjechisch",
    "sv": "Zweeds",
    "fi": "Fins",
    "sk": "Slowaaks",
    "sr": "Servisch",
    "no": "Noors",
    "he": "Hebreeuws",
    "da": "Deens",
    "zh_CN": "Vereenvoudigd Chinees",
    "title": "Taalvoorkeur",
    "ca": "Catalaans",
    "eu": "Baskisch",
    "gl": "Galisisch",
    "nl": "Nederlands"
  }
  
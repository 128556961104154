export default {
    "log-or-register": {
      "title": "Hasi saioa edo erregistratu",
      "welcome-title": "Ongi etorri!",
      "welcome-msg": "Gozatu gure WebAppaz eta kudeatu zure egonaldiak gure hoteletan.",
      "input": "Idatzi zure posta elektronikoa",
      "text-error": "Sartutako posta elektronikoaren formatua ez da zuzena",
      "continue-button": "Jaraitu",
      "continue-google": "Jarraitu Googlerekin",
      "continue-fb": "Jarraitu Facebookekin"
    },
    "log": {
      "title": "Hasi saioa",
      "input-password": "Idatzi pasahitza",
      "password-text-error": "Sartutako pasahitza ez da zuzena",
      "continue-button": "Jaraitu",
      "forgot-question": "Pasahitza ahaztu duzu?",
      "input-show-pass": "Erakutsi",
      "input-hide-pass": "Ezkutatu",
      "entering": "Saioa hasten"
    },
    "register": {
      "title": "Amaitu erregistroa",
      "input-name": "Idatzi zure izena",
      "input-email": "Idatzi zure posta elektronikoa",
      "input-email-error": "Sartutako posta elektronikoaren formatua ez da zuzena",
      "input-password": "Idatzi pasahitza",
      "continue-button": "Onartu eta jarraitu",
      "text-info-1": "komunikazio komertzialak eta produktu eta zerbitzuei buruzko promozio pertsonalizatuak bidaliko dizkizu posta elektronikoz, SMS bidez edo beste bide elektroniko batzuen bidez",
      "text-info-2": "Ez dut mezu promozionalik jaso nahi.",
      "policies-text": "Onartu eta jarraitu hautatuz, onartzen dut",
      "policies-title": "Pribatutasun Politika.",
      "the-chain": "Kateak",
      "password-info": "Pasahitzak gutxienez 8 karaktere izan behar ditu.",
      "complete-register": "Erregistroa osatzen"
    },
    "reset-pass": {
      "title": "Aldatu pasahitza",
      "input-placeholder": "Idatzi zure pasahitz berria",
      "input-input-error": "Sartutako pasahitza ez da zuzena",
      "continue-button": "Aldatu pasahitza",
      "greeting": "Kaixo",
      "title-reset": "Pasahitza berrezarri",
      "msg": "Zure kontuko pasahitza berrezartzeko esteka bat eskatu duzu gure WebAppean. Egin klik beheko botoian prozesua hasteko.",
      "link": "Berrezarri pasahitza"
    },
    "hotel-list": {
      "title": "Hautatu ostatu bat gure WebAppean zure egonaldia sortzeko",
      "amount-text": "kateko ostatuak",
      "stars-text": "izar",
      "star-text": "izar"
    },
    "my-stay": {
      "title": "Nire egonaldia"
    },
    "saving-changes": "Aldaketak gordetzen"
  }
  
export default {
    "title": "Missatges",
    "availabilty": "Disponible",
    "not-availabilty": "No disponible",
    "languages-text": "Et donem la benvinguda al xat!<br>Pots comunicar-te amb el nostre personal en aquests idiomes:",
    "automatic-response": "Resposta automàtica",
    "input": "Escriu un missatge...",
    "horary-title": "Horaris del xat",
    "nameDayLunes": "Dilluns",
    "nameDayMartes": "Dimarts",
    "nameDayMiércoles": "Dimecres",
    "nameDayJueves": "Dijous",
    "nameDayViernes": "Divendres",
    "nameDaySábado": "Dissabte",
    "nameDayDomingo": "Diumenge",
    "tabs": {
      "inbox": "bústia",
      "chat": "xat"
    }
  }
  
export default {
    'cafeterias': 'cafetarías',
    'heladerias': 'xeaderías',
    'monumentos': 'monumentos',
    'museos': 'museos',
    'zonas-verdes': 'zonas verdes',
    'cafeterias-y-postres': 'cafetarías e sobremesas',
    'restaurantes': 'restaurantes',
    'tapas': 'tapas',
    'vida-nocturna': 'vida nocturna',
    'copas': 'copas',
    'compras': 'compras',
    'otros': 'outros'
  }
  
export default {
    "mobileTitle": "Información do hotel",
    "info": "Información de contacto",
    "horary": "Horario de Check in / Check out",
    "checkin": "Chegada a partir das",
    "checkout": "Saída ata as",
    "social": "Atópanos en...",
    "msgCopyPhone": "Teléfono copiado con éxito",
    "msgCopyEmail": "Correo electrónico copiado con éxito",
    "msgCopyAddress": "Enderezo copiado con éxito"
}

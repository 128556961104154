export default {
    "breadcrumbs": {
      "experiences": "Ervaringen"
    },
    "card-experience": {
      "hour": "uur",
      "aprox": "ongeveer",
      "min-aprox": "minuten",
      "and": "en",
      "recommed": "aanbevolen",
      "prominent": "uitgelicht",
      "annulation-gratuite": "gratis annuleren",
      "non-refundable": "niet-restitueerbaar",
      "from": "Vanaf",
      "reviews": "beoordelingen"
    },
    "list-page": {
      "title": "Ervaringen",
      "section-filter": {
        "label-search": "Filteren op",
        "placeholder-input-search": "Voer een trefwoord in",
        "label-price": "Prijs",
        "label-price-min": "Min. prijs",
        "placeholder-price-min": "Minimum...",
        "label-price-max": "Max. prijs",
        "placeholder-price-max": "Maximum...",
        "label-duration": "Duur",
        "cancellation-policy": {
          "title": "Annuleringsbeleid",
          "free-cancellation": "Gratis annuleren"
        },
        "duration": {
          "title": "Duur",
          "one-hour": "Tot 1 uur",
          "one-four-hour": "Tussen 1 en 4 uur",
          "noon": "Halve dag",
          "whole-day": "Hele dag"
        },
        "score": {
          "title": "Beoordeling",
          "star": "Sterren"
        },
        "price": {
          "title": "Prijs",
          "0-100": "Tussen 0 € en 100 €",
          "100-250": "Tussen 100 € en 250 €",
          "250-500": "Tussen 250 € en 500 €",
          "500": "+500 €"
        }
      },
      "section-filter-history": {
        "btn-price": "Tot {price_max}",
        "btn-close": "Filter verwijderen"
      },
      "btn-more-filter": "Meer filters",
      "btn-submit-filter": "Filters toepassen",
      "text-count-list": "Ervaringen gevonden",
      "text-count-list-mobile": "{count} ervaringen gevonden.",
      "placeholder-search": "Zoek een ervaring",
      "text-count-list-search-active": "Zoek alle ervaringen voor",
      "text-count-list-search": "{count} ervaringen gevonden voor",
      "text-search-not-found": "Pas je zoekopdracht aan of verwijder enkele filters.",
      "without-results": {
        "title": "Geen ervaringen gevonden",
        "text": "Er zijn geen ervaringen gevonden in deze stad. Probeer andere steden te ontdekken voor meer activiteiten."
      },
      "btn-load-more": "Meer laden"
    },
    "detail-page": {
      "tag-recommend": "Aanbevolen",
      "tag-featured": "Uitgelicht",
      "text-variand-price": "De prijs varieert afhankelijk van de groepsgrootte",
      "tag-ticket-mobile": "Mobiel ticket",
      "title-recomendation": "Aanbeveling",
      "title-description": "Beschrijving",
      "title-description-mobile": "Algemene beschrijving",
      "tooltip-language": "{language} en {numbers} meer",
      "open-collapse-description": "Meer weergeven",
      "close-collapse-description": "Minder weergeven",
      "title-include": "Wat is inbegrepen",
      "title-location": "Locatie",
      "btn-eye-map": "Bekijk op Google Maps",
      "subtitle-point-start": "Ontmoetingspunt",
      "subtitle-point-end": "Eindpunt",
      "ends-point-at-meeting-point": "Deze activiteit eindigt op het oorspronkelijke ontmoetingspunt",
      "title-other-information": "Aanvullende informatie",
      "title-politic-cancelation": "Annuleringsbeleid",
      "text-politic-standar-1": "Annuleer tot 24 uur van tevoren voor volledige terugbetaling.",
      "text-politic-standar-2": "Voor volledige terugbetaling moet je minimaal 24 uur van tevoren annuleren.",
      "text-politic-standar-3": "Wijzigingen minder dan 24 uur van tevoren worden niet geaccepteerd.",
      "text-politic-standar-4": "Bij annulering binnen 24 uur wordt geen restitutie verleend.",
      "text-politic-standar-5": "De deadline is gebaseerd op de lokale tijd van de ervaring.",
      "text-politic-notrembolsable": "Deze ervaring is niet-restitueerbaar en kan om geen enkele reden worden gewijzigd.",
      "opinions-word": "beoordelingen",
      "variant-price": "De prijs varieert afhankelijk van de groepsgrootte",
      "when-experience-label": "Wanneer wil je van deze ervaring genieten?",
      "maximum-reserves-label": "Je kunt maximaal {maxSeats} plaatsen reserveren",
      "availability-label": "Bekijk beschikbaarheid",
      "free-cancellation-label": "Gratis annuleren",
      "non-refundable-cancellation-label": "Niet-restitueerbaar",
      "check-the-availability-of-this-experience": "Controleer de beschikbaarheid van deze ervaring",
      "btn-see-availability-on-viator": "Bekijk beschikbaarheid op Viator",
      "title-general-information": "Algemene informatie",
      "tag-paper-ticket": "Papieren ticket",
      "offered-in": "Aangeboden in"
    }
  }
export default {
    "facilitiesWord": "Instalacións",
    "horaryWord": "Horario",
    "detailPage": {
      "sectionSchedules": {
        "title": "Horarios",
        "openAlways": "Aberto todos os días as 24h",
        "empty": "Sen horarios especificados. Consulta vía chat",
        "days": {
          "Lunes": "Luns",
          "Martes": "Martes",
          "Miércoles": "Mércores",
          "Jueves": "Xoves",
          "Viernes": "Venres",
          "Sábado": "Sábado",
          "Domingo": "Domingo"
        }
      }
    }
  }
  
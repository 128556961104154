export default {
    "gocheckinButton": "Completa il check-in",
    "cardBanner": {
        "title": "Check-in disponibile",
        "subtitle": "Ottimizza il tuo tempo, completa il check-in ora!"
    },
    "card-guest": {
        "resend-staylink": "Reinvia il link del soggiorno",
        "invite-to-completecheckin": "Invita a completare il check-in",
        "delete-checkin": "Elimina il check-in dell'ospite",
        "delete-guest": "Elimina ospite"
    },
    "delete-guest": {
        "title": "Eliminare l'ospite dal soggiorno?",
        "info": "Rimuoverai questo ospite dal tuo soggiorno. Potrai inviare nuovamente un invito in seguito.",
        "confirm": "Elimina ospite",
        "cancel": "Annulla"
    },
    "delete-checkin": {
        "title": "Eliminare il check-in dell'ospite?",
        "info": "Eliminerai le informazioni del check-in di questo ospite. L'ospite dovrà completare nuovamente il check-in.",
        "confirm": "Elimina check-in",
        "cancel": "Annulla"
    },
    "header": {
        "mystay": "Soggiorno",
        "guests": "Ospiti"
    },
    "form": {
        "why-data": "Perché chiediamo questi dati?",
        "policies-info-1": "Premendo il pulsante 'Completa', dichiaro di accettare le",
        "policies-info-2": "politiche e regole",
        "policies-info-3": "dell'alloggio",
        "why-data-title": "Perché chiediamo questi dati?",
        "why-data-p1": "La legge locale richiede che gli alloggi turistici registrino tutti i loro ospiti.",
        "why-data-p2": "Per questo motivo, abbiamo bisogno di alcune informazioni personali di base e obbligatorie.",
        "why-data-p3": "Completare il check-in online prima del tuo arrivo ti farà risparmiare tempo, evitando di dover fornire queste informazioni sul posto.",
        "why-data-p4": "I tuoi dati saranno trattati in modo sicuro e in conformità con il Regolamento Generale sulla Protezione dei Dati (GDPR).",
        "why-data-p5": "Puoi ottenere ulteriori dettagli nella nostra informativa sulla privacy.",
        "changes-bar-next": "Avanti",
        "changes-bar-finish": "Completa",
        "changes-bar-send-data": "Invio dati",
        "changes-bar-previus": "Passo precedente",
        "input-2-label": "Cognome",
        "input-3-label": "Secondo cognome",
        "input-3-alert": "Se il tuo tipo di documento è il DNI spagnolo, compila questo campo",
        "input-4-label": "Data di nascita",
        "input-4-plchdr": "GG/MM/AAAA",
        "input-5-label": "Sesso",
        "input-5-plchdr": "Seleziona un'opzione",
        "input-6-label": "Telefono",
        "input-7-label": "Email",
        "input-8-label": "Adulto responsabile",
        "input-8-plchdr": "Nome e cognome dell'adulto",
        "input-9-label": "Relazione di parentela",
        "input-9-plchdr": "Come l'adulto è collegato al minore",
        "input-10-label": "Nazionalità",
        "input-10-plchdr": "Digita per vedere suggerimenti",
        "input-11-label": "Tipo di documento",
        "input-11-plchdr": "Seleziona un'opzione",
        "input-11-error": "Per favore, compila il campo 'Secondo cognome'",
        "input-12-label": "Numero di supporto del documento",
        "input-12-plchdr": "Solo per NIE o DNI spagnolo",
        "input-12-error": "Il numero di caratteri inserito non è valido",
        "input-13-label": "Numero del documento",
        "input-13-plchdr": "Inserisci il numero del documento selezionato",
        "input-14-label": "Paese di residenza",
        "input-14-plchdr": "Digita per vedere suggerimenti",
        "input-15-label": "Codice postale",
        "input-15-plchdr": "Digita per vedere suggerimenti",
        "input-16-label": "Città",
        "input-16-plchdr": "Digita per vedere suggerimenti",
        "input-17-label": "Indirizzo di residenza",
        "input-17-plchdr": "Digita per vedere suggerimenti",
        "head-title-1": "Dati personali",
        "head-title-2": "Dettagli di identificazione",
        "head-title-3": "Sondaggio",
        "exit-title": "Uscire dal check-in?",
        "exit-description": "Se esci ora, le informazioni inserite non saranno salvate",
        "exit-button": "Esci",
        "exit-continue": "Continua con il check-in",
        "norms-title": "Politiche e Regole",
        "norms-subtitle": "Penalità:",
        "why-data-continue": "Capito",
        "see-policies": "Vedi l'informativa sulla privacy"
    },
    "autocomplete": {
        "cardbanner-title": "Compilazione automatica del modulo",
        "cardbanner-subtitle": "Risparmia tempo scansionando il tuo documento",
        "title": "Compilazione automatica del modulo",
        "description-1": "Scannerai il tuo passaporto o la tua carta d'identità utilizzando la fotocamera del tuo dispositivo.",
        "description-2": "Devi scansionare il codice MRZ.",
        "description-3": "Qui sotto ti indichiamo dove trovarlo.",
        "passport-title": "Passaporto",
        "dninie-title": "Carta d'identità",
        "scan-doc": "Scansiona documento",
        "backtoform": "Torna al modulo",
        "error-scan": "Errore: Devi inquadrare il codice MRZ del documento",
        "browser-warning": "Accedi tramite un browser come Google Chrome per utilizzare questa funzionalità.",
        "rescan-document": "Riscansiona il documento"
    }
}

export default{
    "create":{
        "title":"Crea tu estancia",
        "check-date":{
            "label":"Fecha Check-in/ Check-out",
            "placeholder": "dd/mm/aaaa - dd/mm/aaaa",
        },
        "continue-button":"Crear y acceder a la estancia",
        "creating" : "Creando estancia"
    },
    "share": {
        "title": "Únete a nuestra estancia en {hotel}",
        "text": "Únete ahora y disfruta de todas las ventajas de la WebApp para aprovechar al máximo nuestra experiencia"
    },
    "detail": {
        "title": "Mis estancias",
        "createBtn": "Crear estancia",
        "editBtn": "Editar estancia"
    },
    "edit": {
        "title": "Editar estancia",
        "information-stay": "Información de la estancia",
        "input": {
            "medio-reserva": {
                "label": "Medio de la reserva",
                "placeholder": "Seleccionar medio de la reserva",
                "n-room": "Nº habitación",
            }
        },
        "calendar": "Calendario",
        "guests": "Huéspedes",
        "saveBtn": "Guardar",
        "share": "Compartir estancia",
        "guest": "Huésped",
        "completeCheckin": "Completar check in",
        "waitingGuest": "Esperando acceso del huésped",
        "accessRoom": "Acceder a la estancia",
        "active": "Activo",
        "pageWebHotel": "Página web del hotel"
    },
    "reservation" : {
        "title": "Reserva tu próxima estancia",
        "button-reservation": "Reservar",
        'title-page': 'Selecciona un alojamiento para reservar y te redirigiremos a su página web.',
    }
}

export default {
  "information": "Informatie",
  "facilities": "Voorzieningen",
  "lodging": "accommodatie",
  "apartment": "appartement",
  "buttons_home": {
    "wifi": "Wifi",
    "call": "Bellen",
    "standards": "Regels",
    "share_stay": "Verblijf delen"
  },
  "utils": {
    "see_more": "Meer weergeven",
    "see_all": "Alles bekijken",
    "see_less": "Minder weergeven",
    "copy": "Kopiëren",
    "from": "Van",
    "to": "Tot",
    "our_networks": "Onze netwerken"
  }
};

export default {
    "privacy": {
      "title": "Privacybeleid",
      //
      "s1-t1": "1. IDENTIFICATIE EN VERANTWOORDELIJKE VOOR DE GEGEVENSVERWERKING.",
      "s1-p1": "{hotel} gevestigd op {address} en met btw-nummer {nif} is verantwoordelijk voor de persoonsgegevens die via de webapp worden verzameld.",
      "s1-p2": "Als je vragen hebt of meer informatie nodig hebt over de verwerking van je persoonsgegevens, neem dan contact op via {email}.",
      "s1-p3": "Contactgegevens van de functionaris voor gegevensbescherming: e-mailadres {email}, postadres [adres].",
      //
      "s2-t1": "2. VERZAMELDE PERSOONSGEGEVENS.",
      "s2-p1": "Bij gebruik van de webapp kunnen we de volgende informatie over jou verzamelen en verwerken:",
      "s2-p2": "Toegang tot de webapp. Bepaalde toegang kan vragen om een van de volgende gegevens:",
      "s2-p3": "Voor- en achternaam: om je te identificeren en je ervaring te personaliseren in de webapp.",
      "s2-p4": "Telefoonnummer of e-mailadres: om communicatie mogelijk te maken en meldingen te sturen over de webapp.",
      "s2-p5": "Check-in en check-out datum: om de verblijfsdata te kennen en relevante informatie aan te bieden.",
      "s2-p6": "Registratie van het verblijf: voornaam, achternaam, tweede achternaam, geslacht, nationaliteit, geboortedatum, geboorteplaats, geboorteland, land van verblijf, woonplaats, woonadres, soort identificatie, identificatienummer, uitgiftedatum, uitgifteplaats en -land, doel van het verblijf, visumnummer, handtekening.",
      "s2-p7": "Deelname aan enquêtes. Als je ervoor kiest deel te nemen aan tevredenheidsenquêtes, verzamelen wij je antwoorden om je ervaring te evalueren en onze diensten te verbeteren.",
      //
      "s3-t1": "3. DOELEINDEN VAN DE VERWERKING.",
      "s3-p1": "De persoonsgegevens die via de webapp worden verzameld, worden gebruikt voor de volgende doeleinden:",
      "s3-p2": "Toegang en gebruik van de webapp: om gepersonaliseerde toegang en gebruik mogelijk te maken, zodat de gast informatie kan verkrijgen over zijn verblijf, de stad en beschikbare toeristische activiteiten, en contact kan opnemen met het hotelpersoneel via de ingebouwde chat.",
      "s3-p3": "Tevredenheidsenquêtes: tijdens of na het verblijf kan de gast worden uitgenodigd om deel te nemen aan een enquête om zijn ervaring te evalueren en feedback te geven.",
      "s3-p4": "Meldingen: in bepaalde gevallen kunnen we meldingen sturen via e-mail of sms met betrekking tot ongelezen berichten of andere relevante informatie over het verblijf.",
      "s3-p5": "Commerciële communicatie: verzending van commerciële berichten en gepersonaliseerde aanbiedingen.",
      "s3-p6": "Informatie aan bevoegde autoriteiten, indien vereist door toeristische regelgeving.",
      //
      "s4-t1": "4. RECHTSGROND VAN DE VERWERKING.",
      "s4-p1": "De juridische basis voor de verwerking van persoonsgegevens van gasten is gebaseerd op:",
      "s4-p2": "Uitvoering van een overeenkomst waarbij de betrokkene partij is of op diens verzoek precontractuele maatregelen.",
      "s4-p3": "Gerechtvaardigd belang: in bepaalde omstandigheden kunnen we persoonsgegevens verwerken op basis van ons gerechtvaardigd belang, zolang dat belang niet wordt overschaduwd door de fundamentele rechten en vrijheden van de gast.",
      "s4-p4": "Naleving van een wettelijke verplichting: in bepaalde gevallen kan de verwerking van persoonsgegevens nodig zijn om aan wettelijke verplichtingen te voldoen.",
      //
      "s5-t1": "5. GEGEVENSDOORGIFTE.",
      "s5-p1": "Persoonsgegevens van gasten worden niet zonder voorafgaande geïnformeerde toestemming doorgegeven of overgedragen aan derden, behalve in de wettelijk voorziene gevallen.",
      "s5-p2": "Voor het goed functioneren van de webapp is het echter noodzakelijk dat bepaalde dienstverleners toegang hebben tot persoonsgegevens van gasten als gegevensverwerkers. Deze dienstverleners zijn verplicht om de gegevens uitsluitend te verwerken voor de door de host vastgestelde doeleinden en onder de voorwaarden die zijn vastgelegd in de respectieve verwerkersovereenkomsten.",
      "s5-p3": "Onder deze dienstverleners valt TheHoster (The Hoster Hospitality S.L. met btw-nummer B56861479 en contact-e-mailadres {email}), die de infrastructuur en technologie levert die nodig zijn voor het goed functioneren van de webapp. TheHoster treedt altijd op als gegevensverwerker en verwerkt de gegevens uitsluitend volgens instructies van de accommodatiehouder en voor de beoogde doeleinden.",
      //
      "s6-t1": "6. RECHTEN VAN DE GEBRUIKER.",
      "s6-p1": "a) Recht op toegang: je hebt het recht om te vragen of er persoonsgegevens van jou worden verwerkt en om toegang tot je opgeslagen persoonsgegevens te krijgen.",
      "s6-p2": "b) Recht op rectificatie: je hebt het recht om de rectificatie van je persoonsgegevens te vragen als deze onjuist of onvolledig zijn.",
      "s6-p3": "c) Recht op verwijdering: je hebt het recht om verwijdering van je persoonsgegevens te vragen wanneer de gegevens niet langer nodig zijn voor de doeleinden waarvoor ze zijn verzameld.",
      "s6-p4": "d) Recht op bezwaar: je hebt het recht om bezwaar te maken tegen de verwerking van je persoonsgegevens om redenen die verband houden met jouw specifieke situatie.",
      "s6-p5": "e) Recht op beperking van de verwerking: je hebt het recht om beperking van de verwerking van je persoonsgegevens aan te vragen, in welk geval ze alleen worden bewaard voor het instellen of verdedigen van claims.",
      "s6-p6": "f) Recht op gegevensoverdraagbaarheid: je hebt het recht om je persoonsgegevens die je aan een verwerkingsverantwoordelijke hebt verstrekt, in een gestructureerd, gangbaar en machineleesbaar formaat te ontvangen en deze aan een andere verwerkingsverantwoordelijke over te dragen.",
      "s6-p7": "g) Recht om niet te worden onderworpen aan geautomatiseerde individuele besluitvorming: je hebt het recht om niet te worden onderworpen aan een uitsluitend op geautomatiseerde verwerking gebaseerd besluit, waaronder profilering, dat rechtsgevolgen voor jou heeft of jou op vergelijkbare wijze in aanmerkelijke mate treft.",
      "s6-p8": "h) Recht om je gegeven toestemming in te trekken.",
      "s6-p9": "i) Recht om een klacht in te dienen bij de Spaanse Gegevensbeschermingsautoriteit als je van mening bent dat je rechten zijn geschonden.",
      //
      "s7-t1": "7. BEWAARTERMIJN VAN DE GEGEVENS.",
      "s7-p1": "Je gegevens worden bewaard zolang als nodig is voor de verschillende verwerkingsdoeleinden, en in elk geval minimaal 3 jaar volgens de toeristische regelgeving.",
      //
      "s8-t1": "8. LINKS NAAR WEBSITES VAN DERDEN",
      "s8-p1": "De webapp kan hyperlinks of links bevatten naar websites van derden. De beheerders van deze websites hebben hun eigen gegevensbeschermingsbeleid en zijn in elk geval zelf verantwoordelijk voor hun bestanden en hun privacypraktijken.",
      "s8-p2": "Dit privacybeleid is voor het laatst bijgewerkt op 6 maart 2024."
    }
  }
  
export default {
    "breadcrumbs": {
      "explore": "Explorar"
    },
    "list-page": {
      "title": "Explora en",
      "text-count-list": "{count} lugares atopados",
      "text-count-list-search": "{count} lugares atopados para",
      "text-count-list-search-active": "Buscar todos os lugares para",
      "text-search-not-found": "Axusta a túa busca ou proba noutra categoría",
      "btn-load-more": "Cargar máis"
    },
    "results": "Sen resultados...",
    "seaching": "Buscando...",
    "categorySearch": "atopados",
    "city": "Cidade",
    "placeholder-search": "Buscar destino",
    "types": {
      "que-visitar": "Que visitar",
      "donde-comer": "Onde comer",
      "ocio": "Lecer"
    },
    "categories": {
      "monumentos": "Monumentos",
      "museos": "Museos",
      "zonas-verdes": "Zonas verdes",
      "cafeterias-y-postres": "Cafetarías e sobremesas",
      "restaurantes": "Restaurantes",
      "vida-nocturna": "Vida nocturna",
      "copas": "Copas",
      "compras": "Compras",
      "otros": "Outros"
    },
    "detail": {
      "titleList": "Explora",
      "opinionsTitle": "Opinións da info. turística",
      "opinionsWord": "Reseñas",
      "recommendationWord": "Recomendación",
      "featured": "Destacado",
      "callWord": "Chamar",
      "dataInterest": {
        "title": "Datos de interese",
        "priceRange": "Rangos de prezos",
        "diet": "Dietas especiais",
        "others": "Outros"
      },
      "specialRegimes": "Réximes especiais",
      "additionalInformation": "Información adicional",
      "copy": "Copiar",
      "showLess": "Mostrar menos",
      "showMore": "Mostrar máis",
      "recommended": "Recomendado",
      "recommendedTitle": "Recomendación do {lodging}",
      "buttoms": {
        "web": "Web",
        "call": "Chamar",
        "menu": "Menú",
        "email": "E-mail"
      },
      "hours": {
        "title": "Horarios",
        "monday": "luns",
        "tuesday": "martes",
        "wednesday": "mércores",
        "thursday": "xoves",
        "friday": "venres",
        "saturday": "sábado",
        "sunday": "domingo"
      },
      "locationWord": "Localización",
      "starWord": "Estrela",
      "starsWord": "Estrelas",
      "ratingTitle": "Puntuación",
      "noComment": "Non se escribiu ningún comentario",
      "seeMoreComment": "Ver máis",
      "seeAllComments": "Ver todas as opinións",
      "titleAllReviews": "Opinións da info. turística",
      "exploreIn": "Explora en",
      "filters": {
        "title": "Filtrar por",
        "distanceLogding": "Distancia do",
        "recommended": "Recomendado",
        "ratings": "Valoracións",
        "star": "estrela",
        "score": "Puntuación",
        "stars": "estrelas",
        "remove": "Quitar todos",
        "apply": "Aplicar filtros",
        "distante": {
          "oneKm": "Entre 1 km e 3 km",
          "threeKm": "Entre 3 km e 5 km",
          "fiveKm": "Entre 5 km e 10 km",
          "tenKm": "10 km+"
        }
      }
    }
  }
  
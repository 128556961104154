export default {
    "guestLog": {
      "title": "Accés a la webapp",
      "name": {
        "label": "Nom i cognoms",
        "placeholder": "Introdueix el teu nom i cognoms"
      },
      "email": {
        "label": "Correu electrònic",
        "placeholder": "Introdueix el teu correu electrònic",
        "error": "Correu electrònic no vàlid"
      },
      "button": "Següent",
      "backButton": "Tornar enrere",
      "processing": "Processant"
    }
  }
  
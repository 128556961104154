export default {
    "gocheckinButton": "Check-in voltooien",
    "cardBanner": {
      "title": "Check-in beschikbaar",
      "subtitle": "Bespaar tijd, doe nu je check-in!"
    },
    "card-guest": {
      "resend-staylink": "Verblijflink opnieuw verzenden",
      "invite-to-completecheckin": "Uitnodigen voor het voltooien van Check-in",
      "delete-checkin": "Check-in van gast verwijderen",
      "delete-guest": "Gast verwijderen"
    },
    "delete-guest": {
      "title": "Gast uit verblijf verwijderen?",
      "info": "Deze gast wordt uit je verblijf verwijderd. Je kunt hem/haar later opnieuw uitnodigen.",
      "confirm": "Gast verwijderen",
      "cancel": "Annuleren"
    },
    "delete-checkin": {
      "title": "Check-in van de gast verwijderen?",
      "info": "De check-in informatie van deze gast wordt verwijderd. Hij/zij moet opnieuw inchecken.",
      "confirm": "Check-in verwijderen",
      "cancel": "Annuleren"
    },
    "header": {
      "mystay": "Verblijf",
      "guests": "Gasten"
    },
    "form": {
      "why-data": "Waarom vragen wij deze gegevens?",
      "policies-info-1": "Door op 'Voltooien' te klikken, verklaar ik akkoord te gaan met de",
      "policies-info-2": "beleidsregels en voorschriften",
      "policies-info-3": "van de accommodatie",
      "why-data-title": "Waarom vragen wij deze gegevens?",
      "why-data-p1": "Lokale wetgeving vereist dat toeristische accommodaties al hun gasten registreren.",
      "why-data-p2": "Daarom hebben we enkele basis- en verplichte persoonsgegevens nodig.",
      "why-data-p3": "Als je vóór aankomst online incheckt, bespaar je tijd.",
      "why-data-p4": "Je gegevens worden veilig verwerkt volgens de AVG.",
      "why-data-p5": "Meer informatie vind je in ons privacybeleid.",
      "changes-bar-next": "Volgende",
      "changes-bar-finish": "Voltooien",
      "changes-bar-send-data": "Gegevens verzenden",
      "changes-bar-previus": "Vorige stap",
      "input-2-label": "Achternaam",
      "input-3-label": "Tweede achternaam",
      "input-3-alert": "Vul dit veld in als je een Spaanse DNI hebt",
      "input-4-label": "Geboortedatum",
      "input-4-plchdr": "DD/MM/JJJJ",
      "input-5-label": "Geslacht",
      "input-5-plchdr": "Selecteer een optie",
      "input-6-label": "Telefoon",
      "input-7-label": "E-mailadres",
      "input-8-label": "Verantwoordelijke volwassene",
      "input-8-plchdr": "Naam van de volwassene",
      "input-9-label": "Relatie",
      "input-9-plchdr": "Hoe is de volwassene verbonden aan het kind",
      "input-10-label": "Nationaliteit",
      "input-10-plchdr": "Typ om suggesties te zien",
      "input-11-label": "Type document",
      "input-11-plchdr": "Selecteer een optie",
      "input-11-error": "Vul het veld 'Tweede achternaam' in",
      "input-12-label": "Documentnummer ondersteuning",
      "input-12-plchdr": "Alleen voor NIE of Spaanse DNI",
      "input-12-error": "Ongeldig aantal tekens",
      "input-13-label": "Documentnummer",
      "input-13-plchdr": "Voer het documentnummer in",
      "input-14-label": "Woonland",
      "input-14-plchdr": "Typ om suggesties te zien",
      "input-15-label": "Postcode",
      "input-15-plchdr": "Typ om suggesties te zien",
      "input-16-label": "Gemeente",
      "input-16-plchdr": "Typ om suggesties te zien",
      "input-17-label": "Woonadres",
      "input-17-plchdr": "Typ om suggesties te zien",
      "head-title-1": "Persoonlijke gegevens",
      "head-title-2": "Identificatiegegevens",
      "head-title-3": "Vragenlijst",
      "exit-title": "Check-in verlaten?",
      "exit-description": "Als je nu stopt, worden je ingevoerde gegevens niet opgeslagen",
      "exit-button": "Verlaten",
      "exit-continue": "Doorgaan met Check-in",
      "norms-title": "Beleid en Regels",
      "norms-subtitle": "Boete:",
      "why-data-continue": "Begrepen",
      "see-policies": "Bekijk privacybeleid"
    },
    "autocomplete": {
      "cardbanner-title": "Formulier automatisch invullen",
      "cardbanner-subtitle": "Bespaar tijd door je document te scannen",
      "title": "Formulier automatisch invullen",
      "description-1": "Je scant je paspoort of ID-kaart met de camera van je apparaat.",
      "description-2": "Je moet de MRZ-code scannen.",
      "description-3": "Hieronder laten we je zien waar die te vinden is.",
      "passport-title": "Paspoort",
      "dninie-title": "DNI/NIE",
      "scan-doc": "Document scannen",
      "backtoform": "Terug naar formulier",
      "error-scan": "Fout: Richt op de MRZ-code van het document",
      "browser-warning": "Gebruik een browser zoals Google Chrome om deze functie te gebruiken.",
      "rescan-document": "Document opnieuw scannen"
    }
  }
  
export default {
    "header": {
      "home": "startpagina",
      "facilities": "voorzieningen",
      "hotel": "hotel",
      "hostal": "hostel",
      "destination": "bestemming",
      "experiences": "ervaringen",
      "messages": "berichten"
    },
    "dropdownLanguage": {
      "title": "taal"
    },
    "footer": {
      "legal-warning": "juridische kennisgeving",
      "privacy-policy": "privacybeleid",
      "privacy-cookies": "cookiebeleid",
      "terms-conditions": "algemene voorwaarden"
    },
    "input-search": {
      "search": "Zoeken",
      "placeholder": "Bestemmingen, ervaringen...",
      "from": "vanaf"
    }
  };
  
export default {
    "header": {
      "home": "inicio",
      "facilities": "instalacións",
      "hotel": "hotel",
      "hostal": "hostal",
      "destination": "destino",
      "experiences": "experiencias",
      "messages": "mensaxes"
    },
    "dropdownLanguage": {
      "title": "idioma"
    },
    "footer": {
      "legal-warning": "aviso legal",
      "privacy-policy": "política de privacidade",
      "privacy-cookies": "política de cookies",
      "terms-conditions": "termos e condicións"
    },
    "input-search": {
      "search": "Buscar",
      "placeholder": "Destinos, experiencias...",
      "from": "desde"
    }
  };
  
export default {
    "account": "My account",
    "greeting": "Hello, {name}!",
    "my_stays": {
        "title": "My stays",
        "subtitle_active": "Manage your stays",
        "subtitle_inactive": "Create your next stay"
    },
    "personal_info": {
        "title": "Personal information",
        "description": "Set up your personal information"
    },
    "language" : {
        "title": "Language",
        "description": "Select your default language"
    },
    "logout": "Log out",
    "share_stay": "Share Stay",
    "page_personal_info": {
        "title": "Personal Information",
        "change_photo": "Change photo",
        "change_password": "Change password",
        "btn_save": "Save",
        "form": {
            "name": {
                "label": "Name",
                "placeholder": "Enter your name"
            },
            "last_name": {
                "label": "Last Name",
                "placeholder": "Enter your last name"
            },
            "email": {
                "label": "Email",
                "placeholder": "Enter your email"
            },
            "number_phone": {
                "label": "Phone Number",
                "placeholder": "Enter your phone number"
            },
            "password": {
                "label": "Password",
                "placeholder": "Enter your password"
            }
        }
    },
    "book_next_stay": {
        "title": "Book your next stay",
        "description": "Plan your next getaway"
    },
    "rewards": {
        "title": "Invite a Friend",
        "description": "Share Exclusive Benefits",
        "button-recommend": "Recommend a Friend Now",
        "referrals": {
            "title": "Recommend a Friend!",
            "discount": "{amount} discount",
            "description": "Send your referral code. They will get a {amount} discount thanks to your code."
        },
        "referent": {
            "title": "Recommend a Friend and Both Win!",
            "titleReferred": "{amount} discount for the referred",
            "descerptionReferred": "Send your referral code. They will get a {amount} discount thanks to your code.",
            "titleReferent": "Get {amount} discount",
            "descerptionReferent": "Once the code is validated and used, you will get a {amount} discount."
        },
        "titleReferentProfile": "Earn money",
        "descriptionReferentProfile": "Refer a friend to get benefits",
    },
    "next-reserve": {
        "reserve-button": "Reserve",
        "continue-title": "Do you want to continue?",
        "continue-message": "You will be redirected to the hotel's website to continue your reservation process.",
        "continue-yes": "Yes, continue",
        "continue-cancel": "Cancel"
    }


};

export default {
    "cmn": "mandarín",
    "es": "español",
    "en": "inglés",
    "hi": "hindi",
    "ar": "árabe",
    "pt": "portugués",
    "bn": "bengalí",
    "ru": "ruso",
    "ja": "japonés",
    "pa": "panjabi",
    "de": "alemán",
    "jv": "javanés",
    "wuu": "chino wu",
    "ms": "malayo",
    "te": "telugu",
    "vi": "vietnamita",
    "ko": "coreano",
    "fr": "francés",
    "mr": "maratí",
    "ta": "tamil",
    "ur": "urdu",
    "tr": "turco",
    "it": "italiano",
    "yue": "cantonés",
    "th": "tailandés",
    "gu": "gujarati",
    "mnp": "min bei chino",
    "fa": "persa",
    "pl": "polaco",
    "ps": "pastún",
    "hsn": "hunanés",
    "ml": "malayalam",
    "my": "birmano",
    "hak": "chino hakka",
    "uk": "ucraniano",
    "fil": "filipino",
    "ro": "rumano",
    "nl": "neerlandés",
    "ku": "kurdo",
    "hr": "croata",
    "hu": "húngaro",
    "el": "griego",
    "cs": "checo",
    "sv": "sueco",
    "fi": "finlandés",
    "sk": "eslovaco",
    "sr": "serbio",
    "no": "noruego",
    "he": "hebreo",
    "da": "danés",
    "zh_CN": "chino simplificado",
    "title": "Preferencia de idioma",
    "ca": "catalán",
    "eu": "euskera",
    "gl": "gallego",
    "nl": "holandés"
}
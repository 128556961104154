export default {
    "gocheckinButton": "Check-in osatu",
    "cardBanner": {
      "title": "Check-in erabilgarri",
      "subtitle": "Optimiza ezazu zure denbora, egin check-in orain!"
    },
    "card-guest": {
      "resend-staylink": "Bidali berriro egonaldiaren esteka",
      "invite-to-completecheckin": "Gonbidatu Check-in osatzera",
      "delete-checkin": "Ezabatu gonbidatuaren Check-in",
      "delete-guest": "Ezabatu gonbidatua"
    },
    "delete-guest": {
      "title": "Gonbidatua egonalditik ezabatu?",
      "info": "Gonbidatu hau zure egonalditik kenduko da. Geroago berriz bidali ahal izango diozu gonbidapena.",
      "confirm": "Ezabatu gonbidatua",
      "cancel": "Utzi"
    },
    "delete-checkin": {
      "title": "Gonbidatuaren Check-in ezabatu?",
      "info": "Check-in informazioa ezabatuko da eta berriro osatu beharko du.",
      "confirm": "Ezabatu Check-in",
      "cancel": "Utzi"
    },
    "header": {
      "mystay": "Egonaldia",
      "guests": "Gonbidatuak"
    },
    "form": {
      "why-data": "Zergatik eskatzen ditugu datu hauek?",
      "policies-info-1": "'Amaitu' botoia sakatzean, honako hau onartzen dut:",
      "policies-info-2": "politikak eta arauak",
      "policies-info-3": "ostatuaren",
      "why-data-title": "Zergatik eskatzen ditugu datu hauek?",
      "why-data-p1": "Tokiko legeek ostatu turistikoek beren gonbidatu guztiak erregistratzea eskatzen dute.",
      "why-data-p2": "Horregatik, oinarrizko eta derrigorrezko datu pertsonalak behar ditugu.",
      "why-data-p3": "Online check-in osatzeak denbora aurrezten lagunduko dizu zure iritsi aurretik.",
      "why-data-p4": "Zure datuak segurtasunez tratatuko dira eta DBEO araudia betez.",
      "why-data-p5": "Informazio gehiago gure pribatutasun-politikan aurki dezakezu.",
      "changes-bar-next": "Hurrengoa",
      "changes-bar-finish": "Amaitu",
      "changes-bar-send-data": "Datuak bidaltzen",
      "changes-bar-previus": "Aurreko urratsa",
      "input-2-label": "Lehen abizena",
      "input-3-label": "Bigarren abizena",
      "input-3-alert": "Zure dokumentua espainiar DNI bada, bete eremu hau",
      "input-4-label": "Jaiotze data",
      "input-4-plchdr": "EE/HH/UUUU",
      "input-5-label": "Sexua",
      "input-5-plchdr": "Aukeratu aukera bat",
      "input-6-label": "Telefonoa",
      "input-7-label": "Posta elektronikoa",
      "input-8-label": "Arduradun heldua",
      "input-8-plchdr": "Helduaren izen-abizenak",
      "input-9-label": "Senidetasun harremana",
      "input-9-plchdr": "Nola lotzen da heldua adingabekoarekin",
      "input-10-label": "Nazionalitatea",
      "input-10-plchdr": "Idatzi iradokizunak ikusteko",
      "input-11-label": "Dokumentu mota",
      "input-11-plchdr": "Aukeratu aukera bat",
      "input-11-error": "Mesedez, bete 'Bigarren abizena' eremua",
      "input-12-label": "Dokumentuaren euskarriko zenbakia",
      "input-12-plchdr": "NIE edo espainiar DNI-rako bakarrik",
      "input-12-error": "Karaktere kopurua ez da baliozkoa",
      "input-13-label": "Dokumentu zenbakia",
      "input-13-plchdr": "Idatzi hautatutako dokumentuaren zenbakia",
      "input-14-label": "Egoitza herrialdea",
      "input-14-plchdr": "Idatzi iradokizunak ikusteko",
      "input-15-label": "Posta kodea",
      "input-15-plchdr": "Idatzi iradokizunak ikusteko",
      "input-16-label": "Udalerria",
      "input-16-plchdr": "Idatzi iradokizunak ikusteko",
      "input-17-label": "Egoitza helbidea",
      "input-17-plchdr": "Idatzi iradokizunak ikusteko",
      "head-title-1": "Datu pertsonalak",
      "head-title-2": "Identifikazio datuak",
      "head-title-3": "Inkesta",
      "exit-title": "Irten Check-in-etik?",
      "exit-description": "Prozesutik irteten bazara, sartutako informazioa ez da gordeko",
      "exit-button": "Irten",
      "exit-continue": "Jarraitu Check-inarekin",
      "norms-title": "Politikak eta Arauak",
      "norms-subtitle": "Zigorra:",
      "why-data-continue": "Ulertuta",
      "see-policies": "Ikusi pribatutasun politika"
    },
    "autocomplete": {
      "cardbanner-title": "Formularioa betetze automatikoa",
      "cardbanner-subtitle": "Aurrez ezazu denbora zure dokumentua eskaneatuz",
      "title": "Formularioa automatikoki bete",
      "description-1": "Zure pasaportea edo DNIa eskaneatuko duzu gailuaren kamerarekin.",
      "description-2": "MRZ kodea eskaneatu behar duzu.",
      "description-3": "Hemen azpian azaltzen dizugu non aurkitu.",
      "passport-title": "Pasaportea",
      "dninie-title": "DNI/NIE",
      "scan-doc": "Eskaneatu dokumentua",
      "backtoform": "Itzuli formulariora",
      "error-scan": "Errorea: Dokumentuaren MRZ kodea enfokatu behar duzu",
      "browser-warning": "Hasi saioa Google Chrome bezalako nabigatzaile batetik funtzionalitate hau erabiltzeko.",
      "rescan-document": "Eskaneatu dokumentua berriro"
    }
  }
  
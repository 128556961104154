export default {
    "title": "Home",
    "title-welcome": `Bienvenido`,
    "btn-see-all": "ver todo",
    "hab": "Hab",
    "btn-more-info": "Información del hotel",
    "stayTitle":"Estancia",
    "guestTitle":"Huéspedes",
    "invite":"Invitar",
    "section-facility": {
      "title": "instalaciones"
    },
    "section-what-visit": {
      "title": "qué visitar"
    },
    "section-where-eat": {
      "title": "dónde comer"
    },
    "section-leisure": {
      "title": "ocio"
    },
    "section-experience": {
      "title": "experiencias"
    },
    "form-survey": {
      "title": "¿Cómo ha sido tu experiencia con nosotros?",
      "subtitle": "Nos encantaría conocer tu opinión, es muy importante para nosotros.",
      "btn-wrong": "Mal",
      "btn-normal": "Normal",
      "btn-good": "Muy bien",
      "btn-next": "Siguiente",
      "btn-submit": "Enviar",
      "message-success-title": "¡Gracias por compartir tu experiencia!",
      "message-success-text": "Valoramos mucho tu opinión y trabajaremos para ofrecerte la mejor estancia posible en el futuro."
    },
    "card-product":{
      "recommended":"Recomendado",
      "featured":"Destacado",
    },
    "inviteModal":{
      "title":"Invitar huéspedes",
      "inputLabel":"Invitar a otro huésped",
      "inputPlaceholder":"Correo electrónico del huésped",
      "button":"Invitar",
      "textToast":"Invitación enviada"
    },
    "social":{
      "title":"Nuestras redes"
    }
  };
  
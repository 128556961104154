export default {
    "gocheckinButton":"Completar Check-in",
    "cardBanner": {
        "title": "Check-in disponible",
        "subtitle": "Optimiza tu tiempo, realiza tu check-in ahora!",
    },
    "card-guest": {
        "resend-staylink": "Reenviar enlace a la estancia",
        "invite-to-completecheckin": "Invitar a completar Check-in",
        "delete-checkin": "Eliminar Check-in de huésped",
        "delete-guest": "Eliminar huésped"
    },
    "delete-guest": {
        "title": "¿Eliminar huésped de la estancia?",
        "info": "Quitarás a este huésped de tu estancia. Podrás volver a enviarle una invitación luego.",
        "confirm": "Eliminar huésped",
        "cancel": "Cancelar",
    },
    "delete-checkin": {
        "title": "¿Eliminar Check-in del huésped?",
        "info": "Eliminarás la información de Check-in de este huésped. El huésped tendrá que volver a completar su Check-in.",
        "confirm": "Eliminar Check-in",
        "cancel": "Cancelar",
    },
    "header": {
        "mystay": "Estancia",
        "guests": "Huéspedes",
    },
    "form": {
        "why-data": "¿Por qué pedimos estos datos?",
        "policies-info-1": "Al presionar el botón “Finalizar”, declaro que acepto las",
        "policies-info-2": "políticas y normas",
        "policies-info-3": "del alojamiento",
        "why-data-title": "¿Por qué pedimos estos datos?",
        "why-data-p1": "La ley local exige que los alojamientos turísticos registren a todos sus huéspedes.",
        "why-data-p2": "Por eso, necesitamos algunos datos personales básicos y obligatorios.",
        "why-data-p3": "Completar el check-in online antes de tu llegada te ahorrará tiempo, evitando tener que proporcionar esta información en el alojamiento.",
        "why-data-p4": "Tus datos se procesarán de manera segura y conforme al Reglamento General de Protección de Datos (RGPD). ",
        "why-data-p5": "Puedes obtener más detalles en nuestra política de privacidad.",
        "changes-bar-next": "Siguiente",
        "changes-bar-finish": "Finalizar",
        "changes-bar-send-data": "Enviando datos",
        "changes-bar-previus": "Paso anterior",
        "input-2-label": "Primer apellido",
        "input-3-label": "Segundo apellido",
        "input-3-alert": "Si tu tipo de documento es DNI español, completa este campo",
        "input-4-label": "Fecha de nacimiento",
        "input-4-plchdr": "DD/MM/AAAA",
        "input-5-label": "Sexo",
        "input-5-plchdr": "Selecciona una opcion",
        "input-6-label": "Teléfono",
        "input-7-label": "Correo electrónico",
        "input-8-label": "Adulto responsable",
        "input-8-plchdr": "Nombre y apellidos del adulto",
        "input-9-label": "Relación de parentesco",
        "input-9-plchdr": "Cómo se vincula el adulto con el menor",
        "input-10-label": "Nacionalidad",
        "input-10-plchdr": "Escribe para ver sugerencias",
        "input-11-label": "Tipo de documento",
        "input-11-plchdr": "Selecciona una opcion",
        "input-11-error": "Por favor, completa el campo “Segundo apellido”",
        "input-12-label": "Número de soporte del documento",
        "input-12-plchdr": "Sólo para NIE o DNI español",
        "input-12-error": "La cantidad de caracteres ingresada no es válida",
        "input-13-label": "Número de documento",
        "input-13-plchdr": "Escribe el número del documento seleccionado",
        "input-14-label": "País de residencia",
        "input-14-plchdr": "Escribe para ver sugerencias",
        "input-15-label": "Código postal",
        "input-15-plchdr": "Escribe para ver sugerencias",
        "input-16-label": "Municipio",
        "input-16-plchdr": "Escribe para ver sugerencias",
        "input-17-label": "Domicilio de residencia",
        "input-17-plchdr": "Escribe para ver sugerencias",
        "head-title-1": "Datos personales",
        "head-title-2": "Datos de identificación",
        "head-title-3": "Encuesta",
        "exit-title": "¿Salir de Check-in?",
        "exit-description": "Si sales del proceso ahora, la información que has ingresado no se guardará",
        "exit-button": "Salir",
        "exit-continue": "Continuar con el Check-in",
        "norms-title" : "Políticas y Normas",
        "norms-subtitle" : "Penalizacion:",
        "why-data-continue":"Entendido",
        "see-policies":"Ver política de privacidad"

    },
    "autocomplete": {
        "cardbanner-title": "Autocompletar formulario",
        "cardbanner-subtitle": "Ahorra tiempo escaneando tu documento",
        "title": "Autocompletar formulario",
        "description-1": "Escanearás tu pasaporte o DNI utilizando la cámara de tu dispositivo.",
        "description-2": "Debes escanear el código MRZ.",
        "description-3": "Aquí debajo te indicamos dónde encontrarlo.",
        "passport-title": "Pasaporte",
        "dninie-title": "DNI/NIE",
        "scan-doc": "Escanear documento",
        "backtoform": "Volver al formulario",
        "error-scan": "Error: Debes enfocar el código MRZ del documento",
        "browser-warning": "Inicia sesion desde un navegador como Google Chrome para usar esta funcionalidad.",
        "rescan-document": "Volver a escanear documento"
    },
}
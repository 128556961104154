export default {
    "guestLog": {
      "title": "Sarbidea webapp-era",
      "name": {
        "label": "Izen-abizenak",
        "placeholder": "Sartu zure izen-abizenak"
      },
      "email": {
        "label": "Posta elektronikoa",
        "placeholder": "Sartu zure posta elektronikoa",
        "error": "Posta elektroniko baliogabea"
      },
      "button": "Hurrengoa",
      "backButton": "Itzuli",
      "processing": "Prozesatzen"
    }
  }
  
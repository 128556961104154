export default {
    "facilitiesWord": "Instalazioak",
    "horaryWord": "Ordutegia",
    "detailPage": {
      "sectionSchedules": {
        "title": "Ordutegiak",
        "openAlways": "Irekita egunero, 24 orduz",
        "empty": "Ordutegirik ez zehaztuta. Kontsultatu txat bidez",
        "days": {
          "Lunes": "Astelehena",
          "Martes": "Asteartea",
          "Miércoles": "Asteazkena",
          "Jueves": "Osteguna",
          "Viernes": "Ostirala",
          "Sábado": "Larunbata",
          "Domingo": "Igandea"
        }
      }
    }
  }
  
export default {
    "create": {
      "title": "Sortu zure egonaldia",
      "check-date": {
        "label": "Check-in / Check-out data",
        "placeholder": "ee/hh/uuuu - ee/hh/uuuu"
      },
      "continue-button": "Sortu eta sartu egonaldian",
      "creating": "Egonaldia sortzen"
    },
    "share": {
      "title": "Batu gure egonaldiari hemen: {hotel}",
      "text": "Batu orain eta gozatu WebApp-aren abantaila guztiez zure esperientzia ahalik eta gehien aprobetxatzeko"
    },
    "detail": {
      "title": "Nire egonaldiak",
      "createBtn": "Egonaldia sortu",
      "editBtn": "Egonaldia editatu"
    },
    "edit": {
      "title": "Egonaldia editatu",
      "information-stay": "Egonaldiaren informazioa",
      "input": {
        "medio-reserva": {
          "label": "Erreserbaren bidea",
          "placeholder": "Hautatu erreserbaren bidea",
          "n-room": "Gela zk."
        }
      },
      "calendar": "Egutegia",
      "guests": "Gonbidatuak",
      "saveBtn": "Gorde",
      "share": "Egonaldia partekatu",
      "guest": "Gonbidatua",
      "completeCheckin": "Check-in osatu",
      "waitingGuest": "Gonbidatuaren sarbidea itxaroten",
      "accessRoom": "Sartu egonaldira",
      "active": "Aktiboa",
      "pageWebHotel": "Hotelaren webgunea"
    },
    "reservation": {
      "title": "Erreserbatu zure hurrengo egonaldia",
      "button-reservation": "Erreserbatu",
      "title-page": "Hautatu ostatua erreserbatzeko eta webgunera birbideratuko zaitugu."
    }
  }
  
export default {
    "account": "El meu compte",
    "greeting": "Hola, {name}!",
    "my_stays": {
        "title": "Les meves estades",
        "subtitle_active": "Gestiona les teves estades",
        "subtitle_inactive": "Crea la teva propera estada"
    },
    "personal_info": {
        "title": "Informació personal",
        "description": "Configura la teva informació personal"
    },
    "language": {
        "title": "Preferència d'idioma",
        "description": "Selecciona el teu idioma per defecte"
    },
    "logout": "Tancar sessió",
    "share_stay": "Compartir estada",
    "page_personal_info": {
        "title": "Informació personal",
        "change_photo": "Canviar foto",
        "change_password": "Canviar contrasenya",
        "btn_save": "Desar",
        "form": {
            "name": {
                "label": "Nom",
                "placeholder": "Introdueix el teu nom"
            },
            "last_name": {
                "label": "Cognoms",
                "placeholder": "Introdueix el teu cognom"
            },
            "email": {
                "label": "Correu electrònic",
                "placeholder": "Introdueix el teu correu electrònic"
            },
            "number_phone": {
                "label": "Número de telèfon",
                "placeholder": "Introdueix el teu número de telèfon"
            },
            "password": {
                "label": "Contrasenya",
                "placeholder": "Introdueix la teva contrasenya"
            }
        }
    },
    "book_next_stay": {
        "title": "Reserva la teva propera estada",
        "description": "Planifica la teva propera escapada"
    },
    "rewards": {
        "title": "Convida un amic",
        "description": "Comparteix beneficis exclusius",
        "button-recommend": "Recomana a un amic ara",
        "referrals": {
            "title": "Recomana a un amic!",
            "discount": "{amount} de descompte",
            "description": "Envia-li el teu codi de referent. Obtindrà {amount} de descompte gràcies al teu codi.",
        },
        "referent": {
            "title": "Recomana a un amic i guanyeu tots dos!",
            "titleReferred": "{amount} de descompte per al referit",
            "descerptionReferred": "Envia-li el teu codi de referent. Obtindrà {amount} de descompte gràcies al teu codi.",
            "titleReferent": "Rep {amount} de descompte",
            "descerptionReferent": "Un cop validat i utilitzat el codi del referit, aconseguiràs {amount} de descompte."
        },
        "titleReferentProfile": "Guanya diners",
        "descriptionReferentProfile": "Recomana a un amic per obtenir beneficis"
    },
    "next-reserve": {
        "reserve-button": "Reservar",
        "continue-title": "Vols continuar?",
        "continue-message": "Seràs redirigit al lloc web de l'hotel per continuar el procés de reserva.",
        "continue-yes": "Sí, continuar",
        "continue-cancel": "Cancel·lar",
    },
};

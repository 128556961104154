export default {
    "textToast": {
      "sendQueryText": "Agraïm sincerament el teu feedback. Ens importa la teva experiència i intentem complir les teves expectatives",
      "updateQueryText": "Modificació enviada",
      "registerResponse": "Feedback enviat",
      "updateResponse": "Feedback modificat"
    },
    "form": {
      "send": "Enviar",
      "sending": "Enviant",
      "modify": "Modificar",
      "cancel": "Cancel·lar",
      "title": "Feedback",
      "hello": "Hola",
      "whatsup": "Com estàs",
      "thanksAll": "Gràcies per tot",
      "btn-verywrong-stay": "Molt dolent",
      "btn-wrong-stay": "Dolent",
      "btn-normal-stay": "Normal",
      "btn-good-stay": "Bo",
      "btn-verygood-stay": "Molt bo",
      "btn-verywrong-poststay": "Molt dolenta",
      "btn-wrong-poststay": "Dolenta",
      "btn-normal-poststay": "Normal",
      "btn-good-poststay": "Bona",
      "btn-verygood-poststay": "Molt bona",
      "poststay-bad-thanks-title": "Resposta enviada",
      "poststay-bad-thanks-subtitle": "Moltes gràcies pel teu temps",
      "continueIn": "Continuar a"
    },
    "settings": {
      "questionpre-stay": "Hi ha alguna cosa que puguem fer per a la teva arribada al {lodging}?",
      "questionin-stay": "Com valoraries el teu nivell de satisfacció amb l’estada fins ara?",
      "questionpost-stay": "Com ha estat la teva experiència amb nosaltres?"
    },
    "inbox": {
      "title": "Missatges"
    }
  }
  
export default {
    "mobileTitle": "Informació de l'hotel",
    "info": "Informació de contacte",
    "horary": "Horari de Check in / Check out",
    "checkin": "Arribada a partir de les",
    "checkout": "Sortida fins a les",
    "social": "Troba'ns a...",
    "msgCopyPhone": "Telèfon copiat amb èxit",
    "msgCopyEmail": "Correu electrònic copiat amb èxit",
    "msgCopyAddress": "Adreça copiada amb èxit"
}

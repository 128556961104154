export default {
  "title": "Startseite",
  "title-welcome": "Willkommen",
  "btn-see-all": "Alle anzeigen",
  "hab": "Zimmer",
  "btn-more-info": "Hotelinformationen",
  "stayTitle": "Aufenthalt",
  "guestTitle": "Gäste",
  "invite": "Einladen",
  "section-facility": {
    "title": "Einrichtungen"
  },
  "section-what-visit": {
    "title": "Was zu besuchen"
  },
  "section-where-eat": {
    "title": "Wo essen"
  },
  "section-leisure": {
    "title": "Freizeit"
  },
  "section-experience": {
        "title": "Erfahrungen"
  },
  "card-product": {
    "featured": "Hervorgehoben",
    "recommended": "Empfohlen"
  },
  "form-survey": {
    "title": "Wie war Ihre Erfahrung mit uns?",
    "subtitle": "Wir würden gerne Ihre Meinung wissen, sie ist uns sehr wichtig.",
    "btn-wrong": "Falsch",
    "btn-normal": "Normal",
    "btn-good": "Sehr gut",
    "btn-next": "Weiter",
    "btn-submit": "Absenden",
    "message-success-title": "Danke, dass Sie Ihre Erfahrungen teilen!",
    "message-success-text": "Wir schätzen Ihr Feedback sehr und werden daran arbeiten, Ihnen in Zukunft den bestmöglichen Aufenthalt zu bieten."
  },
  "card-product": {
    "recommended": "Empfohlen",
    "featured": "Hervorgehoben",
  },
  "inviteModal": {
    "title": "Gäste einladen",
    "inputLabel": "Einen weiteren Gast einladen",
    "inputPlaceholder": "E-Mail des Gastes",
    "button": "Einladen",
    "textToast": "Einladung gesendet",
  },
  "social": {
    "title": "Unsere Netzwerke"
  }
};

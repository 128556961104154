export default {
    "create": {
      "title": "Crea la teva estada",
      "check-date": {
        "label": "Data Check-in/Check-out",
        "placeholder": "dd/mm/aaaa - dd/mm/aaaa"
      },
      "continue-button": "Crear i accedir a l'estada",
      "creating": "Creant estada"
    },
    "share": {
      "title": "Uneix-te a la nostra estada a {hotel}",
      "text": "Uneix-te ara i gaudeix de tots els avantatges de la WebApp per aprofitar al màxim la nostra experiència"
    },
    "detail": {
      "title": "Les meves estades",
      "createBtn": "Crear estada",
      "editBtn": "Editar estada"
    },
    "edit": {
      "title": "Editar estada",
      "information-stay": "Informació de l'estada",
      "input": {
        "medio-reserva": {
          "label": "Mitjà de la reserva",
          "placeholder": "Selecciona el mitjà de la reserva",
          "n-room": "Nº habitació"
        }
      },
      "calendar": "Calendari",
      "guests": "Hostes",
      "saveBtn": "Desar",
      "share": "Compartir estada",
      "guest": "Hoste",
      "completeCheckin": "Completar check-in",
      "waitingGuest": "Esperant accés de l'hoste",
      "accessRoom": "Accedir a l'estada",
      "active": "Activa",
      "pageWebHotel": "Pàgina web de l'hotel"
    },
    "reservation": {
      "title": "Reserva la teva propera estada",
      "button-reservation": "Reservar",
      "title-page": "Selecciona un allotjament per reservar i et redirigirem a la seva pàgina web."
    }
  }
  
export default {
    "account": "Mein Konto",
    "greeting": "Hallo, {name}!",
    "my_stays": {
        "title": "Meine Aufenthalte",
        "subtitle_active": "Verwalten Sie Ihre Aufenthalte",
        "subtitle_inactive": "Erstellen Sie Ihren nächsten Aufenthalt"
    },
    "personal_info": {
        "title": "Persönliche Informationen",
        "description": "Richten Sie Ihre persönlichen Informationen ein"
    },
    "language" : {
        "title": "Sprache",
        "description": "Wählen Sie Ihre Standardsprache aus"
    },
    "logout": "Abmelden",
    "share_stay": "Aufenthalt Teilen",
    "page_personal_info": {
        "title": "Persönliche Informationen",
        "change_photo": "Foto ändern",
        "change_password": "Passwort ändern",
        "btn_save": "Speichern",
        "form": {
            "name": {
                "label": "Vorname",
                "placeholder": "Geben Sie Ihren Vornamen ein"
            },
            "last_name": {
                "label": "Nachname",
                "placeholder": "Geben Sie Ihren Nachnamen ein"
            },
            "email": {
                "label": "E-Mail",
                "placeholder": "Geben Sie Ihre E-Mail ein"
            },
            "number_phone": {
                "label": "Telefonnummer",
                "placeholder": "Geben Sie Ihre Telefonnummer ein"
            },
            "password": {
                "label": "Passwort",
                "placeholder": "Geben Sie Ihr Passwort ein"
            }
        }
    },
    "book_next_stay": {
        "title": "Buche deinen nächsten Aufenthalt",
        "description": "Plane deinen nächsten Kurzurlaub"
    },
    "rewards": {
        "title": "Einen Freund einladen",
        "description": "Teilen Sie exklusive Vorteile",
        "button-recommend": "Empfehlen Sie jetzt einen Freund",
        "referrals": {
            "title": "Empfehlen Sie einen Freund!",
            "discount": "{amount} Rabatt",
            "description": "Senden Sie Ihren Empfehlungscode. Er erhält {amount} Rabatt dank Ihres Codes."
        },
        "referent": {
            "title": "Empfehlen Sie einen Freund und gewinnen Sie beide!",
            "titleReferred": "{amount} Rabatt für den Geworbenen",
            "descerptionReferred": "Senden Sie Ihren Empfehlungscode. Er erhält {amount} Rabatt dank Ihres Codes.",
            "titleReferent": "Erhalten Sie {amount} Rabatt",
            "descerptionReferent": "Sobald der Code validiert und verwendet wurde, erhalten Sie {amount} Rabatt."
        },
        "titleReferentProfile": "Geld verdienen",
        "descriptionReferentProfile": "Empfehlen Sie einen Freund, um Vorteile zu erhalten"
    },
    "next-reserve": {
        "reserve-button": "Reservieren",
        "continue-title": "Möchten Sie fortfahren?",
        "continue-message": "Sie werden zur Hotelwebseite weitergeleitet, um Ihren Buchungsprozess fortzusetzen.",
        "continue-yes": "Ja, fortfahren",
        "continue-cancel": "Abbrechen"
    }



};

export default {
    "mobileTitle": "Hotelaren informazioa",
    "info": "Kontakturako informazioa",
    "horary": "Check in / Check out ordutegia",
    "checkin": "Iritsiera hemendik aurrera",
    "checkout": "Irteera hemendik aurrera",
    "social": "Aurki gaitzazu hemen...",
    "msgCopyPhone": "Telefonoa arrakastaz kopiatu da",
    "msgCopyEmail": "Posta elektronikoa arrakastaz kopiatu da",
    "msgCopyAddress": "Helbidea arrakastaz kopiatu da"
}

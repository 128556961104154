export default {
    "gocheckinButton": "Completar Check-in",
    "cardBanner": {
        "title": "Check-in disponible",
        "subtitle": "Optimitza el teu temps, fes el check-in ara!"
    },
    "card-guest": {
        "resend-staylink": "Reenviar enllaç a l'estada",
        "invite-to-completecheckin": "Convidar a completar el Check-in",
        "delete-checkin": "Eliminar Check-in de l'hoste",
        "delete-guest": "Eliminar hoste"
    },
    "delete-guest": {
        "title": "Vols eliminar l'hoste de l'estada?",
        "info": "Treure aquest hoste de la teva estada. Podràs tornar a enviar-li una invitació més endavant.",
        "confirm": "Eliminar hoste",
        "cancel": "Cancel·lar"
    },
    "delete-checkin": {
        "title": "Vols eliminar el Check-in de l'hoste?",
        "info": "Eliminaràs la informació del Check-in d'aquest hoste. Haurà de completar-lo de nou.",
        "confirm": "Eliminar Check-in",
        "cancel": "Cancel·lar"
    },
    "header": {
        "mystay": "Estada",
        "guests": "Hostes"
    },
    "form": {
        "why-data": "Per què demanem aquestes dades?",
        "policies-info-1": "En prémer el botó \"Finalitzar\", declaro que accepto les",
        "policies-info-2": "polítiques i normes",
        "policies-info-3": "de l'allotjament",
        "why-data-title": "Per què demanem aquestes dades?",
        "why-data-p1": "La llei local exigeix que els allotjaments turístics registrin tots els seus hostes.",
        "why-data-p2": "Per això, necessitem algunes dades personals bàsiques i obligatòries.",
        "why-data-p3": "Completar el check-in en línia abans de la teva arribada t'estalviarà temps.",
        "why-data-p4": "Les teves dades es tractaran de forma segura i segons el Reglament General de Protecció de Dades (RGPD).",
        "why-data-p5": "Pots obtenir més informació a la nostra política de privacitat.",
        "changes-bar-next": "Següent",
        "changes-bar-finish": "Finalitzar",
        "changes-bar-send-data": "Enviant dades",
        "changes-bar-previus": "Pas anterior",
        "input-2-label": "Primer cognom",
        "input-3-label": "Segon cognom",
        "input-3-alert": "Si el teu document és DNI espanyol, omple aquest camp",
        "input-4-label": "Data de naixement",
        "input-4-plchdr": "DD/MM/AAAA",
        "input-5-label": "Sexe",
        "input-5-plchdr": "Selecciona una opció",
        "input-6-label": "Telèfon",
        "input-7-label": "Correu electrònic",
        "input-8-label": "Adult responsable",
        "input-8-plchdr": "Nom i cognoms de l'adult",
        "input-9-label": "Relació de parentiu",
        "input-9-plchdr": "Com es relaciona l'adult amb el menor",
        "input-10-label": "Nacionalitat",
        "input-10-plchdr": "Escriu per veure suggeriments",
        "input-11-label": "Tipus de document",
        "input-11-plchdr": "Selecciona una opció",
        "input-11-error": "Si us plau, completa el camp 'Segon cognom'",
        "input-12-label": "Número de suport del document",
        "input-12-plchdr": "Només per a NIE o DNI espanyol",
        "input-12-error": "La quantitat de caràcters introduïda no és vàlida",
        "input-13-label": "Número de document",
        "input-13-plchdr": "Escriu el número del document seleccionat",
        "input-14-label": "País de residència",
        "input-14-plchdr": "Escriu per veure suggeriments",
        "input-15-label": "Codi postal",
        "input-15-plchdr": "Escriu per veure suggeriments",
        "input-16-label": "Municipi",
        "input-16-plchdr": "Escriu per veure suggeriments",
        "input-17-label": "Domicili de residència",
        "input-17-plchdr": "Escriu per veure suggeriments",
        "head-title-1": "Dades personals",
        "head-title-2": "Dades d'identificació",
        "head-title-3": "Qüestionari",
        "exit-title": "Sortir del Check-in?",
        "exit-description": "Si surts ara, les dades introduïdes no es guardaran",
        "exit-button": "Sortir",
        "exit-continue": "Continuar amb el Check-in",
        "norms-title": "Polítiques i Normes",
        "norms-subtitle": "Penalització:",
        "why-data-continue": "Entès",
        "see-policies": "Veure política de privacitat"
    },
    "autocomplete": {
        "cardbanner-title": "Autocompletar formulari",
        "cardbanner-subtitle": "Estalvia temps escanejant el teu document",
        "title": "Autocompletar formulari",
        "description-1": "Escanejaràs el teu passaport o DNI amb la càmera del dispositiu.",
        "description-2": "Has d'escanejar el codi MRZ.",
        "description-3": "T'indiquem on trobar-lo a continuació.",
        "passport-title": "Passaport",
        "dninie-title": "DNI/NIE",
        "scan-doc": "Escanejar document",
        "backtoform": "Tornar al formulari",
        "error-scan": "Error: Has d'enfocar el codi MRZ del document",
        "browser-warning": "Inicia sessió des d'un navegador com Google Chrome per utilitzar aquesta funcionalitat.",
        "rescan-document": "Tornar a escanejar document"
    }
}
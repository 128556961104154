export default {
    "create": {
      "title": "Maak je verblijf aan",
      "check-date": {
        "label": "Check-in / Check-out datum",
        "placeholder": "dd/mm/jjjj - dd/mm/jjjj"
      },
      "continue-button": "Creëren en toegang tot verblijf",
      "creating": "Verblijf aan het aanmaken"
    },
    "share": {
      "title": "Doe mee met ons verblijf in {hotel}",
      "text": "Doe nu mee en geniet van alle voordelen van de WebApp om het meeste uit onze ervaring te halen"
    },
    "detail": {
      "title": "Mijn verblijven",
      "createBtn": "Verblijf aanmaken",
      "editBtn": "Verblijf bewerken"
    },
    "edit": {
      "title": "Verblijf bewerken",
      "information-stay": "Informatie van het verblijf",
      "input": {
        "medio-reserva": {
          "label": "Boekingsmethode",
          "placeholder": "Selecteer de boekingsmethode",
          "n-room": "Kamernummer"
        }
      },
      "calendar": "Kalender",
      "guests": "Gasten",
      "saveBtn": "Opslaan",
      "share": "Verblijf delen",
      "guest": "Gast",
      "completeCheckin": "Check-in voltooien",
      "waitingGuest": "Wacht op toegang van de gast",
      "accessRoom": "Toegang tot verblijf",
      "active": "Actief",
      "pageWebHotel": "Website van het hotel"
    },
    "reservation": {
      "title": "Boek je volgende verblijf",
      "button-reservation": "Boeken",
      "title-page": "Selecteer een accommodatie om te boeken en we leiden je door naar hun website."
    }
  }
  
export default {
    "gocheckinButton": "Complete Check-in",
    "cardBanner": {
        "title": "Check-in available",
        "subtitle": "Optimize your time, complete your check-in now!"
    },
    "card-guest": {
        "resend-staylink": "Resend stay link",
        "invite-to-completecheckin": "Invite to complete Check-in",
        "delete-checkin": "Delete guest's Check-in",
        "delete-guest": "Delete guest"
    },
    "delete-guest": {
        "title": "Remove guest from stay?",
        "info": "You will remove this guest from your stay. You can resend an invitation later.",
        "confirm": "Remove guest",
        "cancel": "Cancel"
    },
    "delete-checkin": {
        "title": "Delete guest's Check-in?",
        "info": "You will delete this guest's Check-in information. The guest will have to complete their Check-in again.",
        "confirm": "Delete Check-in",
        "cancel": "Cancel"
    },
    "header": {
        "mystay": "Stay",
        "guests": "Guests"
    },
    "form": {
        "why-data": "Why do we ask for this information?",
        "policies-info-1": "By pressing the 'Finish' button, I declare that I accept the",
        "policies-info-2": "policies and rules",
        "policies-info-3": "of the accommodation",
        "why-data-title": "Why do we ask for this information?",
        "why-data-p1": "Local law requires that tourist accommodations register all their guests.",
        "why-data-p2": "Therefore, we need some basic and mandatory personal information.",
        "why-data-p3": "Completing the online check-in before your arrival will save you time, avoiding the need to provide this information at the accommodation.",
        "why-data-p4": "Your data will be processed securely and in accordance with the General Data Protection Regulation (GDPR).",
        "why-data-p5": "You can get more details in our privacy policy.",
        "changes-bar-next": "Next",
        "changes-bar-finish": "Finish",
        "changes-bar-send-data": "Sending data",
        "changes-bar-previus": "Previous step",
        "input-2-label": "First surname",
        "input-3-label": "Second surname",
        "input-3-alert": "If your document type is Spanish DNI, fill out this field",
        "input-4-label": "Date of birth",
        "input-4-plchdr": "DD/MM/YYYY",
        "input-5-label": "Gender",
        "input-5-plchdr": "Select an option",
        "input-6-label": "Phone",
        "input-7-label": "Email",
        "input-8-label": "Responsible adult",
        "input-8-plchdr": "Full name of the adult",
        "input-9-label": "Relationship",
        "input-9-plchdr": "How the adult is related to the minor",
        "input-10-label": "Nationality",
        "input-10-plchdr": "Type to see suggestions",
        "input-11-label": "Document type",
        "input-11-plchdr": "Select an option",
        "input-11-error": "Please fill out the 'Second surname' field",
        "input-12-label": "Document support number",
        "input-12-plchdr": "Only for Spanish NIE or DNI",
        "input-12-error": "The number of characters entered is not valid",
        "input-13-label": "Document number",
        "input-13-plchdr": "Enter the selected document number",
        "input-14-label": "Country of residence",
        "input-14-plchdr": "Type to see suggestions",
        "input-15-label": "Postal code",
        "input-15-plchdr": "Type to see suggestions",
        "input-16-label": "City",
        "input-16-plchdr": "Type to see suggestions",
        "input-17-label": "Residential address",
        "input-17-plchdr": "Type to see suggestions",
        "head-title-1": "Personal information",
        "head-title-2": "Identification details",
        "head-title-3": "Survey",
        "exit-title": "Exit Check-in?",
        "exit-description": "If you exit now, the information you entered will not be saved",
        "exit-button": "Exit",
        "exit-continue": "Continue with Check-in",
        "norms-title": "Policies and Rules",
        "norms-subtitle": "Penalty:",
        "why-data-continue": "Understood",
        "see-policies": "See privacy policy"
    },
    "autocomplete": {
      "cardbanner-title": "Autocomplete form",
      "cardbanner-subtitle": "Save time by scanning your document",
      "title": "Autocomplete form",
      "description-1": "You will scan your passport or ID using your device's camera.",
      "description-2": "You must scan the MRZ code.",
      "description-3": "Below we show you where to find it.",
      "passport-title": "Passport",
      "dninie-title": "ID card",
      "scan-doc": "Scan document",
      "backtoform": "Back to form",
      "error-scan": "Error: You must focus the MRZ code of the document",
    "browser-warning": "Sign in using a browser such as Google Chrome to use this functionality.",
    "rescan-document": "Rescan document"
    }
}
  
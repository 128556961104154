export default {
    "apiError401": "Credenciais incorrectas",
    "apiError404": "Servizo non dispoñible",
    "apiError405_406": "Solicitude non válida",
    "apiError500": "Erro de conexión",
    "apiErrorUndefined": "Erro xeral da aplicación",
    "invalidKey": "Clave non válida",
    "recordSuccess": "Rexistro completado",
    "emailSuccess": "Correo enviado",
    "passwordUpdated": "Contrasinal actualizado",
    "copiedClipboard": "Copiado ao portapapeis",
    "guestDeleted": "Hóspede eliminado",
    "urlClipboard": "Ligazón copiada",
    "changeSave": "Cambios gardados",
    "dataSave": "Datos gardados con éxito"
  };
  
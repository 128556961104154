export default{
    "log-or-register": {
        "title": "Accedi o Registrati",
        "welcome-title": "Benvenuto!",
        "welcome-msg": "Goditi la nostra WebApp e gestisci i tuoi soggiorni in uno qualsiasi dei nostri hotel.",
        "input": "Inserisci il tuo indirizzo email",
        "text-error": "Il formato dell'email inserito non è corretto",
        "continue-button": "Continua",
        "continue-google": "Continua con Google",
        "continue-fb": "Continua con Facebook"
    },
    "log": {
        "title": "Accedi",
        "input-password": "Inserisci una password",
        "password-text-error": "La password inserita è incorretta",
        "continue-button": "Continua",
        "forgot-question": "Hai dimenticato la password?",
        "input-show-pass": "Mostra",
        "input-hide-pass": "Nascondi",
         "entering": "Accesso in corso"
    },
    "register": {
        "title": "Completa la Registrazione",
        "input-name": "Inserisci il tuo nome",
        "input-email": "Inserisci il tuo indirizzo email",
        "input-email-error": "Il formato dell'email inserito non è corretto",
        "input-password": "Inserisci una password",
        "continue-button": "Accetta e Continua",
        "text-info-1": "ti invierà comunicazioni commerciali e promozioni personalizzate tramite email, SMS o altri mezzi elettronici riguardanti prodotti e servizi",
        "text-info-2": "Non desidero ricevere messaggi promozionali.",
        "policies-text": "Selezionando Accetta e Continua, accetto la",
        "policies-title": "Politica sulla Privacy.",
        "the-chain": "La catena",
        "password-info": "La password deve contenere almeno 8 caratteri.",
        "complete-register" : "Registrazione in corso"
    },
    "reset-pass":{
        "title": "Cambia password",
        "input-placeholder": "Inserisci la tua nuova password",
        "input-input-error": "La password inserita è incorretta",
        "continue-button": "Cambia password",
        "greeting": "Ciao",
        "title-reset": "Reimposta password",
        "msg": "Hai richiesto un link per reimpostare la password del tuo account sulla nostra WebApp. Clicca sul seguente pulsante per iniziare il processo.",
        "link": "Reimposta password",
    },
    "hotel-list":{
        "title": "Seleziona la struttura per creare il tuo soggiorno nella nostra WebApp",
        "amount-text": "alloggi della catena",
        "stars-text": "stelle",
        "star-text": "stella",
    },
    "my-stay": {
        "title": "Il mio soggiorno"
    }        
    
}

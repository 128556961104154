export default {
    "breadcrumbs": {
      "experiences": "Experiències"
    },
    "card-experience": {
      "hour": "hores",
      "aprox": "aproximadament",
      "min-aprox": "minuts",
      "and": "i",
      "recommed": "recomanat",
      "prominent": "destacat",
      "annulation-gratuite": "cancel·lació gratuïta",
      "non-refundable": "no reemborsable",
      "from": "Des de",
      "reviews": "ressenyes"
    },
    "list-page": {
      "title": "Experiències",
      "section-filter": {
        "label-search": "Filtrar per",
        "placeholder-input-search": "Introdueix una paraula clau",
        "label-price": "Preu",
        "label-price-min": "Preu mín.",
        "placeholder-price-min": "Mínim..",
        "label-price-max": "Preu màx.",
        "placeholder-price-max": "Màxim..",
        "label-duration": "Durada",
        "cancellation-policy": {
          "title": "Política de cancel·lació",
          "free-cancellation": "Cancel·lació gratuïta"
        },
        "duration": {
          "title": "Durada",
          "one-hour": "Fins a 1 hora",
          "one-four-hour": "Entre 1 i 4 hores",
          "noon": "Mig dia",
          "whole-day": "Tot el dia"
        },
        "score": {
          "title": "Puntuació",
          "star": "Estrelles"
        },
        "price": {
          "title": "Preu",
          "0-100": "Entre 0 € i 100 €",
          "100-250": "Entre 100 € i 250 €",
          "250-500": "Entre 250 € i 500 €",
          "500": "+500 €"
        }
      },
      "section-filter-history": {
        "btn-price": "Fins a {price_max}",
        "btn-close": "Treure filtre"
      },
      "btn-more-filter": "Més filtres",
      "btn-submit-filter": "Aplicar filtres",
      "text-count-list": "Experiències trobades",
      "text-count-list-mobile": "S'han trobat {count} experiències.",
      "placeholder-search": "Cerca una experiència",
      "text-count-list-search-active": "Cercant totes les Experiències per",
      "text-count-list-search": "{count} Experiències trobades per",
      "text-search-not-found": "Ajusta la teva cerca o prova d'eliminar filtres.",
      "without-results": {
        "title": "No s'han trobat experiències",
        "text": "No s'han trobat experiències en aquesta ciutat. Et convidem a explorar altres ciutats per descobrir-ne de noves."
      },
      "btn-load-more": "Carregar més"
    },
    "detail-page": {
      "tag-recommend": "Recomanat",
      "tag-featured": "Destacat",
      "text-variand-price": "El preu varia segons la mida del grup",
      "tag-ticket-mobile": "Entrada mòbil",
      "title-recomendation": "Recomanació",
      "title-description": "Descripció",
      "title-description-mobile": "Descripció general",
      "tooltip-language": "{language} i {numbers} més",
      "open-collapse-description": "Veure més",
      "close-collapse-description": "Veure menys",
      "title-include": "Què inclou",
      "title-location": "Ubicació",
      "btn-eye-map": "Veure a Google Maps",
      "subtitle-point-start": "Punt de trobada",
      "subtitle-point-end": "Punt final",
      "ends-point-at-meeting-point": "Aquesta activitat acaba al punt de trobada inicial",
      "title-other-information": "Informació addicional",
      "title-politic-cancelation": "Política de cancel·lació",
      "text-politic-standar-1": "Pots cancel·lar fins a 24 hores abans per obtenir el reemborsament complet.",
      "text-politic-standar-2": "Per obtenir el reemborsament complet, has de cancel·lar com a mínim 24 hores abans de l'inici.",
      "text-politic-standar-3": "Qualsevol canvi dins de les 24 hores prèvies no serà acceptat.",
      "text-politic-standar-4": "Si cancel·les dins de les 24 hores prèvies, no es reemborsarà l'import abonat.",
      "text-politic-standar-5": "Els horaris límit es basen en l'hora local de l'activitat.",
      "text-politic-notrembolsable": "Aquesta experiència no és reemborsable ni modificable. Si cancel·les o demanes una modificació, no es farà cap reemborsament.",
      "opinions-word": "opinions",
      "variant-price": "El preu varia segons la mida del grup",
      "when-experience-label": "Quan vols gaudir d'aquesta experiència?",
      "maximum-reserves-label": "Pots reservar un màxim de {maxSeats} places",
      "availability-label": "Veure disponibilitat",
      "free-cancellation-label": "Cancel·lació gratuïta",
      "non-refundable-cancellation-label": "No reemborsable",
      "check-the-availability-of-this-experience": "Consulta la disponibilitat d'aquesta experiència",
      "btn-see-availability-on-viator": "Veure disponibilitat a Viator",
      "title-general-information": "Informació general",
      "tag-paper-ticket": "Entrada en paper",
      "offered-in": "Ofert en"
    }
  }
  
export default {
  "title": "Hasiera",
  "title-welcome": "Ongi etorri",
  "btn-see-all": "ikusi dena",
  "hab": "Gela",
  "btn-more-info": "Hotelaren informazioa",
  "stayTitle": "Egonaldia",
  "guestTitle": "Gonbidatuak",
  "invite": "Gonbidatu",
  "section-facility": {
    "title": "instalazioak"
  },
  "section-what-visit": {
    "title": "zer bisitatu"
  },
  "section-where-eat": {
    "title": "non jan"
  },
  "section-leisure": {
    "title": "aisialdia"
  },
  "section-experience": {
    "title": "esperientziak"
  },
  "form-survey": {
    "title": "Zein izan da zure esperientzia gurekin?",
    "subtitle": "Zure iritzia jakitea gustatuko litzaiguke, oso garrantzitsua da guretzat.",
    "btn-wrong": "Txarra",
    "btn-normal": "Normala",
    "btn-good": "Oso ondo",
    "btn-next": "Hurrengoa",
    "btn-submit": "Bidali",
    "message-success-title": "Eskerrik asko zure esperientzia partekatzeagatik!",
    "message-success-text": "Zure iritzia asko baloratzen dugu eta etorkizunean egonaldi hobea eskaintzeko lan egingo dugu."
  },
  "card-product": {
    "recommended": "Gomendatua",
    "featured": "Nabarmendua"
  },
  "inviteModal": {
    "title": "Gonbidatu gonbidatuak",
    "inputLabel": "Beste gonbidatu bat gonbidatu",
    "inputPlaceholder": "Gonbidatuaren posta elektronikoa",
    "button": "Gonbidatu",
    "textToast": "Gonbidapena bidalia"
  },
  "social": {
    "title": "Gure sareak"
  }
};

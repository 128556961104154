export default {
  "title": "Servizos",
  "placeholder-search": "Buscar servizo",
  "modal-request-service": {
    "button": "Solicitar servizo",
    "title": "Estás a piques de ser redirixido a un sitio web externo",
    "text": "Estás a piques de ser redirixido á web do provedor do servizo. Queres continuar?",
    "button-go": "Ir ao sitio"
  },
  "card-item": {
    "free": "De balde"
  },
  "confort": {
    "title": "Confort",
    "text-search-found": "Servizos atopados en confort {number}",
    "list-page": {},
    "detail-page": {}
  },
  "transport": {
    "title": "Transporte",
    "text-search-found": "Servizos atopados en transporte {number}",
    "list-page": {},
    "detail-page": {}
  },
  "activity": {
    "title": "Actividades",
    "text-search-found": "Servizos atopados en actividades",
    "list-page": {},
    "detail-page": {}
  }
}

export default {
    "create": {
      "title": "Crea a túa estancia",
      "check-date": {
        "label": "Data de Check-in / Check-out",
        "placeholder": "dd/mm/aaaa - dd/mm/aaaa"
      },
      "continue-button": "Crear e acceder á estancia",
      "creating": "Creando estancia"
    },
    "share": {
      "title": "Únete á nosa estancia en {hotel}",
      "text": "Únete agora e goza de todas as vantaxes da WebApp para aproveitar ao máximo a nosa experiencia"
    },
    "detail": {
      "title": "As miñas estancias",
      "createBtn": "Crear estancia",
      "editBtn": "Editar estancia"
    },
    "edit": {
      "title": "Editar estancia",
      "information-stay": "Información da estancia",
      "input": {
        "medio-reserva": {
          "label": "Medio da reserva",
          "placeholder": "Seleccionar medio da reserva",
          "n-room": "Nº habitación"
        }
      },
      "calendar": "Calendario",
      "guests": "Hóspedes",
      "saveBtn": "Gardar",
      "share": "Compartir estancia",
      "guest": "Hóspede",
      "completeCheckin": "Completar check-in",
      "waitingGuest": "Agardando acceso do hóspede",
      "accessRoom": "Acceder á estancia",
      "active": "Activa",
      "pageWebHotel": "Páxina web do hotel"
    },
    "reservation": {
      "title": "Reserva a túa próxima estancia",
      "button-reservation": "Reservar",
      "title-page": "Selecciona un aloxamento para reservar e redirixirémoste á súa páxina web."
    }
  }
  
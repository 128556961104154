export default {
    "cmn": "mandarina",
    "es": "gaztelania",
    "en": "ingelesa",
    "hi": "hindia",
    "ar": "arabiera",
    "pt": "portugesa",
    "bn": "bengalera",
    "ru": "errusiera",
    "ja": "japoniera",
    "pa": "punjabera",
    "de": "alemana",
    "jv": "javera",
    "wuu": "wu txinera",
    "ms": "malaysiera",
    "te": "telugua",
    "vi": "vietnamera",
    "ko": "koreera",
    "fr": "frantsesa",
    "mr": "marathera",
    "ta": "tamilera",
    "ur": "urdua",
    "tr": "turkiera",
    "it": "italiera",
    "yue": "kantonera",
    "th": "thailandiera",
    "gu": "gujaratera",
    "mnp": "min bei txinera",
    "fa": "persiera",
    "pl": "poloniera",
    "ps": "pastunera",
    "hsn": "hunanera",
    "ml": "malayalamera",
    "my": "birmaniera",
    "hak": "hakka txinera",
    "uk": "ukrainera",
    "fil": "filipinera",
    "ro": "errumaniera",
    "nl": "nederlandera",
    "ca": "katalana",
    "gl": "galego",
    "eu": "euskara",
    "ku": "kurduera",
    "hr": "kroaziera",
    "hu": "hungariera",
    "el": "greziera",
    "cs": "txekiera",
    "sv": "suediera",
    "fi": "finlandiera",
    "sk": "eslovakiera",
    "sr": "serbiera",
    "no": "norvegiera",
    "he": "hebreera",
    "da": "daniera",
    "zh_CN": "txinera sinplifikatua",
    "title": "Hizkuntza-hobespena",
    "ca": "katalana",
    "eu": "euskara",
    "gl": "galiziera",
    "nl": "holandera"

  }
  
export default {
  "title": "Services",
  "placeholder-search": "service de recherche",
  "modal-request-service": {
    "button": "Demander un service",
    "title": "Vous êtes sur le point d'être redirigé vers un site web externe",
    "text": "Vous allez être redirigé vers le site web du prestataire de service. Voulez-vous continuer ?",
    "button-go": "Aller au site"
  },
  "card-item": {
    "free": "Gratuit"
  },
  "confort": {
    "title": "Confort",
    "text-search-found": "Services trouvés dans le confort {number}",
    "list-page": {},
    "detail-page": {}
  },
  "transport": {
    "title": "Transport",
    "text-search-found": "Services trouvés dans le transport {number}",
    "list-page": {},
    "detail-page": {}
  },
  "activity": {
    "title": "Activités",
    "text-search-found": "Services trouvés dans activités",
    "list-page": {},
    "detail-page": {}
  }
}
export default {
  "title": "Startpagina",
  "title-welcome": "Welkom",
  "btn-see-all": "alles bekijken",
  "hab": "Kmr",
  "btn-more-info": "Hotelinformatie",
  "stayTitle": "Verblijf",
  "guestTitle": "Gasten",
  "invite": "Uitnodigen",
  "section-facility": {
    "title": "voorzieningen"
  },
  "section-what-visit": {
    "title": "wat te bezoeken"
  },
  "section-where-eat": {
    "title": "waar te eten"
  },
  "section-leisure": {
    "title": "vrije tijd"
  },
  "section-experience": {
    "title": "ervaringen"
  },
  "form-survey": {
    "title": "Hoe was je ervaring met ons?",
    "subtitle": "We horen graag je mening, deze is erg belangrijk voor ons.",
    "btn-wrong": "Slecht",
    "btn-normal": "Normaal",
    "btn-good": "Zeer goed",
    "btn-next": "Volgende",
    "btn-submit": "Verzenden",
    "message-success-title": "Bedankt voor het delen van je ervaring!",
    "message-success-text": "We waarderen je mening en streven ernaar om je in de toekomst het beste verblijf te bieden."
  },
  "card-product": {
    "recommended": "Aanbevolen",
    "featured": "Uitgelicht"
  },
  "inviteModal": {
    "title": "Gasten uitnodigen",
    "inputLabel": "Nog een gast uitnodigen",
    "inputPlaceholder": "E-mailadres van de gast",
    "button": "Uitnodigen",
    "textToast": "Uitnodiging verzonden"
  },
  "social": {
    "title": "Onze netwerken"
  }
};

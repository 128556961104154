export default {
    "breadcrumbs": {
      "explore": "Explora"
    },
    "list-page": {
      "title": "Explora a",
      "text-count-list": "{count} llocs trobats",
      "text-count-list-search": "{count} llocs trobats per a",
      "text-count-list-search-active": "Cerca tots els llocs per a",
      "text-search-not-found": "Ajusta la teva cerca o prova amb una altra categoria",
      "btn-load-more": "Carregar més"
    },
    "results": "Sense resultats...",
    "seaching": "Cercant...",
    "categorySearch": "trobats",
    "city": "Ciutat",
    "placeholder-search": "Cercar destinació",
    "types": {
      "que-visitar": "Què visitar",
      "donde-comer": "On menjar",
      "ocio": "Oci"
    },
    "categories": {
      "monumentos": "Monuments",
      "museos": "Museus",
      "zonas-verdes": "Zones verdes",
      "cafeterias-y-postres": "Cafeteries i postres",
      "restaurantes": "Restaurants",
      "vida-nocturna": "Vida nocturna",
      "copas": "Copes",
      "compras": "Compres",
      "otros": "Altres"
    },
    "detail": {
      "titleList": "Explora",
      "opinionsTitle": "Opinions de la info. turística",
      "opinionsWord": "Ressenyes",
      "recommendationWord": "Recomanació",
      "featured": "Destacat",
      "callWord": "Trucar",
      "dataInterest": {
        "title": "Dades d'interès",
        "priceRange": "Rangs de preus",
        "diet": "Dietes especials",
        "others": "Altres"
      },
      "specialRegimes": "Règims especials",
      "additionalInformation": "Informació addicional",
      "copy": "Copiar",
      "showLess": "Mostrar menys",
      "showMore": "Mostrar més",
      "recommended": "Recomanat",
      "recommendedTitle": "Recomanació del {lodging}",
      "buttoms": {
        "web": "Web",
        "call": "Trucar",
        "menu": "Menú",
        "email": "E-mail"
      },
      "hours": {
        "title": "Horaris",
        "monday": "dilluns",
        "tuesday": "dimarts",
        "wednesday": "dimecres",
        "thursday": "dijous",
        "friday": "divendres",
        "saturday": "dissabte",
        "sunday": "diumenge"
      },
      "locationWord": "Ubicació",
      "starWord": "Estrella",
      "starsWord": "Estrelles",
      "ratingTitle": "Puntuació",
      "noComment": "No s'ha escrit cap comentari",
      "seeMoreComment": "Veure més",
      "seeAllComments": "Veure totes les opinions",
      "titleAllReviews": "Opinions de la info. turística",
      "exploreIn": "Explora a",
      "filters": {
        "title": "Filtrar per",
        "distanceLogding": "Distància del",
        "recommended": "Recomanat",
        "ratings": "Valoracions",
        "star": "estrella",
        "score": "Puntuació",
        "stars": "estrelles",
        "remove": "Treure tots",
        "apply": "Aplicar filtres",
        "distante": {
          "oneKm": "Entre 1 km i 3 km",
          "threeKm": "Entre 3 km i 5 km",
          "fiveKm": "Entre 5 km i 10 km",
          "tenKm": "10 km+"
        }
      }
    }
  }
  
export default {
    "account": "Mijn account",
    "greeting": "Hallo, {name}!",
    "my_stays": {
        "title": "Mijn verblijven",
        "subtitle_active": "Beheer je verblijven",
        "subtitle_inactive": "Maak je volgende verblijf aan"
    },
    "personal_info": {
        "title": "Persoonlijke informatie",
        "description": "Beheer je persoonlijke gegevens"
    },
    "language": {
        "title": "Taalvoorkeur",
        "description": "Selecteer je standaardtaal"
    },
    "logout": "Afmelden",
    "share_stay": "Verblijf delen",
    "page_personal_info": {
        "title": "Persoonlijke informatie",
        "change_photo": "Foto wijzigen",
        "change_password": "Wachtwoord wijzigen",
        "btn_save": "Opslaan",
        "form": {
            "name": {
                "label": "Voornaam",
                "placeholder": "Voer je voornaam in"
            },
            "last_name": {
                "label": "Achternaam",
                "placeholder": "Voer je achternaam in"
            },
            "email": {
                "label": "E-mailadres",
                "placeholder": "Voer je e-mailadres in"
            },
            "number_phone": {
                "label": "Telefoonnummer",
                "placeholder": "Voer je telefoonnummer in"
            },
            "password": {
                "label": "Wachtwoord",
                "placeholder": "Voer je wachtwoord in"
            }
        }
    },
    "book_next_stay": {
        "title": "Boek je volgende verblijf",
        "description": "Plan je volgende uitje"
    },
    "rewards": {
        "title": "Nodig een vriend uit",
        "description": "Deel exclusieve voordelen",
        "button-recommend": "Nodig nu een vriend uit",
        "referrals": {
            "title": "Nodig een vriend uit!",
            "discount": "{amount} korting",
            "description": "Stuur je referentiecode. Ze ontvangen {amount} korting dankzij jouw code.",
        },
        "referent": {
            "title": "Nodig een vriend uit en profiteer beiden!",
            "titleReferred": "{amount} korting voor de verwezen persoon",
            "descerptionReferred": "Stuur je referentiecode. Ze ontvangen {amount} korting dankzij jouw code.",
            "titleReferent": "Ontvang {amount} korting",
            "descerptionReferent": "Na validatie en gebruik van de code ontvang jij {amount} korting."
        },
        "titleReferentProfile": "Verdien geld",
        "descriptionReferentProfile": "Verwijs een vriend en ontvang voordelen"
    },
    "next-reserve": {
        "reserve-button": "Boek nu",
        "continue-title": "Wil je doorgaan?",
        "continue-message": "Je wordt doorgestuurd naar de website van het hotel om je boeking af te ronden.",
        "continue-yes": "Ja, doorgaan",
        "continue-cancel": "Annuleren",
    },
};

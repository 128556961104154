export default{
    "privacy":{
        "title": "Politique de confidentialité",
        //
        "s1-t1": "1. IDENTIFICATION ET RESPONSABILITÉ DU TRAITEMENT DES DONNÉES.",
        "s1-p1": "{hotel} dont le siège est situé à {address} et avec le NIF {nif} est responsable des données personnelles collectées via la webapp.",
        "s1-p2": "Si vous avez la moindre question ou besoin de plus d'informations sur le traitement de vos données personnelles, vous pouvez nous contacter via {email}.",
        "s1-p3": "Coordonnées du Délégué à la Protection des Données : email {email}, adresse postale [adresse].",
        //
        "s2-t1": "2. INFORMATIONS PERSONNELLES COLLECTÉES.",
        "s2-p1": "En utilisant la webapp, nous pouvons collecter et traiter les informations suivantes vous concernant :",
        "s2-p2": "Accès à la webapp. Certains accès à la webapp impliqueront la demande de l'une des données suivantes :",
        "s2-p3": "Nom et prénom : pour vous identifier et personnaliser votre expérience sur la webapp.",
        "s2-p4": "Numéro de téléphone ou email : pour faciliter la communication et vous envoyer des notifications relatives à la webapp.",
        "s2-p5": "Dates de check-in et check-out : pour connaître les dates de votre séjour et vous fournir des informations pertinentes.",
        "s2-p6": "Enregistrement du séjour : prénom, nom, deuxième nom, sexe, nationalité, date de naissance, ville de naissance, pays de naissance, pays de résidence, ville de résidence, adresse de résidence, type d'identification, numéro d'identification, date d'identification, pays d'identification, ville d'émission, objet du séjour, numéro de visa, signature.",
        "s2-p7": "Participation aux enquêtes. Si vous décidez de participer aux enquêtes de satisfaction, nous recueillons vos réponses pour évaluer votre expérience et nous aider à améliorer nos services.",
        //
        "s3-t1": "3. FINALITÉ DU TRAITEMENT.",
        "s3-p1": "Les données personnelles collectées via la webapp sont utilisées pour les finalités suivantes :",
        "s3-p2": "Accès et utilisation de la webapp : faciliter l'accès et l'utilisation personnalisée de la webapp, permettant à l'hôte d'obtenir des informations relatives à son séjour, à la ville et aux activités touristiques disponibles, et de communiquer avec le personnel de l'hôtel via le chat intégré dans la webapp.",
        "s3-p3": "Enquêtes de satisfaction : pendant ou après le séjour, l'hôte peut être invité à participer à une enquête pour évaluer son expérience et fournir un retour d'information.",
        "s3-p4": "Notifications : dans certains cas, nous pouvons envoyer des notifications, par email ou SMS, concernant des messages non lus dans le chat ou toute autre information pertinente sur le séjour.",
        "s3-p5": "Communications commerciales : envoi de communications commerciales et d'offres personnalisées.",
        "s3-p6": "Transmission d'informations aux autorités compétentes, conformément aux réglementations touristiques.",
        //
        "s4-t1": "4. BASE LÉGALE DU TRAITEMENT.",
        "s4-p1": "La base juridique qui légitime le traitement des données personnelles des hôtes est fondée sur :",
        "s4-p2": "L'exécution d'un contrat auquel la personne concernée est partie ou, à sa demande, l'application de mesures précontractuelles.",
        "s4-p3": "Intérêt légitime : dans certaines circonstances, il se peut que nous traitions des données personnelles sur la base de notre intérêt légitime, à condition que cet intérêt ne soit pas supplanté par les intérêts ou les droits et libertés fondamentaux de l'hôte.",
        "s4-p4": "Respect d'une obligation légale : dans certains cas, le traitement des données personnelles peut être nécessaire pour satisfaire aux obligations légales applicables à l'hébergeur.",
        //
        "s5-t1": "5. TRANSFERT DES DONNÉES.",
        "s5-p1": "Les données personnelles des hôtes ne seront pas cédées ou transférées à des tiers sans leur consentement préalable éclairé, sauf dans les cas prévus par la loi.",
        "s5-p2": "Cependant, pour le bon fonctionnement de la webapp, il est nécessaire que certains prestataires de services accèdent aux données personnelles des hôtes en tant que sous-traitants. Ces prestataires sont tenus de traiter les données exclusivement pour les finalités stipulées par l'hébergeur et selon les conditions déterminées dans les contrats de sous-traitance correspondants.",
        "s5-p3": "Parmi ces prestataires figure TheHoster (The Hoster Hospitality S.L. avec le NIF B56861479 et email de contact {email}), qui fournit l'infrastructure et la technologie nécessaires au bon fonctionnement de la webapp. TheHoster agit en tout temps en tant que sous-traitant et ne traite les données que selon les instructions du responsable de l'hébergement et pour les finalités prévues.",
        //
        "s6-t1": "6. DROITS DE L'UTILISATEUR.",
        "s6-p1": "a) Droit d'accès : vous avez le droit de demander et d'obtenir la confirmation quant au traitement de vos données personnelles, ainsi que d'accéder à vos données personnelles stockées.",
        "s6-p2": "b) Droit de rectification : vous avez le droit de demander la rectification de vos données personnelles si celles-ci sont inexactes ou incomplètes.",
        "s6-p3": "c) Droit à l'effacement : vous avez le droit de demander l'effacement de vos données personnelles lorsque, entre autres motifs, les données ne sont plus nécessaires aux finalités pour lesquelles elles ont été collectées.",
        "s6-p4": "d) Droit d'opposition : vous avez le droit de vous opposer au traitement de vos données personnelles pour des raisons liées à votre situation particulière.",
        "s6-p5": "e) Droit à la limitation du traitement : vous avez le droit de demander la limitation du traitement de vos données personnelles, auquel cas elles ne seront conservées que pour l'exercice ou la défense de réclamations.",
        "s6-p6": "f) Droit à la portabilité des données : vous avez le droit de recevoir les données personnelles vous concernant que vous avez fournies à un responsable du traitement, dans un format structuré, couramment utilisé et lisible par machine, et de les transmettre à un autre responsable du traitement.",
        "s6-p7": "g) Droit de ne pas faire l'objet d'une décision individuelle automatisée : vous avez le droit de ne pas être soumis à une décision basée uniquement sur un traitement automatisé, y compris le profilage, qui produirait des effets juridiques vous concernant ou vous affecterait de manière significative.",
        "s6-p8": "h) Droit de retirer le consentement donné.",
        "s6-p9": "i) Droit de déposer une réclamation auprès de la CNIL si vous estimez que vos droits, tels que reconnus par la réglementation applicable en matière de protection des données, ont été violés.",
        //
        "s7-t1": "7. DURÉE DE CONSERVATION DES DONNÉES.",
        "s7-p1": "Vos données sont conservées pendant le temps nécessaire à la réalisation des différents traitements et, au minimum, conformément à la réglementation touristique, pendant une période de 3 ans.",
        //
        "s8-t1": "8. LIENS VERS DES SITES WEB TIERS.",
        "s8-p1": "La webapp peut inclure des hyperliens ou des liens permettant d'accéder à des sites web tiers. Les propriétaires de ces sites web disposent de leurs propres politiques de protection des données et sont, en chaque cas, responsables de leurs propres fichiers et pratiques de confidentialité.",
        "s8-p2": "La présente Politique de Confidentialité a été mise à jour pour la dernière fois le 06 mars 2024."
    },
}

export default {
    "account": "A miña conta",
    "greeting": "Ola, {name}!",
    "my_stays": {
        "title": "As miñas estadías",
        "subtitle_active": "Xestiona as túas estadías",
        "subtitle_inactive": "Crea a túa próxima estadía"
    },
    "personal_info": {
        "title": "Información persoal",
        "description": "Configura a túa información persoal"
    },
    "language": {
        "title": "Preferencia de idioma",
        "description": "Selecciona o teu idioma predeterminado"
    },
    "logout": "Pechar sesión",
    "share_stay": "Compartir estadía",
    "page_personal_info": {
        "title": "Información persoal",
        "change_photo": "Cambiar foto",
        "change_password": "Cambiar contrasinal",
        "btn_save": "Gardar",
        "form": {
            "name": {
                "label": "Nome",
                "placeholder": "Introduce o teu nome"
            },
            "last_name": {
                "label": "Apelidos",
                "placeholder": "Introduce o teu apelido"
            },
            "email": {
                "label": "Correo electrónico",
                "placeholder": "Introduce o teu correo"
            },
            "number_phone": {
                "label": "Número de teléfono",
                "placeholder": "Introduce o teu número de teléfono"
            },
            "password": {
                "label": "Contrasinal",
                "placeholder": "Introduce o teu contrasinal"
            }
        }
    },
    "book_next_stay": {
        "title": "Reserva a túa próxima estadía",
        "description": "Planifica a túa próxima escapada"
    },
    "rewards": {
        "title": "Convida a un amigo",
        "description": "Comparte beneficios exclusivos",
        "button-recommend": "Recomendar a un amigo agora",
        "referrals": {
            "title": "Recomenda a un amigo!",
            "discount": "{amount} de desconto",
            "description": "Envíalle o teu código de referencia. Obtendrá {amount} de desconto grazas ao teu código.",
        },
        "referent": {
            "title": "Recomenda a un amigo e gañade os dous!",
            "titleReferred": "{amount} de desconto para o referido",
            "descerptionReferred": "Envíalle o teu código de referencia. Obtendrá {amount} de desconto grazas ao teu código.",
            "titleReferent": "Recibe {amount} de desconto",
            "descerptionReferent": "Unha vez validado e usado o código do referido, conseguirás {amount} de desconto."
        },
        "titleReferentProfile": "Gaña cartos",
        "descriptionReferentProfile": "Recomenda a un amigo para obter beneficios"
    },
    "next-reserve": {
        "reserve-button": "Reservar",
        "continue-title": "Queres continuar?",
        "continue-message": "Serás redirixido á páxina web do hotel para continuar o proceso de reserva.",
        "continue-yes": "Si, continuar",
        "continue-cancel": "Cancelar",
    },
};

export default {
    "mobileTitle": "Hotelinformatie",
    "info": "Contactgegevens",
    "horary": "Check-in / Check-out tijden",
    "checkin": "Aankomst vanaf",
    "checkout": "Vertrek tot",
    "social": "Vind ons op...",
    "msgCopyPhone": "Telefoon succesvol gekopieerd",
    "msgCopyEmail": "E-mail succesvol gekopieerd",
    "msgCopyAddress": "Adres succesvol gekopieerd"
}

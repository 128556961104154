export default {
    "textToast": {
      "sendQueryText": "Agradecemos sinceramente o teu feedback. A túa experiencia é importante para nós e tratamos de cumprir coas túas expectativas",
      "updateQueryText": "Modificación enviada",
      "registerResponse": "Feedback enviado",
      "updateResponse": "Feedback modificado"
    },
    "form": {
      "send": "Enviar",
      "sending": "Enviando",
      "modify": "Modificar",
      "cancel": "Cancelar",
      "title": "Feedback",
      "hello": "Ola",
      "whatsup": "Que tal",
      "thanksAll": "Grazas por todo",
      "btn-verywrong-stay": "Moi malo",
      "btn-wrong-stay": "Malo",
      "btn-normal-stay": "Normal",
      "btn-good-stay": "Bo",
      "btn-verygood-stay": "Moi bo",
      "btn-verywrong-poststay": "Moi mala",
      "btn-wrong-poststay": "Mala",
      "btn-normal-poststay": "Normal",
      "btn-good-poststay": "Boa",
      "btn-verygood-poststay": "Moi boa",
      "poststay-bad-thanks-title": "Resposta enviada",
      "poststay-bad-thanks-subtitle": "Moitas grazas polo teu tempo",
      "continueIn": "Continuar en"
    },
    "settings": {
      "questionpre-stay": "Hai algo que poidamos facer para a túa chegada ao {lodging}?",
      "questionin-stay": "Como valorarías o teu nivel de satisfacción coa estancia ata agora?",
      "questionpost-stay": "Como foi a túa experiencia connosco?"
    },
    "inbox": {
      "title": "Mensaxes"
    }
  }
  
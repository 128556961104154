export default{
    "privacy":{
      "title":"Política de privacitat",
      "s1-t1":"1. IDENTIFICACIÓ I RESPONSABLE DEL TRACTAMENT DE LES DADES.",
      "s1-p1":"{hotel} amb domicili a {address} i amb NIF {nif} és el responsable de les dades personals recollides a través de la webapp.",
      "s1-p2":"Si tens cap dubte o necessites més informació sobre el tractament de les teves dades personals, pots contactar a través de {email}.",
      "s1-p3":"Dades de contacte del Delegat de Protecció de Dades: correu electrònic {email}, adreça postal [adreça].",
      "s2-t1":"2. INFORMACIÓ PERSONAL RECOLLIDA.",
      "s2-p1":"Quan utilitzis la webapp, podem recollir i processar la següent informació sobre tu:",
      "s2-p2":"Accés a la webapp. Certs accessos a la webapp requeriran que proporcionis alguna de les següents dades:",
      "s2-p3":"Nom i cognoms: per identificar-te i personalitzar la teva experiència a la webapp.",
      "s2-p4":"Número de telèfon o email: per facilitar la comunicació i enviar-te notificacions relacionades amb la webapp.",
      "s2-p5":"Data de Check-in i Check-out: per conèixer les dates de la teva estada i oferir-te informació rellevant.",
      "s2-p6":"Registre de l'estada: nom, cognom, segon cognom, sexe, nacionalitat, data de naixement, ciutat de naixement, país de naixement, país de residència, ciutat de residència, adreça de residència, tipus d'identificació, número d'identificació, data d'identificació, país d'identificació, ciutat d'emissió, propòsit de l'estada, número de visat, signatura.",
      "s2-p7":"Participació en enquestes. Si decideixes participar en les enquestes de satisfacció, recollirem les teves respostes per avaluar la teva experiència i ajudar-nos a millorar els serveis.",
      "s3-t1":"3. FINALITAT DEL TRACTAMENT.",
      "s3-p1":"Les dades personals recollides a través de la webapp s'utilitzen per a les següents finalitats:",
      "s3-p2":"Accés i ús de la webapp: facilitar l'accés i ús personalitzat de la webapp, permetent a l'hoste obtenir informació sobre la seva estada, la ciutat i les activitats turístiques disponibles, i comunicar-se amb el personal de l'hotel mitjançant el xat incorporat.",
      "s3-p3":"Enquestes de satisfacció: durant l'estada o un cop finalitzada, es pot convidar l'hoste a participar en una enquesta per valorar la seva experiència i proporcionar comentaris.",
      "s3-p4":"Notificacions: en alguns casos, podem enviar notificacions per correu electrònic o SMS relacionades amb missatges sense llegir o informació rellevant de l'estada.",
      "s3-p5":"Comunicacions comercials: enviament de comunicacions comercials i ofertes personalitzades.",
      "s3-p6":"Informació a les autoritats competents segons el que estableixin les normatives turístiques.",
      "s4-t1":"4. LEGITIMACIÓ DEL TRACTAMENT.",
      "s4-p1":"La base legal que legitima el tractament de les dades personals dels hostes es fonamenta en:",
      "s4-p2":"Execució d’un contracte en el qual l’interessat és part o per aplicar mesures precontractuals a petició seva.",
      "s4-p3":"Interès legítim: en certes circumstàncies, podem tractar dades basant-nos en el nostre interès legítim, sempre que aquest no prevalgui sobre els drets i llibertats fonamentals de l’hoste.",
      "s4-p4":"Compliment d’una obligació legal: en alguns casos, pot ser necessari tractar dades per complir amb obligacions legals del hoster.",
      "s5-t1":"5. CESSIÓ DE LES DADES.",
      "s5-p1":"Les dades personals dels hostes no es cediran a tercers sense el consentiment previ informat, excepte en els casos previstos per la llei.",
      "s5-p2":"Tanmateix, per al correcte funcionament de la webapp, és necessari que alguns proveïdors accedeixin a les dades com a encarregats de tractament. Aquests només poden tractar les dades segons les finalitats i condicions establertes pel hoster.",
      "s5-p3":"Entre aquests proveïdors hi ha TheHoster (The Hoster Hospitality S.L. amb NIF B56861479 i email {email}), que proporciona la infraestructura i tecnologia per al funcionament de la webapp. Actua com a encarregat de tractament seguint les instruccions del titular de l’allotjament.",
      "s6-t1":"6. DRETS DE L’USUARI.",
      "s6-p1":"a) Dret d'accés: pots sol·licitar confirmació de si es tracten dades teves i accedir-hi.",
      "s6-p2":"b) Dret de rectificació: pots demanar que es corregeixin dades inexactes o incompletes.",
      "s6-p3":"c) Dret de supressió: pots sol·licitar que s'eliminin dades quan ja no siguin necessàries.",
      "s6-p4":"d) Dret d’oposició: pots oposar-te al tractament per motius relacionats amb la teva situació particular.",
      "s6-p5":"e) Dret a la limitació del tractament: pots demanar limitar el tractament i conservar només per reclamacions.",
      "s6-p6":"f) Dret a la portabilitat: pots rebre les teves dades en format estructurat i transmetre-les a un altre responsable.",
      "s6-p7":"g) Dret a no ser objecte de decisions automatitzades: tens dret a no ser objecte de decisions que et puguin afectar significativament.",
      "s6-p8":"h) Dret a revocar el consentiment atorgat.",
      "s6-p9":"i) Dret a reclamar davant l'Agència Espanyola de Protecció de Dades si creus que s’han vulnerat els teus drets.",
      "s7-t1":"7. TERMINI DE CONSERVACIÓ DE LES DADES.",
      "s7-p1":"Les dades es conserven mentre siguin necessàries per al tractament i, com a mínim, durant 3 anys segons la normativa turística.",
      "s8-t1":"8. ENLLAÇOS A LLOCS WEB DE TERCERS",
      "s8-p1":"La webapp pot incloure enllaços a webs de tercers que tenen les seves pròpies polítiques de privacitat, de les quals són responsables.",
      "s8-p2":"Aquesta Política de Privacitat va ser actualitzada per última vegada el 06 de març de 2024."
    }
  }
  
export default {
    "guestLog": {
      "title": "Acceso á webapp",
      "name": {
        "label": "Nome e apelidos",
        "placeholder": "Introduce o teu nome e apelidos"
      },
      "email": {
        "label": "Correo electrónico",
        "placeholder": "Introduce o teu correo electrónico",
        "error": "Correo electrónico non válido"
      },
      "button": "Seguinte",
      "backButton": "Volver atrás",
      "processing": "Procesando"
    }
  }
  
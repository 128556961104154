export default {
    "title": 'Home',
    "title-welcome": "Welcome",
    "btn-see-all": "See all",
    "hab": "Room",
    "btn-more-info": "Hotel information",
    "stayTitle":"Stay",
    "guestTitle":"Guests",
    "invite":"Invite",
    "section-facility": {
      "title": "facilities"
    },
    "section-what-visit": {
      "title": "what to visit"
    },
    "section-where-eat": {
      "title": "where to eat"
    },
    "section-leisure": {
      "title": "leisure"
    },
    "section-experience": {
          "title": "experiences"
    },
    "card-product": {
      "featured": "featured",
      "recommended": "recommended"
    },
    "form-survey": {
      "title": "How was your experience with us?",
      "subtitle": "We would love to know your opinion, it's very important to us.",
      "btn-wrong": "Wrong",
      "btn-normal": "Normal",
      "btn-good": "Very Good",
      "btn-next": "Next",
      "btn-submit": "Submit",
      "message-success-title": "Thank you for sharing your experience!",
      "message-success-text": "We greatly value your feedback and will work to provide you with the best possible stay in the future."
    },
    "card-product":{
      "recommended":"Recomended",
      "featured":"Featured",
    },
    "inviteModal":{
      "title":"Invite guests",
      "inputLabel":"Invite another guest",
      "inputPlaceholder":"Guest email",
      "button":"Invite",
      "textToast":"Invitation sent",
    },
    "social": {
      "title": "Our networks"
    }
  };
export default {
    "textToast": {
      "sendQueryText": "Bedankt voor je feedback. Jouw ervaring is belangrijk voor ons en we streven ernaar om aan je verwachtingen te voldoen",
      "updateQueryText": "Wijziging verzonden",
      "registerResponse": "Feedback verzonden",
      "updateResponse": "Feedback bijgewerkt"
    },
    "form": {
      "send": "Verzenden",
      "sending": "Bezig met verzenden",
      "modify": "Wijzigen",
      "cancel": "Annuleren",
      "title": "Feedback",
      "hello": "Hallo",
      "whatsup": "Hoe gaat het",
      "thanksAll": "Bedankt voor alles",
      "btn-verywrong-stay": "Zeer slecht",
      "btn-wrong-stay": "Slecht",
      "btn-normal-stay": "Normaal",
      "btn-good-stay": "Goed",
      "btn-verygood-stay": "Zeer goed",
      "btn-verywrong-poststay": "Zeer slecht",
      "btn-wrong-poststay": "Slecht",
      "btn-normal-poststay": "Normaal",
      "btn-good-poststay": "Goed",
      "btn-verygood-poststay": "Zeer goed",
      "poststay-bad-thanks-title": "Antwoord verzonden",
      "poststay-bad-thanks-subtitle": "Bedankt voor je tijd",
      "continueIn": "Doorgaan in"
    },
    "settings": {
      "questionpre-stay": "Is er iets dat we kunnen doen voor je aankomst in {lodging}?",
      "questionin-stay": "Hoe zou je je tevredenheid tot nu toe beoordelen?",
      "questionpost-stay": "Hoe was je ervaring met ons?"
    },
    "inbox": {
      "title": "Berichten"
    }
  }
  
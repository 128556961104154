export default {
    "apiError401": "Zehaztapen okerrak",
    "apiError404": "Zerbitzua ez dago erabilgarri",
    "apiError405_406": "Eskabide baliogabea",
    "apiError500": "Konexio errorea",
    "apiErrorUndefined": "Aplikazioaren errore orokorra",
    "invalidKey": "Gako baliogabea",
    "recordSuccess": "Erregistroa osatuta",
    "emailSuccess": "Posta bidalia",
    "passwordUpdated": "Pasahitza eguneratuta",
    "copiedClipboard": "Arbelera kopiatua",
    "guestDeleted": "Gonbidatua ezabatua",
    "urlClipboard": "Esteka kopiatua",
    "changeSave": "Aldaketak gordeta",
    "dataSave": "Datuak arrakastaz gordeta"
  };
  
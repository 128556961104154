export default {
    'cafeterias': 'cafeteries',
    'heladerias': 'gelateries',
    'monumentos': 'monuments',
    'museos': 'museus',
    'zonas-verdes': 'zones verdes',
    'cafeterias-y-postres': 'cafeteries i postres',
    'restaurantes': 'restaurants',
    'tapas': 'tapes',
    'vida-nocturna': 'vida nocturna',
    'copas': 'copes',
    'compras': 'compres',
    'otros': 'altres'
  }
export default {
    "breadcrumbs": {
      "explore": "Verkennen"
    },
    "list-page": {
      "title": "Verken in",
      "text-count-list": "{count} plaatsen gevonden",
      "text-count-list-search": "{count} plaatsen gevonden voor",
      "text-count-list-search-active": "Alle plaatsen zoeken voor",
      "text-search-not-found": "Pas je zoekopdracht aan of probeer een andere categorie",
      "btn-load-more": "Meer laden"
    },
    "results": "Geen resultaten...",
    "seaching": "Bezig met zoeken...",
    "categorySearch": "gevonden",
    "city": "Stad",
    "placeholder-search": "Bestemming zoeken",
    "types": {
      "que-visitar": "Wat te bezoeken",
      "donde-comer": "Waar te eten",
      "ocio": "Vrije tijd"
    },
    "categories": {
      "monumentos": "Monumenten",
      "museos": "Musea",
      "zonas-verdes": "Groene zones",
      "cafeterias-y-postres": "Cafés en desserts",
      "restaurantes": "Restaurants",
      "vida-nocturna": "Nachtleven",
      "copas": "Drankjes",
      "compras": "Winkelen",
      "otros": "Overige"
    },
    "detail": {
      "titleList": "Verken",
      "opinionsTitle": "Beoordelingen van toeristische info",
      "opinionsWord": "Recensies",
      "recommendationWord": "Aanbeveling",
      "featured": "Uitgelicht",
      "callWord": "Bellen",
      "dataInterest": {
        "title": "Belangrijke gegevens",
        "priceRange": "Prijsklassen",
        "diet": "Speciale diëten",
        "others": "Overige"
      },
      "specialRegimes": "Speciale regelingen",
      "additionalInformation": "Aanvullende informatie",
      "copy": "Kopiëren",
      "showLess": "Minder weergeven",
      "showMore": "Meer weergeven",
      "recommended": "Aanbevolen",
      "recommendedTitle": "Aanbevolen door {lodging}",
      "buttoms": {
        "web": "Website",
        "call": "Bellen",
        "menu": "Menu",
        "email": "E-mail"
      },
      "hours": {
        "title": "Openingstijden",
        "monday": "maandag",
        "tuesday": "dinsdag",
        "wednesday": "woensdag",
        "thursday": "donderdag",
        "friday": "vrijdag",
        "saturday": "zaterdag",
        "sunday": "zondag"
      },
      "locationWord": "Locatie",
      "starWord": "Ster",
      "starsWord": "Sterren",
      "ratingTitle": "Beoordeling",
      "noComment": "Geen commentaar geschreven",
      "seeMoreComment": "Meer bekijken",
      "seeAllComments": "Alle beoordelingen bekijken",
      "titleAllReviews": "Beoordelingen van toeristische info",
      "exploreIn": "Verken in",
      "filters": {
        "title": "Filteren op",
        "distanceLogding": "Afstand vanaf",
        "recommended": "Aanbevolen",
        "ratings": "Beoordelingen",
        "star": "ster",
        "score": "Score",
        "stars": "sterren",
        "remove": "Alles verwijderen",
        "apply": "Filters toepassen",
        "distante": {
          "oneKm": "Tussen 1 km en 3 km",
          "threeKm": "Tussen 3 km en 5 km",
          "fiveKm": "Tussen 5 km en 10 km",
          "tenKm": "10 km+"
        }
      }
    }
  }
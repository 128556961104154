export default {
    "log-or-register": {
      "title": "Log in of registreer",
      "welcome-title": "Welkom!",
      "welcome-msg": "Geniet van onze WebApp en beheer je verblijven in al onze hotels.",
      "input": "Voer je e-mailadres in",
      "text-error": "Het ingevoerde e-mailadres is niet correct",
      "continue-button": "Doorgaan",
      "continue-google": "Doorgaan met Google",
      "continue-fb": "Doorgaan met Facebook"
    },
    "log": {
      "title": "Log in",
      "input-password": "Voer een wachtwoord in",
      "password-text-error": "Het ingevoerde wachtwoord is onjuist",
      "continue-button": "Doorgaan",
      "forgot-question": "Wachtwoord vergeten?",
      "input-show-pass": "Tonen",
      "input-hide-pass": "Verbergen",
      "entering": "Bezig met inloggen"
    },
    "register": {
      "title": "Maak je registratie af",
      "input-name": "Voer je naam in",
      "input-email": "Voer je e-mailadres in",
      "input-email-error": "Het ingevoerde e-mailadres is niet correct",
      "input-password": "Voer een wachtwoord in",
      "continue-button": "Accepteren en doorgaan",
      "text-info-1": "zal je commerciële communicatie en gepersonaliseerde promoties sturen via e-mail, sms of andere elektronische middelen over producten en diensten",
      "text-info-2": "Ik wil geen promotieberichten ontvangen.",
      "policies-text": "Door op Accepteren en doorgaan te klikken, ga ik akkoord met het",
      "policies-title": "Privacybeleid.",
      "the-chain": "De keten",
      "password-info": "Het wachtwoord moet minstens 8 tekens bevatten.",
      "complete-register": "Registratie voltooien"
    },
    "reset-pass": {
      "title": "Wachtwoord wijzigen",
      "input-placeholder": "Voer je nieuwe wachtwoord in",
      "input-input-error": "Het ingevoerde wachtwoord is onjuist",
      "continue-button": "Wachtwoord wijzigen",
      "greeting": "Hallo",
      "title-reset": "Wachtwoord opnieuw instellen",
      "msg": "Je hebt een link aangevraagd om het wachtwoord van je account in onze WebApp opnieuw in te stellen. Klik op de onderstaande knop om te beginnen.",
      "link": "Wachtwoord opnieuw instellen"
    },
    "hotel-list": {
      "title": "Selecteer de accommodatie om je verblijf in onze WebApp aan te maken",
      "amount-text": "accommodaties van de keten",
      "stars-text": "sterren",
      "star-text": "ster"
    },
    "my-stay": {
      "title": "Mijn verblijf"
    },
    "saving-changes": "Wijzigingen opslaan"
  }
  
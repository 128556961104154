export default {
  "title": "Inici",
  "title-welcome": "Benvingut",
  "btn-see-all": "veure tot",
  "hab": "Hab",
  "btn-more-info": "Informació de l'hotel",
  "stayTitle": "Estada",
  "guestTitle": "Hostes",
  "invite": "Convidar",
  "section-facility": {
    "title": "instal·lacions"
  },
  "section-what-visit": {
    "title": "què visitar"
  },
  "section-where-eat": {
    "title": "on menjar"
  },
  "section-leisure": {
    "title": "oci"
  },
  "section-experience": {
    "title": "experiències"
  },
  "form-survey": {
    "title": "Com ha estat la teva experiència amb nosaltres?",
    "subtitle": "Ens encantaria conèixer la teva opinió, és molt important per a nosaltres.",
    "btn-wrong": "Malament",
    "btn-normal": "Normal",
    "btn-good": "Molt bé",
    "btn-next": "Següent",
    "btn-submit": "Enviar",
    "message-success-title": "Gràcies per compartir la teva experiència!",
    "message-success-text": "Valorem molt la teva opinió i treballarem per oferir-te la millor estada possible en el futur."
  },
  "card-product": {
    "recommended": "Recomanat",
    "featured": "Destacat"
  },
  "inviteModal": {
    "title": "Convidar hostes",
    "inputLabel": "Convida un altre hoste",
    "inputPlaceholder": "Correu electrònic de l'hoste",
    "button": "Convidar",
    "textToast": "Invitació enviada"
  },
  "social": {
    "title": "Les nostres xarxes"
  }
};

export default {
  "title": "Diensten",
  "placeholder-search": "Zoek een dienst",
  "modal-request-service": {
    "button": "Dienst aanvragen",
    "title": "Je wordt doorgestuurd naar een externe website",
    "text": "Je staat op het punt om doorgestuurd te worden naar de website van de dienstverlener. Wil je doorgaan?",
    "button-go": "Ga naar de site"
  },
  "card-item": {
    "free": "Gratis"
  },
  "confort": {
    "title": "Comfort",
    "text-search-found": "Diensten gevonden in comfort {number}",
    "list-page": {},
    "detail-page": {}
  },
  "transport": {
    "title": "Vervoer",
    "text-search-found": "Diensten gevonden in vervoer {number}",
    "list-page": {},
    "detail-page": {}
  },
  "activity": {
    "title": "Activiteiten",
    "text-search-found": "Diensten gevonden in activiteiten",
    "list-page": {},
    "detail-page": {}
  }
}

export default {
  "information": "Información",
  "facilities": "Instalacións",
  "lodging": "aloxamento",
  "apartment": "apartamento",
  "buttons_home": {
    "wifi": "Wifi",
    "call": "Chamar",
    "standards": "Normas",
    "share_stay": "Compartir estancia"
  },
  "utils": {
    "see_more": "Ver máis",
    "see_all": "Ver todo",
    "see_less": "Ver menos",
    "copy": "Copiar",
    "from": "Desde",
    "to": "Ata",
    "our_networks": "As nosas redes"
  }
};

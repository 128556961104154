export default {
    "cmn": "mandarín",
    "es": "español",
    "en": "inglés",
    "hi": "hindi",
    "ar": "árabe",
    "pt": "portugués",
    "bn": "bengalí",
    "ru": "ruso",
    "ja": "xaponés",
    "pa": "panxabí",
    "de": "alemán",
    "jv": "xavanés",
    "wuu": "chinés wu",
    "ms": "malaio",
    "te": "telugu",
    "vi": "vietnamita",
    "ko": "coreano",
    "fr": "francés",
    "mr": "maratí",
    "ta": "támil",
    "ur": "urdú",
    "tr": "turco",
    "it": "italiano",
    "yue": "cantonés",
    "th": "tailandés",
    "gu": "gujaratí",
    "mnp": "chinés min bei",
    "fa": "persa",
    "pl": "polaco",
    "ps": "pastún",
    "hsn": "chinés hunanés",
    "ml": "malabar",
    "my": "birmano",
    "hak": "chinés hakka",
    "uk": "ucraínico",
    "fil": "filipino",
    "ro": "romanés",
    "nl": "neerlandés",
    "ca": "catalán",
    "gl": "galego",
    "eu": "éuscaro",
    "ku": "kurdo",
    "hr": "croata",
    "hu": "húngaro",
    "el": "grego",
    "cs": "checo",
    "sv": "sueco",
    "fi": "finlandés",
    "sk": "eslovaco",
    "sr": "serbio",
    "no": "noruegués",
    "he": "hebreo",
    "da": "dinamarqués",
    "zh_CN": "chinés simplificado",
    "title": "Preferencia de idioma",
    "ca": "Catalán",
    "eu": "Vasco",
    "gl": "Galego",
    "nl": "Holandés"
  }
  
export default {
    'cafeterias': 'kafetegiak',
    'heladerias': 'izozkitegiak',
    'monumentos': 'monumentuak',
    'museos': 'museoak',
    'zonas-verdes': 'eremu berdeak',
    'cafeterias-y-postres': 'kafetegiak eta postreak',
    'restaurantes': 'jatetxeak',
    'tapas': 'tapa',
    'vida-nocturna': 'gaueko bizitza',
    'copas': 'kopak',
    'compras': 'erosketak',
    'otros': 'beste batzuk'
  }
  
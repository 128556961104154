export default {
  "information": "Informació",
  "facilities": "Instal·lacions",
  "lodging": "allotjament",
  "apartment": "apartament",
  "buttons_home": {
    "wifi": "Wifi",
    "call": "Trucar",
    "standards": "Normes",
    "share_stay": "Compartir estada"
  },
  "utils": {
    "see_more": "Veure més",
    "see_all": "Veure tot",
    "see_less": "Veure menys",
    "copy": "Copiar",
    "from": "Des de",
    "to": "Fins a",
    "our_networks": "Les nostres xarxes"
  }
};

export default {
    "header": {
      "home": "hasiera",
      "facilities": "instalazioak",
      "hotel": "hotela",
      "hostal": "ostatu",
      "destination": "helmuga",
      "experiences": "esperientziak",
      "messages": "mezuak"
    },
    "dropdownLanguage": {
      "title": "hizkuntza"
    },
    "footer": {
      "legal-warning": "ohar legala",
      "privacy-policy": "pribatutasun politika",
      "privacy-cookies": "cookieen politika",
      "terms-conditions": "baldintzak eta kondizioak"
    },
    "input-search": {
      "search": "Bilatu",
      "placeholder": "Helmugak, esperientziak...",
      "from": "tik"
    }
  };
  
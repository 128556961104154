export default {
    "title": "Mezuak",
    "availabilty": "Erabilgarri",
    "not-availabilty": "Ez dago erabilgarri",
    "languages-text": "Ongi etorri txatera!<br>Gure langileekin hizkuntza hauetan komunikatu zaitezke:",
    "automatic-response": "Erantzun automatikoa",
    "input": "Idatzi mezu bat...",
    "horary-title": "Txataren ordutegiak",
    "nameDayLunes": "Astelehena",
    "nameDayMartes": "Asteartea",
    "nameDayMiércoles": "Asteazkena",
    "nameDayJueves": "Osteguna",
    "nameDayViernes": "Ostirala",
    "nameDaySábado": "Larunbata",
    "nameDayDomingo": "Igandea",
    "tabs": {
      "inbox": "sarrera",
      "chat": "txata"
    }
  }
  
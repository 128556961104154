export default {
    "title": "Services",
    "placeholder-search": "Search service",
    "modal-request-service": {
      "button": "Request service",
      "title": "You are about to be redirected to an external website",
      "text": "You are about to be redirected to the service provider's website. Do you want to continue?",
      "button-go": "Go to site"
    },
    "card-item": {
      "free": "Free"
    },
    "confort": {
      "title": "Comfort",
      "text-search-found": "Services found in comfort {number}",
      "list-page": {},
      "detail-page": {}
    },
    "transport": {
      "title": "Transport",
      "text-search-found": "Services found in transportation {number}",
      "list-page": {},
      "detail-page": {}
    },
    "activity": {
      "title": "Activities",
      "text-search-found": "Services found in activities",
      "list-page": {},
      "detail-page": {}
    }
  }
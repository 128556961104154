export default {
    "cmn": "mandarí",
    "es": "espanyol",
    "en": "anglès",
    "hi": "hindi",
    "ar": "àrab",
    "pt": "portuguès",
    "bn": "bengalí",
    "ru": "rus",
    "ja": "japonès",
    "pa": "panjabi",
    "de": "alemany",
    "jv": "javanès",
    "wuu": "xinès wu",
    "ms": "malai",
    "te": "telugu",
    "vi": "vietnamita",
    "ko": "coreà",
    "fr": "francès",
    "mr": "marathi",
    "ta": "tàmil",
    "ur": "urdú",
    "tr": "turc",
    "it": "italià",
    "yue": "cantonès",
    "th": "tai",
    "gu": "gujarati",
    "mnp": "xinès min bei",
    "fa": "persa",
    "pl": "polonès",
    "ps": "paixtu",
    "hsn": "xinès hunanès",
    "ml": "malaiàlam",
    "my": "birmà",
    "hak": "xinès hakka",
    "uk": "ucraïnès",
    "fil": "filipí",
    "ro": "romanès",
    "nl": "neerlandès",
    "ca": "català",
    "gl": "gallec",
    "eu": "basc",
    "ku": "kurd",
    "hr": "croat",
    "hu": "hongarès",
    "el": "grec",
    "cs": "txec",
    "sv": "suec",
    "fi": "finès",
    "sk": "eslovac",
    "sr": "serbi",
    "no": "noruec",
    "he": "hebreu",
    "da": "danès",
    "zh_CN": "xinès simplificat",
    "title": "Preferència d'idioma",
    "ca": "Català",
    "eu": "Basc",
    "gl": "Gallec",
    "nl": "Holandès"

  }
  
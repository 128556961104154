export default {
    'cafeterias': 'cafés',
    'heladerias': 'ijswinkels',
    'monumentos': 'monumenten',
    'museos': 'musea',
    'zonas-verdes': 'groene zones',
    'cafeterias-y-postres': 'cafés en desserts',
    'restaurantes': 'restaurants',
    'tapas': 'tapas',
    'vida-nocturna': 'nachtleven',
    'copas': 'drankjes',
    'compras': 'winkelen',
    'otros': 'overige'
  }
  
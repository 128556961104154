export default {
    "log-or-register": {
      "title": "Inicia sessió o registra't",
      "welcome-title": "Benvingut!",
      "welcome-msg": "Gaudeix de la nostra WebApp i gestiona les teves estades en qualsevol dels nostres hotels.",
      "input": "Introdueix el teu correu electrònic",
      "text-error": "El format del correu electrònic introduït no és correcte",
      "continue-button": "Continuar",
      "continue-google": "Continuar amb Google",
      "continue-fb": "Continuar amb Facebook"
    },
    "log": {
      "title": "Inicia sessió",
      "input-password": "Introdueix una contrasenya",
      "password-text-error": "La contrasenya introduïda és incorrecta",
      "continue-button": "Continuar",
      "forgot-question": "Has oblidat la teva contrasenya?",
      "input-show-pass": "Mostrar",
      "input-hide-pass": "Ocultar",
      "entering": "Iniciant sessió"
    },
    "register": {
      "title": "Acaba de registrar-te",
      "input-name": "Introdueix el teu nom",
      "input-email": "Introdueix el teu correu electrònic",
      "input-email-error": "El format del correu electrònic introduït no és correcte",
      "input-password": "Introdueix una contrasenya",
      "continue-button": "Acceptar i continuar",
      "text-info-1": "t'enviarà comunicacions comercials i promocions personalitzades per correu electrònic, SMS o altres mitjans electrònics sobre productes i serveis",
      "text-info-2": "No vull rebre missatges promocionals.",
      "policies-text": "En seleccionar Acceptar i continuar, accepto la",
      "policies-title": "Política de Privacitat.",
      "the-chain": "La cadena",
      "password-info": "La contrasenya ha de contenir almenys 8 caràcters.",
      "complete-register": "Completant registre"
    },
    "reset-pass": {
      "title": "Canviar contrasenya",
      "input-placeholder": "Introdueix la teva nova contrasenya",
      "input-input-error": "La contrasenya introduïda és incorrecta",
      "continue-button": "Canviar contrasenya",
      "greeting": "Hola",
      "title-reset": "Restablir contrasenya",
      "msg": "Has sol·licitat un enllaç per restablir la contrasenya del teu compte a la nostra WebApp. Fes clic al següent botó per iniciar el procés.",
      "link": "Restablir contrasenya"
    },
    "hotel-list": {
      "title": "Selecciona l'allotjament per crear la teva estada a la nostra WebApp",
      "amount-text": "allotjaments de la cadena",
      "stars-text": "estrelles",
      "star-text": "estrella"
    },
    "my-stay": {
      "title": "La meva estada"
    },
    "saving-changes": "Guardant canvis"
  }
export default {
    "title": "Mensaxes",
    "availabilty": "Dispoñible",
    "not-availabilty": "Non dispoñible",
    "languages-text": "Dámosche a benvida ao chat!<br>Podes comunicarte co noso persoal nestes idiomas:",
    "automatic-response": "Resposta automática",
    "input": "Escribe unha mensaxe...",
    "horary-title": "Horarios do chat",
    "nameDayLunes": "Luns",
    "nameDayMartes": "Martes",
    "nameDayMiércoles": "Mércores",
    "nameDayJueves": "Xoves",
    "nameDayViernes": "Venres",
    "nameDaySábado": "Sábado",
    "nameDayDomingo": "Domingo",
    "tabs": {
      "inbox": "buzón",
      "chat": "chat"
    }
  }
  
export default{
    "privacy":{
        "title": "Datenschutzerklärung",
        //
        "s1-t1": "1. IDENTIFIKATION UND VERANTWORTLICHKEIT FÜR DIE DATENVERARBEITUNG.",
        "s1-p1": "{hotel} mit Sitz in {address} und mit der Steuernummer {nif} ist für die über die Webapp erhobenen personenbezogenen Daten verantwortlich.",
        "s1-p2": "Wenn Sie Fragen haben oder weitere Informationen zur Verarbeitung Ihrer personenbezogenen Daten benötigen, können Sie uns unter {email} kontaktieren.",
        "s1-p3": "Kontaktdaten des Datenschutzbeauftragten: E-Mail {email}, Postanschrift [Adresse].",
        //
        "s2-t1": "2. ERFASSTE PERSONENBEZOGENE INFORMATIONEN.",
        "s2-p1": "Bei der Nutzung der Webapp können wir folgende Informationen über Sie erheben und verarbeiten:",
        "s2-p2": "Zugriff auf die Webapp. Bestimmte Zugriffe auf die Webapp erfordern die Angabe der folgenden Daten:",
        "s2-p3": "Vor- und Nachname: um Sie zu identifizieren und Ihre Erfahrung in der Webapp zu personalisieren.",
        "s2-p4": "Telefonnummer oder E-Mail: um die Kommunikation zu erleichtern und Ihnen Benachrichtigungen im Zusammenhang mit der Webapp zu senden.",
        "s2-p5": "Check-in- und Check-out-Daten: um die Daten Ihres Aufenthalts zu erfassen und Ihnen relevante Informationen bereitzustellen.",
        "s2-p6": "Aufenthaltsregistrierung: Vorname, erster Nachname, zweiter Nachname, Geschlecht, Nationalität, Geburtsdatum, Geburtsort, Geburtsland, Wohnsitzland, Wohnort, Wohnadresse, Ausweistyp, Ausweisnummer, Ausweisdatum, Ausstellungsland, Ausstellungsort, Aufenthaltszweck, Visanummer, Unterschrift.",
        "s2-p7": "Teilnahme an Umfragen. Wenn Sie sich entscheiden, an Zufriedenheitsumfragen teilzunehmen, erfassen wir Ihre Antworten, um Ihre Erfahrung zu bewerten und uns bei der Verbesserung unserer Dienstleistungen zu unterstützen.",
        //
        "s3-t1": "3. ZWECK DER VERARBEITUNG.",
        "s3-p1": "Die über die Webapp erhobenen personenbezogenen Daten werden für folgende Zwecke verwendet:",
        "s3-p2": "Zugriff und Nutzung der Webapp: um einen personalisierten Zugriff und die Nutzung der Webapp zu ermöglichen, sodass der Gast Informationen zu seinem Aufenthalt, zur Stadt und zu den verfügbaren touristischen Aktivitäten erhält und mit dem Hotelpersonal über den integrierten Chat kommunizieren kann.",
        "s3-p3": "Zufriedenheitsumfragen: Während des Aufenthalts oder nach dessen Abschluss kann der Gast eingeladen werden, an einer Umfrage teilzunehmen, um seine Erfahrung zu bewerten und Feedback zu geben.",
        "s3-p4": "Benachrichtigungen: In bestimmten Fällen können wir Benachrichtigungen, sei es per E-Mail oder SMS, senden, die sich auf ungelesene Nachrichten im Chat oder auf andere relevante Informationen zu Ihrem Aufenthalt beziehen.",
        "s3-p5": "Werbliche Mitteilungen: Versand von Werbeinformationen und personalisierten Angeboten.",
        "s3-p6": "Weitergabe von Informationen an die zuständigen Behörden, sofern dies durch die Tourismusvorschriften erforderlich ist.",
        //
        "s4-t1": "4. RECHTLICHE GRUNDLAGE DER VERARBEITUNG.",
        "s4-p1": "Die Rechtsgrundlage, die die Verarbeitung der personenbezogenen Daten der Gäste legitimiert, basiert auf:",
        "s4-p2": "Erfüllung eines Vertrags, an dem die betroffene Person beteiligt ist, oder auf deren Anfrage hin zur Anwendung vorvertraglicher Maßnahmen.",
        "s4-p3": "Berechtigtes Interesse: In bestimmten Fällen können wir personenbezogene Daten auf Grundlage unseres berechtigten Interesses verarbeiten, sofern dieses Interesse nicht durch die Interessen oder Grundrechte und -freiheiten des Gastes übertroffen wird.",
        "s4-p4": "Erfüllung einer gesetzlichen Verpflichtung: In bestimmten Fällen kann die Verarbeitung personenbezogener Daten erforderlich sein, um gesetzlichen Verpflichtungen nachzukommen, die für den Gastgeber gelten.",
        //
        "s5-t1": "5. DATENÜBERMITTLUNG.",
        "s5-p1": "Die personenbezogenen Daten der Gäste werden ohne deren vorherige informierte Zustimmung nicht an Dritte übermittelt oder weitergegeben, es sei denn, dies ist gesetzlich vorgesehen.",
        "s5-p2": "Für den ordnungsgemäßen Betrieb der Webapp ist es jedoch notwendig, dass bestimmte Dienstleister als Auftragsverarbeiter Zugriff auf die personenbezogenen Daten der Gäste erhalten. Diese Dienstleister sind verpflichtet, die Daten ausschließlich für die vom Gastgeber festgelegten Zwecke und unter den in den entsprechenden Auftragsverarbeitungsverträgen festgelegten Bedingungen zu verarbeiten.",
        "s5-p3": "Zu diesen Dienstleistern gehört TheHoster (The Hoster Hospitality S.L. mit Steuernummer B56861479 und Kontakt-E-Mail {email}), der die Infrastruktur und Technologie bereitstellt, die für den ordnungsgemäßen Betrieb der Webapp erforderlich sind. TheHoster agiert stets als Auftragsverarbeiter und verarbeitet die Daten ausschließlich gemäß den Anweisungen des Unterkunftsanbieters und für die vorgesehenen Zwecke.",
        //
        "s6-t1": "6. RECHTE DES NUTZERS.",
        "s6-p1": "a) Auskunftsrecht: Sie haben das Recht, zu verlangen, dass Ihnen bestätigt wird, ob personenbezogene Daten, die Sie betreffen, verarbeitet werden, sowie Zugang zu Ihren gespeicherten personenbezogenen Daten zu erhalten.",
        "s6-p2": "b) Berichtigungsrecht: Sie haben das Recht, die Berichtigung Ihrer personenbezogenen Daten zu verlangen, falls diese ungenau oder unvollständig sind.",
        "s6-p3": "c) Löschungsrecht: Sie haben das Recht, die Löschung Ihrer personenbezogenen Daten zu verlangen, wenn diese unter anderem für die Zwecke, für die sie erhoben wurden, nicht mehr erforderlich sind.",
        "s6-p4": "d) Widerspruchsrecht: Sie haben das Recht, der Verarbeitung Ihrer personenbezogenen Daten aus Gründen, die sich aus Ihrer besonderen Situation ergeben, zu widersprechen.",
        "s6-p5": "e) Recht auf Einschränkung der Verarbeitung: Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, sodass diese nur noch zur Geltendmachung oder Verteidigung von Rechtsansprüchen gespeichert werden.",
        "s6-p6": "f) Recht auf Datenübertragbarkeit: Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie einem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und diese an einen anderen Verantwortlichen zu übermitteln.",
        "s6-p7": "g) Recht, nicht einer ausschließlich auf automatisierter Verarbeitung beruhenden Entscheidung unterworfen zu werden: Sie haben das Recht, nicht einer ausschließlich auf automatisierter Verarbeitung, einschließlich Profiling, beruhenden Entscheidung unterworfen zu werden, die rechtliche Wirkungen für Sie entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt.",
        "s6-p8": "h) Recht auf Widerruf der erteilten Einwilligung: Sie haben das Recht, Ihre erteilte Einwilligung jederzeit zu widerrufen.",
        "s6-p9": "i) Recht, bei der spanischen Datenschutzbehörde Beschwerde einzulegen, wenn Sie der Ansicht sind, dass Ihre durch die geltenden Datenschutzbestimmungen gewährleisteten Rechte verletzt wurden.",
        //
        "s7-t1": "7. DATENSPEICHERUNGSDURCH.",
        "s7-p1": "Ihre Daten werden für die erforderliche Dauer zur Durchführung der verschiedenen Verarbeitungen gespeichert und mindestens gemäß den Tourismusvorschriften für einen Zeitraum von 3 Jahren aufbewahrt.",
        //
        "s8-t1": "8. LINKS ZU WEBSITES DRITTER",
        "s8-p1": "Die Webapp kann Hyperlinks oder Links enthalten, die den Zugang zu Websites Dritter ermöglichen. Die Betreiber dieser Websites verfügen über eigene Datenschutzrichtlinien und sind jeweils für ihre eigenen Daten und Datenschutzpraktiken verantwortlich.",
        "s8-p2": "Diese Datenschutzerklärung wurde zuletzt am 06. März 2024 aktualisiert."
    },
}

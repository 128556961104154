export default{
    "privacy":{
        "title":"Privacy Policy",
        //
        "s1-t1":"1. IDENTIFICATION AND RESPONSIBILITY FOR DATA PROCESSING.",
        "s1-p1":"{hotel} with address at {address} and with tax ID {nif} is responsible for the personal data collected through the webapp.",
        "s1-p2":"If you have any questions or need more information about the processing of your personal data, you can contact us at {email}.",
        "s1-p3":"Contact details of the Data Protection Officer: email {email}, postal address [address].",
        //
        "s2-t1":"2. PERSONAL INFORMATION COLLECTED.",
        "s2-p1":"When using the webapp, we may collect and process the following information about you:",
        "s2-p2":"Access to the webapp. Certain accesses to the webapp will involve requesting one or more of the following data:",
        "s2-p3":"First and last name: to identify you and personalize your experience on the webapp.",
        "s2-p4":"Phone number or email: to facilitate communication and send you notifications related to the webapp.",
        "s2-p5":"Check-in and Check-out dates: to know the dates of your stay and provide you with relevant information.",
        "s2-p6":"Stay registration: first name, first surname, second surname, gender, nationality, date of birth, city of birth, country of birth, country of residence, city of residence, residential address, type of identification, identification number, identification date, country of identification, city of issue, purpose of the stay, visa number, signature.",
        "s2-p7":"Participation in surveys. If you decide to participate in satisfaction surveys, we collect your responses to evaluate your experience and help us improve our services.",
        //
        "s3-t1":"3. PURPOSE OF THE PROCESSING.",
        "s3-p1":"The personal data collected through the webapp is used for the following purposes:",
        "s3-p2":"Access and use of the webapp: to facilitate personalized access and use of the webapp, allowing the guest to obtain information related to their stay, the city, and the available tourist activities, and to communicate with the hotel staff through the integrated chat in the webapp.",
        "s3-p3":"Satisfaction surveys: during or after the stay, the guest may be invited to participate in a survey to evaluate their experience and provide feedback.",
        "s3-p4":"Notifications: in certain cases, we may send notifications, either by email or SMS, related to unread messages in the chat or any other relevant information about the stay.",
        "s3-p5":"Commercial communications: sending commercial communications and personalized offers.",
        "s3-p6":"Information to the competent authorities, as required by tourism regulations.",
        //
        "s4-t1":"4. LEGITIMATION OF THE PROCESSING.",
        "s4-p1":"The legal basis that legitimizes the processing of guests' personal data is based on:",
        "s4-p2":"The execution of a contract in which the data subject is a party or for the application at their request of pre-contractual measures.",
        "s4-p3":"Legitimate interest: in certain circumstances, we may process personal data based on our legitimate interest, provided that such interest is not overridden by the interests or fundamental rights and freedoms of the guest.",
        "s4-p4":"Compliance with a legal obligation: in certain cases, the processing of personal data may be necessary to comply with legal obligations applicable to the host.",
        //
        "s5-t1":"5. DATA TRANSFER.",
        "s5-p1":"Guests' personal data will not be transferred or disclosed to third parties without prior informed consent, except as provided by law.",
        "s5-p2":"However, for the proper functioning of the webapp, it is necessary that certain service providers access guests' personal data as data processors. These providers are obligated to process the data exclusively for the purposes stipulated by the host and under the conditions determined in the corresponding data processing agreements.",
        "s5-p3":"Among these providers is TheHoster (The Hoster Hospitality S.L. with tax ID B56861479 and contact email {email}), which provides the infrastructure and technology necessary for the proper functioning of the webapp. TheHoster always acts as a data processor and only processes the data according to the instructions of the accommodation provider and for the intended purposes.",
        //
        "s6-t1":"6. USER RIGHTS.",
        "s6-p1":"a) Right of access: you have the right to request and obtain confirmation of whether personal data concerning you is being processed, as well as to access your stored personal data.",
        "s6-p2":"b) Right to rectification: you have the right to request the rectification of your personal data if it is inaccurate or incomplete.",
        "s6-p3":"c) Right to erasure: you have the right to request the deletion of your personal data when, among other reasons, the data is no longer necessary for the purposes for which it was collected.",
        "s6-p4":"d) Right to object: you have the right to object to the processing of your personal data for reasons related to your particular situation.",
        "s6-p5":"e) Right to restrict processing: you have the right to request the restriction of processing your personal data, in which case it will only be stored for the exercise or defense of claims.",
        "s6-p6":"f) Right to data portability: you have the right to receive the personal data concerning you that you have provided to a data controller, in a structured, commonly used and machine-readable format, and to transmit it to another data controller.",
        "s6-p7":"g) Right not to be subject to automated individual decision-making: you have the right not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning you or similarly affects you significantly.",
        "s6-p8":"h) Right to withdraw consent: you have the right to revoke the consent you have provided.",
        "s6-p9":"i) Right to lodge a complaint with the Spanish Data Protection Agency if you believe that your rights, as recognized by the applicable data protection regulations, have been violated.",
        //
        "s7-t1":"7. DATA RETENTION PERIOD.",
        "s7-p1":"Your data is stored for the time necessary to carry out the various processing activities and, at a minimum, in accordance with tourism regulations, for a period of 3 years.",
        //
        "s8-t1":"8. LINKS TO THIRD-PARTY WEBSITES",
        "s8-p1":"The webapp may include hyperlinks or links that allow access to third-party websites. The owners of these websites will have their own data protection policies and are, in each case, responsible for their own files and privacy practices.",
        "s8-p2":"This Privacy Policy was last updated on March 06, 2024."
    },
}

export default {
    "breadcrumbs": {
      "experiences": "Esperientziak"
    },
    "card-experience": {
      "hour": "ordu",
      "aprox": "gutxi gorabehera",
      "min-aprox": "minutu",
      "and": "eta",
      "recommed": "gomendatua",
      "prominent": "nabarmendua",
      "annulation-gratuite": "doako ezeztatzea",
      "non-refundable": "ez da itzulgarria",
      "from": "Hasita",
      "reviews": "iritziak"
    },
    "list-page": {
      "title": "Esperientziak",
      "section-filter": {
        "label-search": "Iragazi honen arabera",
        "placeholder-input-search": "Idatzi gako-hitz bat",
        "label-price": "Prezioa",
        "label-price-min": "Gutxieneko prezioa",
        "placeholder-price-min": "Gutxienez...",
        "label-price-max": "Gehieneko prezioa",
        "placeholder-price-max": "Gehienez...",
        "label-duration": "Iraupena",
        "cancellation-policy": {
          "title": "Ezeztapen politika",
          "free-cancellation": "Doako ezeztapena"
        },
        "duration": {
          "title": "Iraupena",
          "one-hour": "Ordu 1 arte",
          "one-four-hour": "1 eta 4 ordu artean",
          "noon": "Egun erdia",
          "whole-day": "Egun osoa"
        },
        "score": {
          "title": "Puntuazioa",
          "star": "Izarrak"
        },
        "price": {
          "title": "Prezioa",
          "0-100": "0 € eta 100 € artean",
          "100-250": "100 € eta 250 € artean",
          "250-500": "250 € eta 500 € artean",
          "500": "+500 €"
        }
      },
      "section-filter-history": {
        "btn-price": "{price_max} arte",
        "btn-close": "Kendu iragazkia"
      },
      "btn-more-filter": "Iragazki gehiago",
      "btn-submit-filter": "Aplikatu iragazkiak",
      "text-count-list": "Aurkitutako esperientziak",
      "text-count-list-mobile": "{count} esperientzia aurkitu dira.",
      "placeholder-search": "Bilatu esperientzia bat",
      "text-count-list-search-active": "Esperientzia guztiak bilatzen: ",
      "text-count-list-search": "{count} esperientzia aurkituta honentzat: ",
      "text-search-not-found": "Doitu zure bilaketa edo kendu iragazkiak.",
      "without-results": {
        "title": "Ez da esperientziarik aurkitu",
        "text": "Ez da esperientziarik aurkitu hiri honetan. Beste hirietan bilatu dezakezu eta esperientzia paregabeak aurkitu."
      },
      "btn-load-more": "Kargatu gehiago"
    },
    "detail-page": {
      "tag-recommend": "Gomendatua",
      "tag-featured": "Nabarmendua",
      "text-variand-price": "Prezioa taldearen tamainaren arabera aldatzen da",
      "tag-ticket-mobile": "Sarrera mugikorra",
      "title-recomendation": "Gomendioa",
      "title-description": "Deskribapena",
      "title-description-mobile": "Deskribapen orokorra",
      "tooltip-language": "{language} eta beste {numbers}",
      "open-collapse-description": "Ikusi gehiago",
      "close-collapse-description": "Ikusi gutxiago",
      "title-include": "Zer barne hartzen du",
      "title-location": "Kokapena",
      "btn-eye-map": "Ikusi Google Maps-en",
      "subtitle-point-start": "Bilketa puntua",
      "subtitle-point-end": "Amaiera puntua",
      "ends-point-at-meeting-point": "Jarduera hau hasierako bilketa puntuan amaitzen da",
      "title-other-information": "Informazio gehigarria",
      "title-politic-cancelation": "Ezeztapen politika",
      "text-politic-standar-1": "Esperientzia hasi baino 24 ordu lehenago ezeztatzen bada, dirua osorik itzuliko da.",
      "text-politic-standar-2": "Itzulera osoa lortzeko, 24 ordu lehenago bertan behera utzi behar da.",
      "text-politic-standar-3": "Esperientzia hasi baino 24 ordu lehenago egindako aldaketak ez dira onartuko.",
      "text-politic-standar-4": "24 orduko epearen barruan ezeztatzen bada, ez da itzulketarik egingo.",
      "text-politic-standar-5": "Mugako ordutegiak esperientziaren tokiko orduan oinarritzen dira.",
      "text-politic-notrembolsable": "Esperientzia hau ez da itzulgarria eta ez da aldaketarik onartuko. Ezeztatuz gero, ez da dirurik itzuliko.",
      "opinions-word": "iritziak",
      "variant-price": "Prezioa taldearen tamainaren arabera aldatzen da",
      "when-experience-label": "Noiz gozatu nahi duzu esperientzia hau?",
      "maximum-reserves-label": "Gehienez {maxSeats} leku erreserbatu ditzakezu",
      "availability-label": "Ikusi erabilgarritasuna",
      "free-cancellation-label": "Doako ezeztapena",
      "non-refundable-cancellation-label": "Ez da itzulgarria",
      "check-the-availability-of-this-experience": "Kontsultatu esperientzia honen erabilgarritasuna",
      "btn-see-availability-on-viator": "Ikusi erabilgarritasuna Viator-en",
      "title-general-information": "Informazio orokorra",
      "tag-paper-ticket": "Sarrera paperean",
      "offered-in": "Eskaintzen da hizkuntza honetan"
    }
  }
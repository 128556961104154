export default {
    "gocheckinButton": "Check-in abschließen",
    "cardBanner": {
        "title": "Check-in verfügbar",
        "subtitle": "Optimiere deine Zeit, führe jetzt deinen Check-in durch!"
    },
    "card-guest": {
        "resend-staylink": "Link zum Aufenthalt erneut senden",
        "invite-to-completecheckin": "Einladen, den Check-in abzuschließen",
        "delete-checkin": "Check-in des Gastes löschen",
        "delete-guest": "Gast löschen"
    },
    "delete-guest": {
        "title": "Gast aus dem Aufenthalt entfernen?",
        "info": "Du wirst diesen Gast aus deinem Aufenthalt entfernen. Du kannst später erneut eine Einladung senden.",
        "confirm": "Gast entfernen",
        "cancel": "Abbrechen"
    },
    "delete-checkin": {
        "title": "Check-in des Gastes löschen?",
        "info": "Du wirst die Check-in-Informationen dieses Gastes löschen. Der Gast muss seinen Check-in erneut durchführen.",
        "confirm": "Check-in löschen",
        "cancel": "Abbrechen"
    },
    "header": {
        "mystay": "Aufenthalt",
        "guests": "Gäste"
    },
    "form": {
        "why-data": "Warum fragen wir diese Daten ab?",
        "policies-info-1": "Durch Drücken des 'Fertigstellen'-Buttons erkläre ich, dass ich die",
        "policies-info-2": "Richtlinien und Regeln",
        "policies-info-3": "der Unterkunft akzeptiere",
        "why-data-title": "Warum fragen wir diese Daten ab?",
        "why-data-p1": "Das örtliche Gesetz verlangt, dass touristische Unterkünfte alle ihre Gäste registrieren.",
        "why-data-p2": "Daher benötigen wir einige grundlegende und obligatorische persönliche Daten.",
        "why-data-p3": "Die Online-Abwicklung des Check-ins vor deiner Ankunft spart dir Zeit, da du diese Informationen nicht vor Ort angeben musst.",
        "why-data-p4": "Deine Daten werden sicher und gemäß der Datenschutz-Grundverordnung (DSGVO) verarbeitet.",
        "why-data-p5": "Weitere Details findest du in unserer Datenschutzerklärung.",
        "changes-bar-next": "Nächster",
        "changes-bar-finish": "Fertigstellen",
        "changes-bar-send-data": "Daten werden gesendet",
        "changes-bar-previus": "Vorheriger Schritt",
        "input-2-label": "Erster Nachname",
        "input-3-label": "Zweiter Nachname",
        "input-3-alert": "Wenn dein Dokumententyp spanischer DNI ist, fülle dieses Feld aus",
        "input-4-label": "Geburtsdatum",
        "input-4-plchdr": "TT/MM/JJJJ",
        "input-5-label": "Geschlecht",
        "input-5-plchdr": "Wähle eine Option",
        "input-6-label": "Telefon",
        "input-7-label": "E-Mail",
        "input-8-label": "Verantwortliche erwachsene Person",
        "input-8-plchdr": "Vor- und Nachname der erwachsenen Person",
        "input-9-label": "Verwandtschaftsverhältnis",
        "input-9-plchdr": "Wie die erwachsene Person mit dem Minderjährigen verbunden ist",
        "input-10-label": "Nationalität",
        "input-10-plchdr": "Tippe, um Vorschläge zu sehen",
        "input-11-label": "Dokumententyp",
        "input-11-plchdr": "Wähle eine Option",
        "input-11-error": "Bitte fülle das Feld 'Zweiter Nachname' aus",
        "input-12-label": "Dokumententrägernummer",
        "input-12-plchdr": "Nur für spanischen NIE oder DNI",
        "input-12-error": "Die eingegebene Zeichenanzahl ist ungültig",
        "input-13-label": "Dokumentennummer",
        "input-13-plchdr": "Gib die Nummer des ausgewählten Dokuments ein",
        "input-14-label": "Wohnsitzland",
        "input-14-plchdr": "Tippe, um Vorschläge zu sehen",
        "input-15-label": "Postleitzahl",
        "input-15-plchdr": "Tippe, um Vorschläge zu sehen",
        "input-16-label": "Stadt",
        "input-16-plchdr": "Tippe, um Vorschläge zu sehen",
        "input-17-label": "Wohnadresse",
        "input-17-plchdr": "Tippe, um Vorschläge zu sehen",
        "head-title-1": "Persönliche Daten",
        "head-title-2": "Identifikationsdaten",
        "head-title-3": "Umfrage",
        "exit-title": "Check-in beenden?",
        "exit-description": "Wenn du jetzt den Prozess verlässt, werden die eingegebenen Informationen nicht gespeichert",
        "exit-button": "Beenden",
        "exit-continue": "Mit dem Check-in fortfahren",
        "norms-title": "Richtlinien und Regeln",
        "norms-subtitle": "Strafe:",
        "why-data-continue": "Verstanden",
        "see-policies": "Datenschutzerklärung ansehen"
    },
    "autocomplete": {
      "cardbanner-title": "Formular automatisch ausfüllen",
      "cardbanner-subtitle": "Sparen Sie Zeit, indem Sie Ihr Dokument scannen",
      "title": "Formular automatisch ausfüllen",
      "description-1": "Sie werden Ihren Reisepass oder Personalausweis mit der Kamera Ihres Geräts scannen.",
      "description-2": "Sie müssen den MRZ-Code scannen.",
      "description-3": "Hier unten zeigen wir Ihnen, wo Sie ihn finden.",
      "passport-title": "Reisepass",
      "dninie-title": "Personalausweis/NIE",
      "scan-doc": "Dokument scannen",
      "backtoform": "Zurück zum Formular",
      "error-scan": "Fehler: Du musst den MRZ-Code des Dokuments fokussieren",
    "browser-warning": "Melde dich in einem Browser wie Google Chrome an, um diese Funktion zu nutzen.",
    "rescan-document": "Dokument erneut scannen"
    }
}
  

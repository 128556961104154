export default{
    "privacy":{
      "title":"Política de privacidade",
      //
      "s1-t1":"1. IDENTIFICACIÓN E RESPONSABLE DO TRATAMENTO DOS DATOS.",
      "s1-p1":"{hotel}, con domicilio en {address} e con NIF {nif}, é o responsable dos datos persoais recollidos a través da webapp.",
      "s1-p2":"Se tes algunha dúbida ou necesitas máis información sobre o tratamento dos teus datos persoais, podes contactar a través de {email}.",
      "s1-p3":"Datos de contacto do Delegado de Protección de Datos: correo electrónico {email}, dirección postal [dirección].",
      //
      "s2-t1":"2. INFORMACIÓN PERSOAL RECOLLIDA.",
      "s2-p1":"Ao utilizar a webapp, podemos recoller e procesar a seguinte información sobre ti:",
      "s2-p2":"Acceso á webapp. Certos accesos á webapp implicarán a solicitude dalgúns dos seguintes datos:",
      "s2-p3":"Nome e apelidos: para identificarte e personalizar a túa experiencia na webapp.",
      "s2-p4":"Número de teléfono ou correo electrónico: para facilitar a comunicación e enviarte notificacións relacionadas coa webapp.",
      "s2-p5":"Data de Check-in e Check-out: para coñecer as datas da túa estadía e ofrecerche información relevante.",
      "s2-p6":"Rexistro da estadía: nome, apelido, segundo apelido, sexo, nacionalidade, data de nacemento, cidade de nacemento, país de nacemento, país de residencia, cidade de residencia, dirección de residencia, tipo de identificación, número de identificación, data de identificación, país de identificación, cidade de emisión, propósito da estadía, número de visado, sinatura.",
      "s2-p7":"Participación en enquisas. Se decides participar nas enquisas de satisfacción, recompilamos as túas respostas para avaliar a túa experiencia e axudarnos a mellorar os servizos.",
      //
      "s3-t1":"3. FINALIDADE DO TRATAMENTO.",
      "s3-p1":"Os datos persoais que se recollen a través da webapp utilízanse para as seguintes finalidades:",
      "s3-p2":"Acceso e uso da webapp: facilitar o acceso e uso personalizado da webapp, permitindo ao hóspede obter información relacionada coa súa estadía, a cidade e as actividades turísticas dispoñibles, e comunicarse co persoal do hotel a través do chat incorporado na webapp.",
      "s3-p3":"Enquisas de satisfacción: durante a estadía ou unha vez finalizada, o hóspede pode ser convidado a participar nunha enquisa para avaliar a súa experiencia e proporcionar feedback.",
      "s3-p4":"Notificacións: en determinados casos, podemos enviar notificacións, sexa por correo electrónico ou SMS, relacionadas con mensaxes sen ler no chat ou calquera outra información relevante sobre a estadía.",
      "s3-p5":"Comunicacións comerciais: envío de comunicacións comerciais e ofertas personalizadas.",
      "s3-p6":"Información ás autoridades competentes, segundo o requiran as normativas turísticas.",
      //
      "s4-t1":"4. LEXITIMACIÓN DO TRATAMENTO.",
      "s4-p1":"A base xurídica que lexitima o tratamento dos datos persoais dos hóspedes fundaméntase en:",
      "s4-p2":"Execución dun contrato no que o interesado é parte ou para a aplicación a petición deste de medidas precontractuais.",
      "s4-p3":"Interese lexítimo: en determinadas circunstancias, é posible que tratemos datos persoais baseándonos no noso interese lexítimo, sempre que ese interese non se vexa superado polos intereses ou dereitos e liberdades fundamentais do hóspede.",
      "s4-p4":"Cumprimento dunha obriga legal: en certos casos, o tratamento de datos persoais pode ser necesario para cumprir obrigas legais aplicables ao hoster.",
      //
      "s5-t1":"5. CESIÓN DOS DATOS.",
      "s5-p1":"Os datos persoais dos hóspedes non serán cedidos nin transferidos a terceiros sen o previo consentimento informado, salvo nos casos previstos pola lei.",
      "s5-p2":"Con todo, para o correcto funcionamento da webapp é necesario que certos provedores de servizos accedan aos datos persoais dos hóspedes como encargados do tratamento. Estes provedores teñen a obriga de tratar os datos exclusivamente coas finalidades estipuladas polo hoster e baixo as condicións que se determinen nos correspondentes contratos de encargado do tratamento.",
      "s5-p3":"Entre estes provedores atópase TheHoster (The Hoster Hospitality S.L. con NIF B56861479 e email de contacto {email}), que proporciona a infraestrutura e tecnoloxía necesaria para o correcto funcionamento da webapp. TheHoster actúa en todo momento como encargado do tratamento e unicamente procesa os datos segundo as instrucións do titular do aloxamento e para as finalidades previstas.",
      //
      "s6-t1":"6. DEREITOS DO USUARIO.",
      "s6-p1":"a) Dereito de acceso: tes dereito a solicitar e obter confirmación de se se tratan datos persoais que che concirnen, así como a acceder aos teus datos persoais almacenados.",
      "s6-p2":"b) Dereito de rectificación: tes dereito a solicitar a rectificación dos teus datos persoais se estes fosen inexactos ou incompletos",
      "s6-p3":"c) Dereito de supresión: tes dereito a solicitar a supresión dos teus datos persoais cando, entre outros motivos, os datos xa non sexan necesarios para os fins que foron recollidos.",
      "s6-p4":"d) Dereito de oposición: tes dereito a opoñerte ao tratamento dos teus datos persoais, por motivos relacionados coa túa situación particular.",
      "s6-p5":"e) Dereito á limitación do tratamento: tes dereito a solicitar a limitación do tratamento dos teus datos persoais, no cal caso unicamente se conservan para o exercicio ou a defensa de reclamacións.",
      "s6-p6":"f) Dereito á portabilidade dos datos: tes dereito a recibir os datos persoais que che incumben, que facilitases a un responsable do tratamento, nun formato estruturado, de uso común e lectura mecánica e a transmitilos a outro responsable do tratamento.",
      "s6-p7":"g) Dereito a non ser obxecto de decisións individuais automatizadas: tes dereito a non ser obxecto dunha decisión baseada unicamente no tratamento automatizado, incluída a elaboración de perfís, que produza efectos xurídicos ou che afecte significativamente de modo similar.",
      "s6-p8":"h) Dereito a revogar o consentimento outorgado.",
      "s6-p9":"i) Dereito a reclamar ante a Axencia Española de Protección de Datos cando o interesado considere que se vulneraron os dereitos que lle son recoñecidos pola normativa aplicable en protección de datos.",
      //
      "s7-t1":"7. PRAZO DE CONSERVACIÓN DOS DATOS.",
      "s7-p1":"Os teus datos almacénanse durante o tempo necesario para realizar os diferentes tratamentos e, como mínimo, de acordo coa normativa turística, por un período de 3 anos.",
      //
      "s8-t1":"8. LIGAZÓNS A SITIOS WEB DE TERCEIROS",
      "s8-p1":"A webapp pode incluír hipervínculos ou ligazóns que permiten acceder a páxinas web de terceiros. Os titulares deses sitios web disporán das súas propias políticas de protección de datos, sendo eles mesmos, en cada caso, responsables dos seus propios ficheiros e das súas propias prácticas de privacidade.",
      "s8-p2":"Esta Política de Privacidade foi actualizada por última vez o 06 de marzo de 2024."
    }
  }
  
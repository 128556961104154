export default {
    "textToast": {
      "sendQueryText": "Eskerrik asko zure iritzia partekatzeagatik. Zure esperientzia garrantzitsua da guretzat eta zure itxaropenak betetzen saiatzen gara",
      "updateQueryText": "Aldaketa bidalita",
      "registerResponse": "Iritzia bidalita",
      "updateResponse": "Iritzia eguneratuta"
    },
    "form": {
      "send": "Bidali",
      "sending": "Bidaliz",
      "modify": "Aldatu",
      "cancel": "Utzi",
      "title": "Iritzia",
      "hello": "Kaixo",
      "whatsup": "Zer moduz",
      "thanksAll": "Eskerrik asko denagatik",
      "btn-verywrong-stay": "Oso txarra",
      "btn-wrong-stay": "Txarra",
      "btn-normal-stay": "Normala",
      "btn-good-stay": "Ona",
      "btn-verygood-stay": "Oso ona",
      "btn-verywrong-poststay": "Oso txarra",
      "btn-wrong-poststay": "Txarra",
      "btn-normal-poststay": "Normala",
      "btn-good-poststay": "Ona",
      "btn-verygood-poststay": "Oso ona",
      "poststay-bad-thanks-title": "Erantzuna bidalita",
      "poststay-bad-thanks-subtitle": "Eskerrik asko zure denboragatik",
      "continueIn": "Jarraitzea hemen"
    },
    "settings": {
      "questionpre-stay": "Zerbait egin dezakegu zure {lodging}(e)ko iristeko?",
      "questionin-stay": "Nola baloratuko zenuke zure egonaldiaren asebetetze maila orain arte?",
      "questionpost-stay": "Zein izan da zure esperientzia gurekin?"
    },
    "inbox": {
      "title": "Mezuak"
    }
  }
  
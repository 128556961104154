export default {
    "facilitiesWord": "Instal·lacions",
    "horaryWord": "Horari",
    "detailPage": {
      "sectionSchedules": {
        "title": "Horaris",
        "openAlways": "Obert tots els dies les 24h",
        "empty": "Sense horaris especificats. Consulta via xat",
        "days": {
          "Lunes": "Dilluns",
          "Martes": "Dimarts",
          "Miércoles": "Dimecres",
          "Jueves": "Dijous",
          "Viernes": "Divendres",
          "Sábado": "Dissabte",
          "Domingo": "Diumenge"
        }
      }
    }
  }
  
export default {
    "apiError401": "Ongeldige inloggegevens",
    "apiError404": "Service niet beschikbaar",
    "apiError405_406": "Ongeldig verzoek",
    "apiError500": "Verbindingsfout",
    "apiErrorUndefined": "Algemene applicatiefout",
    "invalidKey": "Ongeldige sleutel",
    "recordSuccess": "Registratie voltooid",
    "emailSuccess": "E-mail verzonden",
    "passwordUpdated": "Wachtwoord bijgewerkt",
    "copiedClipboard": "Gekopieerd naar klembord",
    "guestDeleted": "Gast verwijderd",
    "urlClipboard": "Link gekopieerd",
    "changeSave": "Wijzigingen opgeslagen",
    "dataSave": "Gegevens succesvol opgeslagen"
  };
  